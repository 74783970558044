<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Watchers</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small text color="primary" @click.prevent="newWatcher()" :disabled="!hasAaaAccess()">
                <v-icon dark left>create</v-icon>
                New Watcher
              </v-btn>
              <v-btn small text color="primary" @click.prevent="confirmConfig = true" :disabled="!hasAaaAccess()">
                <v-icon dark left>sync</v-icon>
                Apply Watchers
              </v-btn>
              <v-btn small text color="primary" @click.prevent="refreshWatchers()">
                <v-icon dark left>refresh</v-icon>
                Refresh
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small text color="primary" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-card>
                      <v-card-actions>
                        <v-list dense>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportConfig()">
                              <v-icon dark left>get_app</v-icon>
                              Export
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex m12>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="Object.values(allConfig.watchersList)"
              :loading="isLoading.config"
              :search="search"
              :sort-by="'name'"
              :sort-desc="false"
              item-key="name+tag"
              class="elevation-1"
              dense
              v-if="allConfig.watchersList"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.name+item.tag" @click="displayWatcher(item)" :style="{cursor:'pointer'}">
                    <td><v-icon small>apps</v-icon></td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.tag }}</td>
                    <td>
                      {{ prettyDaemons(item.daemonsList) }}
                    </td>
                    <td><v-icon small v-if="item.glob">check</v-icon></td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>

        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      v-model="watchersdialog"
      width="500"
    >
      <v-card class="elevation-0">
        <v-card-title></v-card-title>
        <v-card-text>
          <v-flex md12>
            <v-text-field
            v-model="appname"
            label="Package Name"
            required
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
            v-model="apptag"
            label="Tag"
            hint="Only 'pac' and 'scada' for now."
            required
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
            v-model="appdaemons"
            label="Daemons"
            hint="Space seperated, use @N notation for all OS."
            required
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-checkbox
              v-model="glob"
              label="Use glob pattern"
            ></v-checkbox>
          </v-flex>
        </v-card-text>
        <v-card-actions>
            <v-btn color="red" text @click.stop="confirmDelete = true; watchersdialog = false;" :disabled="!hasAaaAccess()">Delete</v-btn>
          <v-spacer></v-spacer>
            <v-btn color="primary" text @click.prevent="watchersdialog = false">Close</v-btn>
            <v-btn color="primary" text @click.prevent="callSetWatcher(); watchersdialog = false;" :disabled="!hasAaaAccess()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmConfig"
      width="500"
    >
      <v-card>
        <v-card-title>Apply Configuration</v-card-title>
        <v-card-text>
          Configuration will be applied and actions will be taken, are you sure?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmConfig = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="confirmConfig = false; callApplyWatchers();">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDelete"
      width="500"
    >
      <v-card>
        <v-card-title>Delete selected Application?</v-card-title>
        <v-card-text>
          "{{ appname }}" for tag "{{ apptag }}" will be deleted. You can't undo this action.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmDelete = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callDeleteWatcher(); confirmDelete = false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import libvue from '../lib/vue.js'

export default {
  mixins: [libvue],
  data() {
    return {
      confirmConfig: false,
      confirmDelete: false,
      dialog: false,
      search: '',
      headers: [
        { text: '', sortable: false, value: '' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Tag', sortable: true, value: 'tag' },
        { text: 'Daemons', sortable: true, value: 'daemonsList' },
        { text: 'Glob', sortable: true, value: 'glob' }
      ],
      watchersdialog: false,
      appname: null,
      apptag: null,
      appdaemons: null,
      glob: false,
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  computed: mapGetters(['allConfig', 'getUserById', 'isLoading']),
  mounted() {
    this.refreshWatchers();
  },
  methods: {
    refreshWatchers() {
      return this.getConfig().then(() => {}).catch(() => {});
    },
    displayWatcher(v) {
      this.appname = v.name;
      this.apptag = v.tag;
      this.appdaemons = this.prettyDaemons(v.daemonsList);
      this.glob = v.glob;
      this.watchersdialog = true;
    },
    newWatcher() {
      this.appname = "";
      this.apptag = "";
      this.appdaemons = "";
      this.glob = false;
      this.watchersdialog = true;
    },
    callSetWatcher() {
      this.dialog = true;
      var args = {
        name: this.appname,
        tag: this.apptag,
        daemonsList: this.appdaemons.trim().split(" "),
        glob: this.glob
      }
      this.setWatcher(args).then((v) => {
        this.getConfig().then(() => {
          this.dialog = false;
          this.snack = true;
          this.snackColor = 'success';
          this.snackMessage = v.name + ' set'
        });
      }).catch(() => { this.dialog = false; });
    },
    callDeleteWatcher() {
      this.dialog = true;
      var args = {
        name: this.appname,
        tag: this.apptag,
      }
      this.deleteWatcher(args).then((v) => {
        this.getConfig().then(() => {
          this.dialog = false;
          this.snack = true;
          this.snackColor = 'success';
          this.snackMessage = v.name + ' deleted'
        });
      }).catch(() => { this.dialog = false; });
    },
    callApplyWatchers() {
      this.dialog = true;
      this.applyWatchers().then(() => {
        this.dialog = false;
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = 'Configuration applied'
      }).catch(() => { this.dialog = false; });
    },
    hasAaaAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.aaaaccess;
      }
      return false;
    },
    exportConfig() {
      var dataStr = JSON.stringify(this.allConfig, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'coolConfig.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['getConfig', 'applyWatchers', 'setWatcher', 'deleteWatcher'])
  }
}
</script>
