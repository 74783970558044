<template>
  <v-container grid-list-md fluid>
    <v-layout align-center justify-space-around wrap>
      <v-flex md12>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title>Library</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>

      <v-flex md12>
        <v-card v-if="recipe">
          <v-card-title>
            <v-btn text icon color="primary" @click.prevent="openLibraryPage()"> 
              <v-icon dark>arrow_back</v-icon>
            </v-btn>
            <h4> {{ recipeid }} </h4>
            <v-spacer></v-spacer>
            <NewTask ref="NewTask" :recipeid="recipeid" />
            <v-btn small text color="primary" @click.prevent="shareRecipe" v-if="hasAaaAccess()">
              <v-icon dark left>share</v-icon>
              Share
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small text color="primary" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-layout row wrap>
                <v-flex md12>
                  <v-card>
                    <v-card-actions>
                      <v-list dense>
                        <v-list-item>
                          <v-btn small text color="red" :loading="snack" :disabled="snack || approved || !hasNewTaskFullAccess()" @click.prevent="confirm = true">
                            <v-icon dark left>delete</v-icon>
                            Delete
                          </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn small text color="primary" @click.prevent="exportRecipe()">
                            <v-icon dark left>get_app</v-icon>
                            Export
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-menu>
          </v-card-title>

          <v-layout wrap>
            <v-flex md6>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td colspan="2">{{ recipe.recipeid }}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{{ recipe.name }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Name'; xvalue = recipe.name; xdialog = true" :disabled="approved">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Version</td>
                    <td colspan="2">{{ recipe.version }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{{ recipe.description }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Description'; xvalue = recipe.description; xdialog = true" :disabled="approved">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Switch</td>
                    <td>{{ recipe.pb_switch }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Switch'; xvalue = recipe.pb_switch; xdialog = true" :disabled="approved">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Run As</td>
                    <td>{{ recipe.runas }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Run As'; xvalue = recipe.runas; xdialog = true" :disabled="approved">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Files</td>
                    <td v-if="recipe.filesList">{{ recipe.filesList.length }}</td>
                    <td v-else></td>
                    <td style="text-align: right; white-space: nowrap">
                      <v-btn x-small text @click.prevent="xkey = 'FileObjectID'; ufob(); fdialog = true">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                      <v-btn x-small text color="red" @click.prevent="xkey = 'FileObjectID'; fileobjects = []; callUpdateRecipe();" :disabled="approved">
                        <v-icon small>clear</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Require Arguments</td>
                    <td colspan="2">
                      <input type="checkbox" v-model="argsrequired" :loading="snack" :disabled="approved" v-on:change="xkey='ArgsRequired'; xvalue=argsrequired; callUpdateRecipe()">
                    </td>
                  </tr>
                  <tr>
                    <td>Timeout</td>
                    <td>{{ recipe.timeout }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Timeout'; xvalue = recipe.timeout; xdialog = true" :disabled="approved">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Concurrent</td>
                    <td colspan="2">
                      <input type="checkbox" v-model="concurrent" :loading="snack" :disabled="approved" v-on:change="xkey='Concurrent'; xvalue=concurrent; callUpdateRecipe()">
                    </td>
                  </tr>
                  <tr>
                    <td>Cross Site</td>
                    <td colspan="2">
                      <input type="checkbox" v-model="crosssite" :loading="snack" :disabled="approved" v-on:change="xkey='CrossSite'; xvalue=crosssite; callUpdateRecipe()">
                    </td>
                  </tr>
                  <tr>
                    <td>Owner</td>
                    <td colspan="2">{{ recipe.owner }}</td>
                  </tr>
                  <tr>
                    <td>Last Modified</td>
                    <td colspan="2">{{ dateToString(recipe.lastmodified) }}</td>
                  </tr>
                  <tr>
                    <td>Modified By</td>
                    <td colspan="2">{{ recipe.modifiedby }}</td>
                  </tr>
                  <tr>
                    <td>Sticky</td>
                    <td colspan="2">
                      <input type="checkbox" v-model="sticky" :loading="snack" :disabled="approved" v-on:change="xkey='Sticky'; xvalue=sticky; callUpdateRecipe()">
                    </td>
                  </tr>
                  <tr>
                    <td>Approved</td>
                    <td colspan="2">
                      <input type="checkbox" v-model="approved" :loading="snack" :disabled="snack || !hasApproveAccess()" v-on:change="callApproveRecipe()">
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-flex>

            <v-flex md6> 
              <v-card class="elevation-0">
                <v-card-text>
                  <v-textarea
                    v-model="data"
                    auto-grow
                    solo
                    background-color="rgba(0, 0, 0, .02)"
                    flat
                    counter="4096"
                    dense
                    :readonly="approved"
                    v-if="hasNewTaskFullAccess()"
                  >
                  </v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon text @click.prevent="help = true">
                      <v-icon>help</v-icon>
                    </v-btn>
                    <v-btn icon text :disabled="approved || !hasNewTaskFullAccess()" @click.prevent="xkey = 'Data'; callUpdateRecipe()">
                      <v-icon>save</v-icon>
                    </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>

      </v-flex>
    </v-layout>

    <v-dialog
      v-model="xdialog"
      width="500"
      v-if="recipe"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-text-field
            v-model="xvalue"
            :label="xkey"
            :rules="switchRules"
            required
            v-if="xkey == 'Switch'"
          ></v-text-field>
          <v-text-field
            v-model="xvalue"
            :label="xkey"
            required
            v-else
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="xdialog = false;">Close</v-btn>
          <v-btn color="primary" text @click.prevent="callUpdateRecipe(); xdialog = false;">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="fdialog"
      width="800"
      v-if="recipe"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-layout wrap md12 v-for="(f, i) in fileobjects" :key="i">
            <v-flex md12>
              <v-autocomplete
                v-model="fileobjects[i].fileobjectid"
                :items="getFiles()"
                :loading="isLoading.files"
                :disabled="approved"
                item-text="filenameversion"
                item-value="fileobjectid"
                clearable
                label="Search files"
              ></v-autocomplete>
            </v-flex>
            <v-flex md12>
              <v-text-field
              v-model="fileobjects[i].destination"
              label="Destination"
              :rules="destinationRules"
              :disabled="approved"
              required
              ></v-text-field>
            </v-flex>
            <v-flex md2>
              <v-text-field
              v-model="fileobjects[i].destinationUser"
              label="User"
              :rules="destinationUserGroupRules"
              :disabled="approved"
              ></v-text-field>
            </v-flex>
            <v-flex md2>
              <v-text-field
              v-model="fileobjects[i].destinationGroup"
              label="Group"
              :rules="destinationUserGroupRules"
              :disabled="approved"
              ></v-text-field>
            </v-flex>
            <v-flex md2>
              <v-text-field
              v-model="fileobjects[i].destinationMode"
              label="Mode"
              :rules="destinationModeRules"
              :disabled="approved"
              ></v-text-field>
            </v-flex>
            <v-flex md2>
              <v-checkbox
                v-model="fileobjects[i].destinationOverwrite"
                label="Overwrite"
                :disabled="approved"
              ></v-checkbox>
            </v-flex>
            <v-flex md1>
            </v-flex>
            <v-flex md3>
              <v-text-field
                v-model="fileobjects[i].transfertimeout"
                label="Transfer Timeout (sec)"
                :rules="transfertimeoutRules"
                :disabled="approved"
              ></v-text-field>
            </v-flex>
            <v-flex md12>
              <span>
              <v-btn fab x-small @click.prevent="addFileObjectField()">+</v-btn>
              <v-btn fab x-small @click.prevent="delFileObjectField(i)" v-if="fileobjects.length > 1">-</v-btn>
              </span>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="fdialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callUpdateRecipe(); fdialog = false;" :disabled="approved">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sdialog"
      width="600"
      v-if="recipe"
    >
      <v-card>
        <v-card-title>
          <v-icon dark left color="primary">share</v-icon>
          Share recipe
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="svalue"
            :items="getUsers()"
            :loading="isLoading.users"
            item-text="key"
            item-value="email"
            label="Add people"
            :disabled="sall"
          ></v-autocomplete>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">person</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ getUserEntry(recipe.owner) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>Owner</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-for="sub in subList" :key="sub">
              <v-list-item-icon>
                <v-icon color="primary">person</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ getUserEntry(sub) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small text color="primary" @click.prevent="removeSvalue(sub)">
                  <v-icon dark>clear</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-layout>
            <v-flex>
              <v-checkbox v-model="sall" label="Share with all"></v-checkbox>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="sdialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callSetRecipeAccess(); sdialog = false;">Save</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirm"
      width="500"
    >
      <v-card>
        <v-card-title>Delete selected recipe?</v-card-title>
        <v-card-text>
          "{{ recipeid }}" will be deleted. You can't undo this action.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirm = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callDeleteRecipe(); confirm = false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="help"
      width="650"
    >
      <v-card>
        <v-card-title>Help</v-card-title>
        <v-card-text>
          <p>
          Recipe names must be unique and written in <b>sh</b> compatible syntax. <br />
          Timeout must be reasonable and should take account plants network speed (eg. for package installations). <br />
          Require Arguments will force argument passing to script (eg. $1, $2, ...) <br />
          Concurrent will allow this recipe to run in parallel with other tasks. <br />
          Cross Site will allow the user to use another plant as source (provided he has access). <br />
          Sticky recipes are run in the background until timeout. <br />
          </p>
          <p>
          The following environment variables are available: <br />
          - $COOL_OS: $GOOS as defined in <a href='https://golang.org/doc/install/source#environment' rel='noopener'>golang doc</a> <br />
          - $COOL_ARCH: $GOARCH as defined in <a href='https://golang.org/doc/install/source#environment' rel='noopener'>golang doc</a> <br />
          - $COOL_RELEASE: $RELEASE_ID$RELEASE_VERSION <br />
          - $COOL_RELEASE_ID: one of centos, debian or embian <br />
          - $COOL_RELEASE_VERSION: version (2.0, 6, 7, 8, ...) <br />
          - $COOL_AGENT_ID: [Certificate Common Name] <br />
          - $COOL_CLIENT_ID: [Current random UUID of client] <br />
          - $COOL_TAGS: space separated tags (eg. scada, pac, fttl, ...) <br />
          - $COOL_LABELS: space separated labels (eg. release=...) <br />
          Server side generated $TAGS and $LABELS are not exported in agent environment (eg. label portal=...)
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="help = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import libvue from '../lib/vue.js'
import NewTask from '../components/NewTask.vue'

export default {
  mixins: [libvue],
  data() {
    return {
      recipeid: this.$route.params.RecipeID,
      example: `#!/bin/sh\n`,
      confirm: false,
      dialog: false,
      sdialog: false,
      svalue: null,
      subList: [],
      sall: false,
      fdialog: false,
      xdialog: false,
      xkey: null,
      xvalue: null,
      fileobjects: [],
      destinationRules: [
        v => v && v.length >= 1 && v.startsWith('/') || 'A path is required (filename is appended if it ends with a /)'
      ],
      destinationUserGroupRules: [
        v => v && v.length >= 1 && /^[_a-z][-0-9_a-z]*$/.test(v) || 'Should be a valid UNIX user or group'
      ],
      destinationModeRules: [
        v => { if (v && /^[0-7]+$/.test(v) && parseInt(v, 8) >= 0 && parseInt(v, 8) <= 777) return true ; else return 'Should be valid UNIX absolute permissions' }
      ],
      transfertimeoutRules: [
        v => v && v >=1 && v <= 3600 || 'Timeout must be between 1 and 3600s'
      ],
      switchRules: [
        v => !v || v && /^$|^[a-z]+[a-z0-9-]+$/.test(v) || 'Must be a lowercase alphanumeric'
      ],
      help: false,
      snack: false,
      snackColor: 'primary',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  components: { NewTask },
  mounted() {
  },
  watch: {
    sall: function(v) {
      if (v) {
        this.svalue = null;
      }
    },
    svalue: function(v) {
      if (v) {
        this.subList.push(v);
      }
    }
  },
  computed: {
    recipe: {
      get() {
        return this.allRecipes[this.recipeid];
      }
    },
    sticky: {
      get() {
        return this.recipe.sticky;
      },
      set(v) {
        this.recipe.sticky = v;
      },
    },
    approved: {
      get() {
        return this.recipe.approved;
      },
      set(v) {
        this.recipe.approved = v;
      },
    },
    argsrequired: {
      get() {
        return this.recipe.argsrequired;
      },
      set(v) {
        this.recipe.argsrequired = v;
      },
    },
    concurrent: {
      get() {
        return this.recipe.concurrent;
      },
      set(v) {
        this.recipe.concurrent = v;
      },
    },
    crosssite: {
      get() {
        return this.recipe.crosssite;
      },
      set(v) {
        this.recipe.crosssite = v;
      },
    },
    data: {
      get() {
        if (!this.recipe.data) {
          return this.example;
        }
        return this.recipe.data;
      },
      set(v) {
        this.recipe.data = v;
      }
    },
    ...mapGetters(['allRecipes', 'allFiles', 'getUserById', 'allUsers', 'isLoading'])
  },
  methods: {
    openLibraryPage() {
      this.$router.push({ name: 'library-page', params: { Tab: 0 } });
    },
    addFileObjectField() {
      this.fileobjects.push({
        fileobjectid: null,
        destination: null,
        destinationUser: 'root',
        destinationGroup: 'root',
        destinationMode: "644",
        destinationOverwrite: false,
        transfertimeout: 300
      });
    },
    delFileObjectField(i){
      this.fileobjects.splice(i, 1);
    },
    ufob() {
      this.fileobjects = [];
      for (var ft of this.recipe.filesList) {
        this.fileobjects.push({
          fileobjectid: ft.fileobjectid,
          destination: ft.filename,
          destinationUser: ft.fileperms.user,
          destinationGroup: ft.fileperms.group,
          destinationMode: ft.fileperms.mode,
          destinationOverwrite: ft.overwrite,
          transfertimeout: ft.transfertimeout
        });
      }
      if (this.fileobjects.length == 0) {
        this.fileobjects = [{
          fileobjectid: null,
          destination: null,
          destinationUser: 'root',
          destinationGroup: 'root',
          destinationMode: "644",
          destinationOverwrite: false,
          transfertimeout: 300
        }];
      }
    },
    callUpdateRecipe() {
      this.dialog = true;
      var args = Object.assign({}, this.recipe);
      args.data = this.data;
      switch (this.xkey) {
        case 'Name':
          args.name = this.xvalue;
          break;
        case 'Description':
          args.description = this.xvalue;
          break;
        case 'Switch':
          args.pb_switch = this.xvalue;
          break;
        case 'Run As':
          args.runas = this.xvalue;
          break;
        case 'ArgsRequired':
          args.argsrequired = this.xvalue;
          break;
        case 'Timeout':
          args.timeout = this.xvalue;
          break;
        case 'Concurrent':
          args.concurrent = this.xvalue;
          break;
        case 'CrossSite':
          args.crosssite = this.xvalue;
          break;
        case 'Sticky':
          args.sticky = this.xvalue;
          break;
        case 'FileObjectID':
          args.filesList = [];
          if (this.fileobjects.length > 0) {
            for (var fo of this.fileobjects) {
              if (!fo.fileobjectid || fo.fileobjectid == "") {
                this.dialog = false;
                this.snack = true;
                this.snackColor = 'error'
                this.snackMessage = 'No file is selected';
                return;
              }
              if (fo.destination.endsWith("/")) {
                fo.destination += this.allFiles[fo.fileobjectid].filename;
              }
              var fail = false;
              if (!fo.destination.startsWith('/') ||
                  !/^[_a-z][-0-9_a-z]*$/.test(fo.destinationUser) ||
                  !/^[_a-z][-0-9_a-z]*$/.test(fo.destinationGroup) ||
                  !/^[0-7]+$/.test(fo.destinationMode) ||
                  parseInt(fo.destinationMode, 8) < 0 ||
                  parseInt(fo.destinationMode, 8) > 777) {
                fail = true;
              }
              if (fail) {
                this.dialog = false;
                this.snack = true;
                this.snackColor = 'error'
                this.snackMessage = 'Destination, file owner and permissions are required';
                return;
              }
              args.filesList.push({
                fileobjectid: fo.fileobjectid,
                filename: fo.destination,
                overwrite: fo.destinationOverwrite,
                transfertimeout: fo.transfertimeout,
                fileperms: {
                  user: fo.destinationUser,
                  group: fo.destinationGroup,
                  mode: fo.destinationMode
                }
              });
            }
          }
          break;
        case 'Data':
          args.data = this.data;
          break;
      }
      this.updateRecipe(args).then(() => { this.dialog = false; }).catch(() => { this.dialog = false; });
    },
    callApproveRecipe() {
      this.dialog = true;
      this.approveRecipe({recipeid: this.recipeid, owner: this.owner, approved: this.approved}).then(() => {
        this.dialog = false;
      }).catch(() => {
        this.dialog = false;
      });
    },
    callDeleteRecipe() {
      this.dialog = true;
      this.deleteRecipe({recipeid: this.recipeid, owner: this.recipe.owner}).then(() => {
        this.dialog = false;
        this.openLibraryPage();
      }).catch(() => { this.dialog = false; });
    },
    getFiles() {
      var files = [];
      Object.values(this.allFiles).filter((v) => v.approved).sort((a, b) => a.filename.localeCompare(b.filename)).forEach((v) => {
        if (v.version && v.version != "") {
          v.filenameversion = v.filename + " (v " + v.version + ")";
        } else {
          v.filenameversion = v.filename;
        }
        files.push(v);
      });
      return files;
    },
    getUsers() {
      var users = [];
      if (this.allUsers) {
        Object.values(this.allUsers).filter((v) => v.sub != v.email).sort((a, b) => a.name.localeCompare(b.name)).forEach((v) => {
          v.key = v.name + " (" + v.email + ")";
          users.push(v);
        });
      }
      return users;
    },
    getUserEntry(email) {
      var entry = "";
      if (email && this.allUsers) {
        var v = Object.values(this.allUsers).filter((v) => v.email == email);
        if (v && v.length == 1) {
          entry = v[0].name + " (" + v[0].email + ")";
        }
      }
      return entry;
    },
    removeSvalue(sub) {
      var index = this.subList.indexOf(sub);
      if (index > -1) {
        this.subList.splice(index, 1);
      }
    },
    shareRecipe() {
      var args = {
        recipeid: this.recipe.recipeid,
        owner: this.recipe.owner,
      }
      this.getRecipeAccess(args).then((v) => {
        this.sall = v.sharewithall;
        this.svalue = null,
        this.subList = v.subList;
        this.sdialog = true;
      }).catch(() => {});
    },
    callSetRecipeAccess() {
      this.dialog = true;
      var args = {
        recipeid: this.recipe.recipeid,
        owner: this.recipe.owner,
        subList: this.subList,
        sharewithall: this.sall,
      }
      this.setRecipeAccess(args).then(() => { this.dialog = false; }).catch(() => { this.dialog = false; });
    },
    hasApproveAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.approveaccess;
      }
      return false;
    },
    hasAaaAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.aaaaccess;
      }
      return false;
    },
    hasNewTaskFullAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.newtaskfullaccess;
      }
      return false;
    },
    exportRecipe() {
      var dataStr = JSON.stringify(this.recipe, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = this.recipeid+'.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['approveRecipe', 'updateRecipe', 'deleteRecipe', 'getRecipeAccess', 'setRecipeAccess'])
  }
}
</script>
