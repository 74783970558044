<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Policies</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small text color="primary" @click.prevent="callSyncPolicies()">
                <v-icon dark left>sync</v-icon>
                Sync
              </v-btn>
              <v-btn small text color="primary" @click.prevent="callFilterPolicies()">
                <v-icon dark left>refresh</v-icon>
                Refresh
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small text color="primary" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-card>
                      <v-card-actions>
                        <v-list dense>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportPolicies()">
                              <v-icon dark left>get_app</v-icon>
                              Export
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex m12>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :footer-props="{
                'items-per-page-options': itemsPerPage
              }"
              :items="Object.values(allPolicies)"
              :loading="isLoading.policies"
              :search="search"
              :sort-by="'name'"
              :sort-desc="false"
              item-key="sub"
              class="elevation-1"
              dense
              @update:options="updatePagination"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.policyid">
                    <td><v-icon small>policy </v-icon></td>
                    <td>{{ item.policyid }}</td>
                    <td>{{ item.owner }}</td>
                    <td>{{ item.sub }}</td>
                    <td>{{ item.subrule }}</td>
                    <td>{{ item.obj }}</td>
                    <td>{{ item.act }}</td>
                    <td>{{ item.eft }}</td>
                    <td>{{ item.comment }}</td>
                    <!-- <td>
                      <v-btn icon text :disabled="snack" @click.stop="policy = item; confirmDelete = true;">
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      v-model="confirmDelete"
      width="500"
      v-if="policy"
    >
      <v-card>
        <v-card-title>Delete selected Policy?</v-card-title>
        <v-card-text>
          "{{ policy.policyid }}" will be deleted. You can't undo this action.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmDelete = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callRemovePolicy(); confirmDelete = false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  
    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import libvue from '../lib/vue.js'

export default {
  mixins: [libvue],
  data() {
    return {
      search: '',
      headers: [
        { text: '', sortable: false, value: '' },
        { text: 'PolicyID', sortable: true, value: 'policyid' },
        { text: 'Owner', sortable: true, value: 'owner' },
        { text: 'Sub', sortable: true, value: 'sub' },
        { text: 'SubRule', sortable: true, value: 'subrule' },
        { text: 'Obj', sortable: true, value: 'obj' },
        { text: 'Act', sortable: true, value: 'act' },
        { text: 'Eft', sortable: true, value: 'eft' },
        { text: 'Comment', sortable: true, value: 'comment' },
        // { text: 'Delete', sortable: false, value: '' }
      ],
      policy: null,
      policydialog: false,
      confirmDelete: false,
      dialog: false,
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    ...mapGetters(['allPolicies', 'userConfig', 'isLoading'])
  },
  mounted() {
    this.callFilterPolicies();
  },
  methods: {
    callFilterPolicies() {
      this.filterPolicies().then(() => {}).catch(() => {});
    },
    callSyncPolicies() {
      this.dialog = true;
      this.syncPolicies().then(() => { this.dialog = false; }).catch(() => { this.dialog = false; });
    },
    callRemovePolicy() {
      this.removePolicy(this.policy).then(() => {}).catch(() => {});
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportPolicies() {
      var dataStr = JSON.stringify(this.allPolicies, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'policies.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['filterPolicies', 'syncPolicies', 'removePolicy', 'setUserConfig'])
  }
}
</script>
