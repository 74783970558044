<template>
  <div class="text-xs">
    <v-dialog
      v-model="dialog"
      hide-overlay
      width="800"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          small
          text
          color="primary"
          v-on="on"
          @click.prevent="refreshInstances(agentid)"
        >
          <v-icon small dark left>history</v-icon> History
        </v-btn>
      </template>

      <v-card>
        <v-card-title>{{ agentid }}</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :footer-props="{
              'items-per-page-options': itemsPerPage
            }"
            :items="Object.values(allInstances)"
            :loading="isLoading.instances"
            :search="search"
            :sort-by="'registerat'"
            :sort-desc="true"
            :page.sync="page"
            @page-count="pageCount = $event"
            item-key="clientid"
            class="elevation-0"
            dense
            @update:options="updatePagination"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.clientid" @click="select(item)" :style="{cursor:'pointer'}">
                  <td >{{ item.clientid }}</td>
                  <td >{{ dateToString(item.registerat) }}</td>
                  <td >{{ dateToString(item.offlineat) }}</td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer>
              <div align="center" v-if="page == pageCount || pageCount == 0">
                <v-btn small text color="primary" @click.prevent="getInstancesMore()" :disabled="bce(start)" v-if="!isLoading.instances">
                  Load More
                </v-btn>
                <v-btn small text color="primary" :disabled="true" v-else>
                  Loading...
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="infodialog"
      hide-overlay
      width="600"
    >
      <v-card v-if="selected">
        <v-card-title>{{ selected.clientid }}</v-card-title>
        <v-card-text>
          <pre>{{ selected }}</pre>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="infodialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import libvue from '../lib/vue.js'
import config from '../../config/config.json';

export default {
  mixins: [libvue],
  data () {
    return {
      dialog: false,
      infodialog: false,
      page: 1,
      pageCount: 0,
      selected: null,
      search: '',
      headers: [
        { text: 'Client ID', sortable: true, value: 'clientid' },
        { text: 'Register At', sortable: true, value: 'registerat' },
        { text: 'Offline At', sortable: true, value: 'offlineat' },
      ],
      start: 0,
      limit: 0,
      snack: false,
      snackColor: 'primary',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  props: {
    _agentid: String
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    agentid: {
      get() {
        return this._agentid
      },
      set(v) {
        this._agentid = v
      }
    },
    ...mapGetters(['allInstances', 'userConfig', 'isLoading'])
  },
  methods: {
    select(v) {
      this.selected = v;
      this.infodialog = true;
    },
    refreshInstances() {
      this.clearInstances();
      this.start = (new Date().getTime()-config.historyMs) * 1e6;
      this.limit = new Date().getTime() * 1e6;
      this.getInstances().then(() => {}).catch(() => {});
    },
    getInstances(more) {
      var args = {start: this.start, limit: this.limit, more: more, agentid: this.agentid};
      return this.filterInstances(args);
    },
    getInstancesMore(warp) {
      if (!warp) warp = 1;
      var n = Object.keys(this.allInstances).length;
      this.limit = this.start;
      this.start = this.start - config.historyMs * 1e6 * warp;
      this.getInstances(true).then(() => {
        if (Object.keys(this.allInstances).length === n) {
          if (this.start < config.beginningMs * 1e6) return; // before 20200101
          this.getInstancesMore(warp + 1); // incremental
        }
      }).catch(() => {});
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    ...mapActions(['filterInstances', 'clearInstances', 'setUserConfig'])
  }
}
</script>
