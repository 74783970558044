<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Users</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small text color="primary" @click.prevent="displayAddUser()">
                <v-icon dark left>account_circle</v-icon>
                Add
              </v-btn>
              <v-btn small text color="primary" @click.prevent="callFilterUsers()">
                <v-icon dark left>refresh</v-icon>
                Refresh
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small text color="primary" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-card>
                      <v-card-actions>
                        <v-list dense>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportUsers()">
                              <v-icon dark left>get_app</v-icon>
                              Export
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex m12>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :footer-props="{
                'items-per-page-options': itemsPerPage
              }"
              :items="Object.values(allUsers)"
              :loading="isLoading.users"
              :search="search"
              :sort-by="'name'"
              :sort-desc="false"
              item-key="sub"
              class="elevation-1"
              dense
              @update:options="updatePagination"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.sub" @click.stop="displayUser(item)" :disabled="snack" :style="{cursor:'pointer'}">
                    <td><v-icon small>person</v-icon></td>
                    <!-- <td>{{ item.sub }}</td> -->
                    <td>{{ item.name }}</td>
                    <td>{{ item.nickname }}</td>
                    <td>{{ item.email }}</td>
                    <td>
                      <v-icon small v-if="item.attributes.access">check_circle</v-icon>
                      <v-icon small v-if="!item.attributes.access">panorama_fish_eye</v-icon>
                      <v-icon small color="red" v-if="item.attributes.aaaaccess">check_circle</v-icon>
                      <v-icon small v-if="!item.attributes.aaaaccess">panorama_fish_eye</v-icon>
                      <v-icon small v-if="item.attributes.coolaccess">check_circle</v-icon>
                      <v-icon small v-if="!item.attributes.coolaccess">panorama_fish_eye</v-icon>
                      <v-icon small v-if="item.attributes.spacesaccess">check_circle</v-icon>
                      <v-icon small v-if="!item.attributes.spacesaccess">panorama_fish_eye</v-icon>
                      <v-icon small v-if="item.attributes.imagemakeraccess">check_circle</v-icon>
                      <v-icon small v-if="!item.attributes.imagemakeraccess">panorama_fish_eye</v-icon>
                      <v-icon small v-if="item.attributes.certmakeraccess">check_circle</v-icon>
                      <v-icon small v-if="!item.attributes.certmakeraccess">panorama_fish_eye</v-icon>
                      <v-icon small v-if="item.attributes.dashboardaccess">check_circle</v-icon>
                      <v-icon small v-if="!item.attributes.dashboardaccess">panorama_fish_eye</v-icon>
                    </td>
                    <td>{{ dateToString(item.created) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      v-model="userdialog"
      width="550"
      v-if="user"
    >
      <v-card class="elevation-0">
        <v-card-title v-if="!newuser">{{ user.name }}</v-card-title>
        <v-card-title v-else>Add new user</v-card-title>
        <v-card-text v-if="!newuser">
          {{ user.email }} with {{ user.sub }}
        </v-card-text>
        <v-card-text v-else>
          <v-flex md8>
            <v-text-field
            v-model="user.email"
            label="Email"
            :rules="emailRules"
            required
            ></v-text-field>
          </v-flex>
        </v-card-text>
        <v-card-text>
          <v-flex md12>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has access to Agent information?</td>
                    </template>
                    <span>User can discover information about Agents specified in policies</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.access">
                  </td>
                </tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has <span style="color: red;">AAA</span> access?</td>
                    </template>
                    <span>User has unrestricted access including modifying users and viewing audit logs</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.aaaaccess">
                  </td>
                </tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Can approve object and actions?</td>
                    </template>
                    <span>User can approve, in combination with service access, files, recipies, templates, ...</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.approveaccess">
                  </td>
                </tr>
                <tr><td colspan="2"></td></tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has access to CoolAgent?</td>
                    </template>
                    <span>Has access to CoolAgent</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.coolaccess">
                  </td>
                </tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Can create certificates in CoolAgent?</td>
                    </template>
                    <span>User can create certificates for authorized agents</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.certificateaccess" :disabled="!user.attributes.coolaccess">
                  </td>
                </tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has full access to CoolAgent tasks and console?</td>
                    </template>
                    <span>User can create tasks not bound by recipies and open console</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.newtaskfullaccess" :disabled="!user.attributes.coolaccess">
                  </td>
                </tr>
                <tr><td colspan="2"></td></tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has access to Spaces?</td>
                    </template>
                    <span>Has any form of access to Spaces</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.spacesaccess">
                  </td>
                </tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has access to ImageMaker?</td>
                    </template>
                    <span>Has any form of access to ImageMaker</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.imagemakeraccess">
                  </td>
                </tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has access to CertMaker?</td>
                    </template>
                    <span>User can create certificates for authorized agents</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.certmakeraccess" :disabled="!user.attributes.access">
                  </td>
                </tr>
                <tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <td v-on="on">Has access to Dashboard?</td>
                    </template>
                    <span>Has any form of access to Dashboard</span>
                  </v-tooltip>
                  <td>
                    <input type="checkbox" v-model="user.attributes.dashboardaccess">
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click.prevent="confirmDelete = true; userdialog = false" :disabled="user.sub != user.email" v-if="!newuser">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="userdialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callUpdateUserAttributes(); userdialog = false" v-if="!newuser">Save</v-btn>
          <v-btn color="primary" text @click.prevent="callNewUser(); userdialog = false" v-else>Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDelete"
      width="500"
      v-if="user"
    >
      <v-card>
        <v-card-title>Delete selected User?</v-card-title>
        <v-card-text>
          "{{ user.email }}" will be deleted. You can't undo this action.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirmDelete = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="confirmDelete = false; callDeleteUser();">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  
    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import libvue from '../lib/vue.js'

const {User} = require('../pb/user_pb.js');
const {Attributes} = require('../pb/attributes_pb.js');

export default {
  mixins: [libvue],
  data() {
    return {
      dialog: false,
      search: '',
      headers: [
        { text: '', sortable: false, value: '' },
        // { text: 'Sub', sortable: true, value: 'sub' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Nickname', sortable: true, value: 'nickname' },
        { text: 'Email', sortable: true, value: 'email' },
        // { text: 'CoolAccess', sortable: true, value: 'attributes' },
        { text: 'A A C S I C D', sortable: true, value: 'attributes' },
        { text: 'Created', sortable: true, value: 'created' }
      ],
      user: null,
      userdialog: false,
      newuser: false,
      emailRules: [
        v => v && v.length >= 1 && /\S+@\S+\.\S+/.test(v) || 'Should be a valid email address'
      ],
      confirmDelete: false,
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    ...mapGetters(['allUsers', 'userConfig', 'isLoading'])
  },
  mounted() {
    this.callFilterUsers();
  },
  methods: {
    callFilterUsers() {
      this.filterUsers().then(() => {}).catch(() => {});
    },
    displayUser(v) {
      this.newuser = false;
      this.user = v;
      this.userdialog = true;
    },
    displayAddUser() {
      this.newuser = true;
      var user = new User();
      var attrs = new Attributes();
      user.setAttributes(attrs);
      this.user = user.toObject();
      this.userdialog = true;
    },
    callNewUser() {
      if (!/\S+@\S+\.\S+/.test(this.user.email)) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackMessage = 'Invalid email entered';
        return;
      }
      this.dialog = true;
      this.user.sub = this.user.email; // sub will be replaced by real sub on first user login.
      this.newUser(this.user).then((v) => {
        this.dialog = false;
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = 'User created ('+v.email+')';
        // this.sleep(1000).then(() => {
          // Reload policies.
          // this.syncPolicies().then(() => {}).catch(() => {});
        // });
      }).catch(() => { this.dialog = false; });
    },
    callDeleteUser() {
      if (this.user.sub != this.user.email) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackMessage = 'User cannot be deleted';
        return;        
      }
      this.dialog = true;
      this.deleteUser(this.user).then((v) => {
        this.dialog = false;
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = 'User deleted ('+v.email+')';
        // this.sleep(1000).then(() => {
          // Reload policies.
          // this.syncPolicies().then(() => {}).catch(() => {});
        // });
      }).catch(() => { this.dialog = false; });
    },
    callUpdateUserAttributes() {
      this.updateUserAttributes(this.user).then(() => {
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = 'User attributes updated'
      }).catch(() => {});
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportUsers() {
      var dataStr = JSON.stringify(this.allUsers, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'users.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['filterUsers', 'updateUserAttributes', 'newUser', 'deleteUser', 'syncPolicies', 'setUserConfig'])
  }
}
</script>
