<template>
  <div class="text-xs">
    <v-dialog
      v-model="show"
      fullscreen
      transition="dialog-bottom-transition"
      v-if="request"
    >

      <v-toolbar color="primary" dark dense>
        <v-btn icon dark @click.stop="show = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Request Details</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-flex md12>
        <v-card>
          <v-card-title>
            <v-btn text icon color="primary" @click.prevent="openTasksPage()" v-if="$route.params.RequestID"> 
              <v-icon dark>arrow_back</v-icon>
            </v-btn>
            <!-- <h4> {{ requestid }} </h4> -->
            <v-spacer></v-spacer>
            <NewTask ref="NewTask" :requestid="requestid" @submitted="onChildSubmit" />
            <v-btn small text color="primary" @click.prevent="callCancelRequest()" :disabled="!canCancelRequest()">
              <v-icon dark left>cancel</v-icon>
              Cancel
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small text color="primary" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-layout row wrap>
                <v-flex md12>
                  <v-card>
                    <v-card-actions>
                      <v-list dense>
                        <v-list-item>
                          <v-btn small text color="primary" @click.prevent="exportTaskCSV()">
                            <v-icon dark left>get_app</v-icon>
                            Export (CSV)
                          </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn small text color="primary" @click.prevent="exportTask()">
                            <v-icon dark left>get_app</v-icon>
                            Export (JSON)
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-menu>
          </v-card-title>
        </v-card>
      </v-flex>

      <v-flex md12>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md6>
                  <v-card>
                    <v-card-title>Request</v-card-title>
                    <v-card-text>
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td>ID</td>
                            <td>{{ requestid }}</td> 
                          </tr>
                          <tr>
                            <td>Owner</td>
                            <td>{{ request.owner }}</td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td v-if='request.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                            <td v-else-if='request.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                            <td v-else-if='request.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                            <td v-else-if='request.status == taskStatus().COMPLETED'><v-icon small color="success">done_all</v-icon>COMPLETED</td>
                            <td v-else-if='request.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                            <td v-else-if='request.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                            <td v-else><v-icon small color="error">error</v-icon>UNKNOWN</td>
                          </tr>
                          <tr>
                            <td>Submitted</td>
                            <td>{{ dateToStringMs(request.submitted) }}</td>
                          </tr>
                          <tr>
                            <td>Completed</td>
                            <td>{{ dateToStringMs(request.completed) }}</td>
                          </tr>
                          <tr>
                            <td>Filter</td>
                            <td>{{ filterToString(request.filterList) }}</td>
                          </tr>
                          <tr>
                            <td>Recipe&nbsp;ID</td>
                            <v-tooltip top v-if='request.recipeid != ""'>
                              <template v-slot:activator="{ on }">
                                <td class="underline" v-on="on" @click.prevent="gotoRecipe(request.recipeid)" :style="{cursor:'pointer'}">{{ request.recipeid }}</td>
                              </template>
                              <span>{{ recipeInfo(request.recipeid) }}</span>
                            </v-tooltip>
                            <td v-else></td>
                          </tr>
                          <tr>
                            <td>Command</td>
                            <td v-if="request.recipeid == ''">{{ request.command }}</td>
                            <td v-else-if="hasNewTaskFullAccess()" @click.prevent="displayRecipeCommand(request.recipeid)" :style="{cursor:'pointer'}">
                              <span style="color:blue">click to show</span>
                            </td>
                            <td v-else></td>
                          </tr>
                          <tr>
                            <td>Arguments</td>
                            <td>{{ request.args }}</td>
                          </tr>
                          <tr>
                            <td>Cross&nbsp;Site</td>
                            <td>{{ request.crosssite }}</td>
                          </tr>
                          <tr>
                            <td>Run&nbsp;As</td>
                            <td>{{ request.runas }}</td>
                          </tr>
                          <tr>
                            <td>JobTimeout</td>
                            <td>{{ request.jobtimeout }}</td>
                          </tr>
                          <tr>
                            <td>Job&nbsp;Max&nbsp;Delay</td>
                            <td>{{ request.jobmaxdelay }}</td>
                          </tr>
                          <tr>
                            <td colspan="2">Schedule</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Start</td>
                            <td>{{ dateToStringMs(request.schedule.start) }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Stop</td>
                            <td>{{ dateToStringMs(request.schedule.expiration) }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Interval</td>
                            <td>{{ request.schedule.interval }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Sticky</td>
                            <td>{{ request.schedule.sticky }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Future</td>
                            <td>{{ request.schedule.future }}</td>
                          </tr>
                          <tr>
                            <td>Concurrent</td>
                            <td>{{ request.concurrent }}</td>
                          </tr>
                          <tr>
                            <td>Files</td>
                            <td>{{ request.filesList.length }}</td>
                          </tr>
                          <tr>
                            <td>Last&nbsp;Modified</td>
                            <td>{{ dateToStringMs(request.lastmodified) }}</td>
                          </tr>
                          <tr>
                            <td>Modified&nbsp;By</td>
                            <td>{{ request.modifiedby }}</td>
                          </tr>
                          <tr>
                            <td>Remote&nbsp;Address</td>
                            <td>{{ request.remoteaddress }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex md6>
                  <v-card>
                    <v-card-title>Summary</v-card-title>
                    <v-card-text>
                      <v-simple-table dense>
                        <tbody>
                          <tr>
                            <td>Tasks Created</td>
                            <td>{{ request.tasks }}</td>
                          </tr>
                          <tr>
                            <td>Jobs Created</td>
                            <td>{{ jobsTotal() }}</td>
                          </tr>
                          <tr>
                            <td>Jobs Succeded</td>
                            <td>{{ jobsSucceeded() }}</td>
                          </tr>
                          <tr>
                            <td>FileTransfers Created</td>
                            <td>{{ ftTotal() }}</td>
                          </tr>
                          <tr>
                            <td>FileTransfers Succeded</td>
                            <td>{{ ftSucceeded() }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>

                  <v-card>
                    <v-card-title>Files</v-card-title>
                    <v-card-text>
                      <v-simple-table dense>
                        <tbody v-for="ft in request.filesList" :key="ft.fileobjectid">
                          <tr>
                            <td style="text-align: right;">FileObjectID</td>
                            <td class="underline" @click.prevent="gotoFile(ft.fileobjectid)" :style="{cursor:'pointer'}">
                              {{ ft.fileobjectid }}
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Filename</td>
                            <td>{{ ft.filename }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Permissions</td>
                            <td>{{ ft.fileperms.user+":"+ft.fileperms.group+" "+ft.fileperms.mode.toString(8) }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Overwrite</td>
                            <td>{{ ft.overwrite }}</td>
                          </tr>
                          <tr>
                            <td style="text-align: right;">Timeout</td>
                            <td>{{ ft.transfertimeout }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-flex md12>
                  <v-card>
                    <v-card-title>
                      Aggregated Status
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="aggregatedHeaders"
                        :items="aggregatedList"
                        :search="search"
                        :sort-by="'agentid'"
                        :sort-desc="false"
                        :footer-props="{
                          'items-per-page-options': itemsPerPage
                        }"
                        item-key="skey"
                        class="elevation-1"
                        dense
                        @update:options="updatePagination"
                      >
                        <template v-slot:body="{ items }">
                          <tbody>
                            <tr v-for="item in items" :key="item.skey">
                              <td><v-icon small>assignment</v-icon></td>
                              <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                              <td v-if="item.hostid">{{ item.hostid }}</td>
                              <td v-else>{{ smallId(item.clientid) }}</td>
                              <!-- <td>{{ item.owner }}</td> -->
                              <td>{{ dateToString(item.submitted) }}</td>
                              <td>{{ dateToString(item.completed) }}</td>
                              <!-- <td>{{ smallStr(item.command, 15) }}</td> -->
                              <td v-if='(item.jobid || item.filetransferid) && item.status == jobStatus().STARTING'><v-icon small color="info">hourglass_full</v-icon>STARTING</td>
                              <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().STARTED'><v-icon small color="info">hourglass_full</v-icon>STARTED</td>
                              <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().DONE'><v-icon small color="success">done</v-icon>DONE</td>
                              <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().FAILED'><v-icon small color="error">error</v-icon>FAILED</td>
                              <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().TIMEOUT'><v-icon small color="error">hourglass_empty</v-icon>TIMEOUT</td>
                              <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().NOTFOUND'><v-icon small color="error">error</v-icon>NOTFOUND</td>
                              <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().NOREPLY'><v-icon small color="warning">warning</v-icon>NOREPLY</td>
                              <td v-else-if='item.taskid && item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                              <td v-else-if='item.taskid && item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                              <td v-else-if='item.taskid && item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                              <td v-else-if='item.taskid && item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                              <td v-else-if='item.taskid && item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                              <td v-else-if='item.taskid && item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                              <td v-else-if='item.requestid && item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                              <td v-else-if='item.requestid && item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                              <td v-else-if='item.requestid && item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                              <td v-else-if='item.requestid && item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                              <td v-else-if='item.requestid && item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                              <td v-else-if='item.requestid && item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                              <td v-else><v-icon small color="error">error</v-icon>UNKNOWN</td>
                              <td>{{ item.exitstatus }}</td>
                              <td>
                                <v-btn color="primary" x-small @click.prevent="displayCombinedOutput(item)" :style="{cursor:'pointer'}" v-if="item.combinedoutput">Show</v-btn>
                                <v-btn color="error" x-small @click.prevent="displayErrorOutput(item)" :style="{cursor:'pointer'}" v-else-if="item.error">Show</v-btn>
                                <v-btn color="info" x-small @click.prevent="displayFileTransfer(item)" v-else-if="item.fileobjectid && item.fileobjectid != ''">Show</v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-flex>

              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>

      <v-dialog
        v-model="showCommand"
        hide-overlay
        width="800"
      >
        <v-card>
          <v-card-title>Command</v-card-title>
          <v-card-text>
            <pre>{{ command }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showCommand = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showCombinedOutput"
        hide-overlay
        width="800"
      >
        <v-card>
          <v-card-title>Output on {{ hostid }}</v-card-title>
          <v-card-text>
            <pre>{{ combinedOutput }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showCombinedOutput = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snack"
        :color="snackColor"
        :elevation="24"
        :timeout="snackTimeout"
        :top="true"
      >
        {{ snackMessage }}
        <v-btn
          text
          @click="snack = false"
        >
          <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
          Close
        </v-btn>
      </v-snackbar>

    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import libvue from '../lib/vue.js'
import NewTask from '../components/NewTask.vue'

export default {
  mixins: [libvue],
  data () {
    return {
      taskid: null,
      jobid: null,
      transferid: null,
      command: null,
      showCommand: false,
      showCombinedOutput: false,
      combinedOutput: null,
      hostid: null,
      search: null,
      aggregatedHeaders: [
        { text: '', sortable: false, value: '' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        { text: 'HostID', sortable: true, value: 'hostid' },
        // { text: 'Owner', sortable: true, value: 'owner' },
        // { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Completed', sortable: true, value: 'completed' },
        // { text: 'Recipe/Command', sortable: true, value: 'command' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Exit Status', sortable: true, value: 'exitstatus' },
        { text: 'Output', sortable: false, value: 'combinedoutput' }
      ],
      snack: false,
      snackColor: 'primary',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  props: {
    value: Boolean,
    _request: Object,
    _aggregatedList: Array
  },
  watch: {
    show() {
      this.request = this._request;
    }
  },
  mounted() {
  },
  components: { NewTask },
  computed: {
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    requestid: {
      get() {
        if (this._request) {
          return this._request.requestid;
        } else {
          return this.$route.params.RequestID;
        }
      }
    },
    request: {
      get() {
        return this._request;
      },
      set(v) {
        this._request = v;
      }
    },
    aggregatedList: {
      get() {
        return this._aggregatedList.filter((s) => s.requestid == this.requestid && s.agentid);
      },
    },
    ...mapGetters(['allRequests', 'allTasks', 'allJobs', 'allFileTransfers', 'getRecipeById', 'selectedRequest', 'userConfig', 'getUserById', 'isLoading']),
  },
  methods: {
    onChildSubmit() {
      this.show = false;
    },
    agregatedStatus() {
      var agsOK = 0;
      var agsFail = 0;
      this.jobs.forEach((j) => {
        switch (j.status) {
          // eslint-disable-next-line no-undef
          case proto.pb.JobStatus.DONE:
            agsOK++;
            break;
          // eslint-disable-next-line no-undef
          case proto.pb.JobStatus.FAILED:
            agsFail++;
            break;
          // eslint-disable-next-line no-undef
          case proto.pb.JobStatus.TIMEOUT:
            agsFail++;
            break;
          // eslint-disable-next-line no-undef
          case proto.pb.JobStatus.NOTFOUND:
            agsFail++;
            break;
          // eslint-disable-next-line no-undef
          case proto.pb.JobStatus.NOREPLY:
            agsFail++;
            break;
        }
      });
      if (agsFail == 0 && agsOK > 0) {
        return "OK";
      }
      if (agsFail > 0 && agsOK > 0) {
        return "Partial FAIL";
      }
      if (agsFail > 0 && agsOK == 0) {
        return "FAIL";
      }
    },
    jobsTotal() {
      // eslint-disable-next-line no-undef
      return this._aggregatedList.filter((j) => j.jobid && j.requestid == this.requestid).length;
    },
    jobsSucceeded() {
      // eslint-disable-next-line no-undef
      return this._aggregatedList.filter((j) => j.jobid && j.requestid == this.requestid && j.status == proto.pb.JobStatus.DONE).length;
    },
    ftTotal() {
      // eslint-disable-next-line no-undef
      return this._aggregatedList.filter((ft) => ft.filetransferid && ft.requestid == this.requestid).length;
    },
    ftSucceeded() {
      // eslint-disable-next-line no-undef
      return this._aggregatedList.filter((ft) => ft.filetransferid && ft.requestid == this.requestid && ft.status == proto.pb.JobStatus.DONE).length;
    },
    displayRecipeCommand(v) {
      var r =  this.getRecipeById(v);
      if (!r) {
        this.command = "(Not found or no access permissions)";
      } else {
        this.command = this.getRecipeById(v).data;
      }
      this.showCommand = true;
    },
    displayCombinedOutput(v) {
      this.combinedOutput = this.decodeCombinedOutput(v.combinedoutput);
      if (v.hostid) {
        this.hostid = v.hostid + " (Job)";
      } else {
        this.hostid = v.clientid + " (Job)";
      }
      this.showCombinedOutput = true;
    },
    displayErrorOutput(v) {
      this.combinedOutput = v.error;
      if (v.hostid) {
        this.hostid = v.hostid + " (File Transfer)";
      } else {
        this.hostid = v.clientid + " (File Transfer)";
      }
      this.showCombinedOutput = true;
    },
    displayFileTransfer(v) {
      this.combinedOutput = v.filename + " (" + v.fileperms.user + ":" + v.fileperms.group + " " + v.fileperms.mode + ")";
      if (v.hostid) {
        this.hostid = v.hostid + " (File Transfer)";
      } else {
        this.hostid = v.clientid + " (File Transfer)";
      }
      this.showCombinedOutput = true;
    },
    recipeInfo(v) {
      var r = this.getRecipeById(v);
      if (!r) {
        return "(Not found or no access permissions)";
      }
      return r.name + ' (' + r.description + ')';
    },
    openAgentsPage() {
      this.$router.push({ name: 'agents-page' });
    },
    openAgentDetailsPage(agentid) {
      this.$router.push({ name: 'agent-details', params: { AgentID: agentid } });
    },
    openTasksPage() {
      this.$router.push({ name: 'tasks-page' });
    },
    canCancelRequest() {
      switch (this.request.status) {
        // eslint-disable-next-line no-undef
        case proto.pb.TaskStatus.PENDING:
          return true;
        // eslint-disable-next-line no-undef
        case proto.pb.TaskStatus.RUNNING:
          return true;
        default:
          return false;
      }
    },
    callCancelRequest() {
      switch (this.request.status) {
        // eslint-disable-next-line no-undef
        case proto.pb.TaskStatus.PENDING:
          break;
        // eslint-disable-next-line no-undef
        case proto.pb.TaskStatus.RUNNING:
          break;
        default:
          this.snack = true;
          this.snackColor = 'error';
          this.snackMessage = "Task has completed"
          return
      }
      this.cancelRequest({
        requestid: this.requestid,
        owner: this.request.owner,
        submitted: this.request.submitted
      }).then((v) => {
        this.show = false;
        this.snack = true;
        this.snackColor = 'success'
        this.snackMessage = "Cancelled Request with ID: " + v.requestid;
      }).catch(() => { this.show = false; });
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    hasNewTaskFullAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.newtaskfullaccess;
      }
      return false;
    },
    hasAaaAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.aaaaccess;
      }
      return false;
    },
    exportTask() {
      var parent = this;
      var data = Object.assign({}, this.request);
      data["tasks"] = (function() {
        var tasks = [];
        var grandpa = parent;
        Object.values(parent.allTasks).forEach((t) => {
          if (parent.idFromKey(t.requestkey) == parent.requestid) {
            var task = Object.assign({}, t);
            task.status = parent.taskStatusToString(task.status);
            task["jobs"] = (function() {
              var jobs = [];
              Object.values(grandpa.allJobs).forEach((j) => {
                if (grandpa.idFromKey(j.taskkey) == t.taskid) {
                  var job = Object.assign({}, j);
                  job.status = grandpa.jobStatusToString(job.status);
                  jobs.push(job);
                }
              });
              return jobs;
            })();
            tasks.push(task);
          }
        });
        return tasks;
      })();
      var dataStr = JSON.stringify(data, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'task-'+this.requestid+'.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    exportTaskCSV() {
      var parent = this;
      var dataStr = "RequestID,AgentID,Owner,Submitted,Completed,Recipe,Command,Status,ExitStatus,Output\n";
      this.aggregatedList.forEach((a) => {
        var r = parent.allRequests[a.requestid];
        var data = a.requestid + "," + a.agentid + "," + a.owner + ","
                  + parent.dateToStringEmpty(a.submitted) + "," + parent.dateToStringEmpty(a.completed) + ","
                  + r.recipeid + "," + JSON.stringify(r.command).replace(",", "&#44;") + ","
                  + parent.jobStatusToString(a.status) + "," + a.exitstatus + "," + JSON.stringify(this.decodeCombinedOutput(a.combinedoutput)).replace(",", "&#44;") + "\n";
        dataStr += data;
      });
      // var dataStr = JSON.stringify(allData, null, 2);
      var dataUri = 'data:application/csv;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'task-'+this.requestid+'.csv';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['cancelRequest', 'setUserConfig'])
  }
}
</script>
