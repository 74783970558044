import config from '../../config/config.json';

/* eslint-disable no-undef */
var libvue = {
  methods: {
    pagination(v) {
      var items = [15, 25, 50, 75, 100, 150];
      switch (v) {
        case 15:
          items = [15, 25, 50, 75, 100, 150];
          break;
        case 25:
          items = [25, 15, 50, 75, 100, 150];
          break;
        case 50:
          items = [50, 15, 25, 75, 100, 150];
          break;
        case 75:
          items = [75, 15, 25, 50, 100, 150];
          break;
        case 100:
          items = [100, 15, 25, 50, 75, 150];
          break;
        case 150:
          items = [150, 15, 25, 50, 75, 100];
          break;
      }
      return items;
    },
    bce(ce) {
      return ce <= config.beginningMs*1e6;
    },
    smallStr(v, n) {
      if (v && n && v.length > n && n > 3) {
        return v.slice(0, n-3) + "...";
      }
      return v
    },
    idFromKey(v) {
      if (v) return v.slice(20);
      return v;
    },
    smallKey(v) {
      if (v) return v.split('-')[1];
      return v;
    },
    smallId(id) {
      if (id) return id.slice(0, 8);
      return id;
    },
    authMethod(sub) {
      if (sub) return sub.split('|')[0];
      return sub;
    },
    inTags(tags, tag) {
      var rc = false;
      if (tags) {
        tags.forEach((r) => {
          if (r == tag) {
            rc = true;
          }
        });
      }
      return rc;
    },
    prettyTags(v) {
      var tags = "";
      if (v) {
        v.forEach((r) => {
          tags += r.toLowerCase() + " ";
        });
      }
      return tags.trim();
    },
    prettyLabels(v) {
      var labels = "";
      if (v) {
        v.forEach((r) => {
          labels += r[0] + "=" + r[1] + " ";
        });
      }
      return labels.trim();
    },
    prettyAlarms(v, clientid) {
      var alarms = "";
      if (v) {
        v.forEach((r) => {
          if (r.status == "ON") {
            if (clientid == null || clientid == r.clientid) {
              alarms += r.name + " ";
            }
          }
        });
      }
      return alarms.trim();
    },
    prettyDaemons(v) {
      var daemons = "";
      if (v) {
        v.forEach((r) => {
          daemons += r.name + " ";
        });
      }
      return daemons.trim();
    },
    dateToString(v) {
      if (v > 0) {
        return new Date(v / 1e6).toISOString().slice(0, -5)+'Z';
      }
      return '-';
    },
    dateToStringMs(v) {
      if (v > 0) {
        return new Date(v / 1e6).toISOString();
      }
      return '-';
    },
    dateToStringEmpty(v) {
      if (v > 0) {
        return new Date(v / 1e6).toISOString().slice(0, -5)+'Z';
      }
      return '';
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    gotoRequest(v) {
      this.$router.push({ path: `/requests/${v}`});
    },
    gotoFile(v) {
      this.$router.push({ path: `/files/${v}`});
    },
    gotoRecipe(v) {
      this.$router.push({ path: `/recipes/${v}`});
    },
    taskStatus() {
      return proto.pb.TaskStatus;
    },
    filterToString(v) {
      if (!v) return [];
      var filter = ''
      v.forEach(f => {
        if (filter.length > 1) {
          filter += f.multioperator + ' ';
        }
        filter += f.field + ' ' + f.operator + ' ' + f.patternList + ' ';
      });
      return filter;
    },
    decodeCombinedOutput(v) {
      var str = ""
      if (v != "") {
        try {
          str = atob(v);
        } catch {
          str = ""
        }
      }
      return str;
    },
    taskStatusToString(status) {
      switch (status) {
        case proto.pb.TaskStatus.PENDING:
          return "PENDING";
        case proto.pb.TaskStatus.PREPARING:
          return "PREPARING";
        case proto.pb.TaskStatus.RUNNING:
          return "RUNNING";
        case proto.pb.TaskStatus.COMPLETED:
          return "COMPLETED";
        case proto.pb.TaskStatus.CANCELLED:
          return "CANCELLED";
        case proto.pb.TaskStatus.EXPIRED:
          return "EXPIRED";
        default:
          return "UNKNOWN";
      }
    },
    jobStatus() {
      return proto.pb.JobStatus;
    },
    jobStatusToString(status) {
      switch (status) {
        case proto.pb.JobStatus.STARTING:
          return "STARTING";
        case proto.pb.JobStatus.STARTED:
          return "STARTED";
        case proto.pb.JobStatus.DONE:
          return "DONE";
        case proto.pb.JobStatus.FAILED:
          return "FAILED";
        case proto.pb.JobStatus.TIMEOUT:
          return "TIMEOUT";
        case proto.pb.JobStatus.NOTFOUND:
          return "NOTFOUND";
        case proto.pb.JobStatus.NOREPLY:
          return "NOREPLY";
        default:
          return "UNKNOWN";
      }
    },
    daemonStatus() {
      return proto.pb.DaemonStatus;
    },
    daemonStatusToString(status) {
      switch (status) {
        case proto.pb.DaemonStatus.OFF:
          return "OFF";
        case proto.pb.DaemonStatus.ON:
          return "ON";
        case proto.pb.DaemonStatus.ERR:
          return "ERR";
        default:
          return "UNKNOWN";
      }
    },
    problemTypeToString(type) {
      switch (type) {
        case proto.pb.ProblemType.DISCONNECTS:
          return "Disconnects";
        case proto.pb.ProblemType.ALARMS:
          return "Alarms";
        default:
          return "UNKNOWN";
      }
    },
    getFilterValues(allAgents) {
      var filterValues = {};
      var agents = {};
      var sites = {};
      var tags = {};
      var labels = {};
      var oss = {};
      var archs = {};
      var releases = {};
      var cmodels = {};
      var siteids = {};
      var status = {};
      var versions = {};
      var re = new RegExp('-[svc]{1}[0-9]+[n]?[0-9]*.insolar-plants.net$',"g");
      Object.values(allAgents).forEach((a) => {
        agents[a.agentid] = true;
        status[a.status] = true;
        sites[a.agentid.split(re)[0]] = true;
        a.tagsList.forEach((o) => {
          tags[o] = true;
        });
        a.labelsMap.forEach((o) => {
          labels[o[0]+"="+o[1]] = true;
        });
        versions[a.version] = true;
        if (a.lastinstance && a.lastinstance.info) {
          var i = a.lastinstance.info;
          if (i.os != "") oss[i.os] = true;
          if (i.arch != "") archs[i.arch] = true;
          if (i.release && i.release.name != "") {
            releases[i.release.name+" "+i.release.version] = true;
          }
          if (i.identity) {
            if (i.identity.controllermodel != "") cmodels[i.identity.controllermodel] = true;
            if (i.identity.siteid != "") siteids[i.identity.siteid] = true;
          }
        }
      })
      filterValues["Agent ID"] = Object.keys(agents).sort();
      filterValues["Plant ID"] = Object.keys(sites).sort();
      filterValues["Tags"] = Object.keys(tags).sort();
      filterValues["Labels"] = Object.keys(labels).sort();
      filterValues["OS"] = Object.keys(oss).sort();
      filterValues["Arch"] = Object.keys(archs).sort();
      filterValues["Release"] = Object.keys(releases).sort();
      filterValues["Controller Model"] = Object.keys(cmodels).sort();
      filterValues["Site ID"] = Object.keys(siteids).sort();
      filterValues["Status"] = Object.keys(status).sort();
      filterValues["Version"] = Object.keys(versions).sort();
      return filterValues;
    },
    errToString(err) {
      var message = "errCode: " + err.code;
      if (err.message) {
        message += " " + err.message;
      } else if (err.details) {
        message += " " + err.details;
      }
      if (process.env.NODE_ENV != 'production') {
        message += " (" + err.func + ")";
      }
      return message;
    },
    partitionArray(array, isValid) {
      return array.reduce(([pass, fail], item) => {
        return isValid(item) ? [[...pass, item], fail] : [pass, [...fail, item]];
      }, [[], []]);
    },
    escapeRegEx(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
  }
}

export default libvue;
