<template>
  <div>
    <p>Loading...</p>
    {{ error }}
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      error: ''
    }
  },
  methods: {
    handleLoginEvent(data) {
      if (!data.error) {
        this.watchAgents();
        this.filterAgents();
        // this.watchRequests();
        // this.filterRequests();
        // this.watchTasks();
        // this.filterTasks();
        // this.watchJobs();
        // this.filterJobs();
        // this.watchFileTransfers();
        // this.filterFileTransfers();
        this.watchFiles();
        this.filterFiles();
        this.watchRecipes();
        this.filterRecipes();
        this.filterUsers();
        this.watchConfig();
        this.getConfig();
        this.getUserConfig({sub: this.$auth.profile.sub, owner: this.$auth.profile.email}).then(() => {}).catch(() => {});
        this.$router.push(data.state.target || "/");
      }
    },
    ...mapActions(['filterAgents', 'watchAgents', 'filterTasks', 'watchTasks',
                  'filterRequests', 'watchRequests', 'filterJobs', 'watchJobs',
                  'filterFiles', 'watchFiles', 'filterFileTransfers', 'watchFileTransfers',
                  'filterRecipes', 'watchRecipes', 'filterUsers', 'getUserConfig', 'getConfig', 'watchConfig'])
  },
  created() {
    this.$auth.handleAuthentication().then(() => {})
    .catch(err => { this.error = err.errorDescription });
  }
};
</script>