// source: instance.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.pb.CoolInfo', null, global);
goog.exportSymbol('proto.pb.Ethernet', null, global);
goog.exportSymbol('proto.pb.Identity', null, global);
goog.exportSymbol('proto.pb.Identity_ControllerID', null, global);
goog.exportSymbol('proto.pb.Instance', null, global);
goog.exportSymbol('proto.pb.InstanceRange', null, global);
goog.exportSymbol('proto.pb.Release', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Instance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Instance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Instance.displayName = 'proto.pb.Instance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CoolInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.CoolInfo.repeatedFields_, null);
};
goog.inherits(proto.pb.CoolInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.CoolInfo.displayName = 'proto.pb.CoolInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Ethernet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Ethernet.repeatedFields_, null);
};
goog.inherits(proto.pb.Ethernet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Ethernet.displayName = 'proto.pb.Ethernet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Release = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Release, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Release.displayName = 'proto.pb.Release';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Identity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Identity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Identity.displayName = 'proto.pb.Identity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Identity_ControllerID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Identity_ControllerID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Identity_ControllerID.displayName = 'proto.pb.Identity_ControllerID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.InstanceRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.InstanceRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.InstanceRange.displayName = 'proto.pb.InstanceRange';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Instance.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Instance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Instance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Instance.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clientid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    registerat: jspb.Message.getFieldWithDefault(msg, 4, 0),
    offlineat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    version: jspb.Message.getFieldWithDefault(msg, 6, ""),
    info: (f = msg.getInfo()) && proto.pb.CoolInfo.toObject(includeInstance, f),
    update: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Instance}
 */
proto.pb.Instance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Instance;
  return proto.pb.Instance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Instance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Instance}
 */
proto.pb.Instance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRegisterat(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOfflineat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 7:
      var value = new proto.pb.CoolInfo;
      reader.readMessage(value,proto.pb.CoolInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdate(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Instance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Instance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Instance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Instance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegisterat();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOfflineat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pb.CoolInfo.serializeBinaryToWriter
    );
  }
  f = message.getUpdate();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string AgentID = 1;
 * @return {string}
 */
proto.pb.Instance.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ClientID = 2;
 * @return {string}
 */
proto.pb.Instance.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Status = 3;
 * @return {string}
 */
proto.pb.Instance.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 RegisterAt = 4;
 * @return {number}
 */
proto.pb.Instance.prototype.getRegisterat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setRegisterat = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 OfflineAt = 5;
 * @return {number}
 */
proto.pb.Instance.prototype.getOfflineat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setOfflineat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string Version = 6;
 * @return {string}
 */
proto.pb.Instance.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional CoolInfo Info = 7;
 * @return {?proto.pb.CoolInfo}
 */
proto.pb.Instance.prototype.getInfo = function() {
  return /** @type{?proto.pb.CoolInfo} */ (
    jspb.Message.getWrapperField(this, proto.pb.CoolInfo, 7));
};


/**
 * @param {?proto.pb.CoolInfo|undefined} value
 * @return {!proto.pb.Instance} returns this
*/
proto.pb.Instance.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Instance.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool Update = 10;
 * @return {boolean}
 */
proto.pb.Instance.prototype.getUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool Deleted = 12;
 * @return {boolean}
 */
proto.pb.Instance.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.Instance} returns this
 */
proto.pb.Instance.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.CoolInfo.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.CoolInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.CoolInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.CoolInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CoolInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    os: jspb.Message.getFieldWithDefault(msg, 1, ""),
    arch: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cpu: jspb.Message.getFieldWithDefault(msg, 3, ""),
    memory: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ethernetList: jspb.Message.toObjectList(msg.getEthernetList(),
    proto.pb.Ethernet.toObject, includeInstance),
    release: (f = msg.getRelease()) && proto.pb.Release.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    identity: (f = msg.getIdentity()) && proto.pb.Identity.toObject(includeInstance, f),
    hostname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    labelsMap: (f = msg.getLabelsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CoolInfo}
 */
proto.pb.CoolInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.CoolInfo;
  return proto.pb.CoolInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CoolInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CoolInfo}
 */
proto.pb.CoolInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArch(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpu(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemory(value);
      break;
    case 5:
      var value = new proto.pb.Ethernet;
      reader.readMessage(value,proto.pb.Ethernet.deserializeBinaryFromReader);
      msg.addEthernet(value);
      break;
    case 6:
      var value = new proto.pb.Release;
      reader.readMessage(value,proto.pb.Release.deserializeBinaryFromReader);
      msg.setRelease(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 8:
      var value = new proto.pb.Identity;
      reader.readMessage(value,proto.pb.Identity.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 10:
      var value = msg.getLabelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CoolInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.CoolInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CoolInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CoolInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCpu();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMemory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEthernetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pb.Ethernet.serializeBinaryToWriter
    );
  }
  f = message.getRelease();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.pb.Release.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.pb.Identity.serializeBinaryToWriter
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLabelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string OS = 1;
 * @return {string}
 */
proto.pb.CoolInfo.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Arch = 2;
 * @return {string}
 */
proto.pb.CoolInfo.prototype.getArch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.setArch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CPU = 3;
 * @return {string}
 */
proto.pb.CoolInfo.prototype.getCpu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.setCpu = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Memory = 4;
 * @return {string}
 */
proto.pb.CoolInfo.prototype.getMemory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.setMemory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Ethernet Ethernet = 5;
 * @return {!Array<!proto.pb.Ethernet>}
 */
proto.pb.CoolInfo.prototype.getEthernetList = function() {
  return /** @type{!Array<!proto.pb.Ethernet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.Ethernet, 5));
};


/**
 * @param {!Array<!proto.pb.Ethernet>} value
 * @return {!proto.pb.CoolInfo} returns this
*/
proto.pb.CoolInfo.prototype.setEthernetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pb.Ethernet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Ethernet}
 */
proto.pb.CoolInfo.prototype.addEthernet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pb.Ethernet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.clearEthernetList = function() {
  return this.setEthernetList([]);
};


/**
 * optional Release Release = 6;
 * @return {?proto.pb.Release}
 */
proto.pb.CoolInfo.prototype.getRelease = function() {
  return /** @type{?proto.pb.Release} */ (
    jspb.Message.getWrapperField(this, proto.pb.Release, 6));
};


/**
 * @param {?proto.pb.Release|undefined} value
 * @return {!proto.pb.CoolInfo} returns this
*/
proto.pb.CoolInfo.prototype.setRelease = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.clearRelease = function() {
  return this.setRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CoolInfo.prototype.hasRelease = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string Tags = 7;
 * @return {!Array<string>}
 */
proto.pb.CoolInfo.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional Identity Identity = 8;
 * @return {?proto.pb.Identity}
 */
proto.pb.CoolInfo.prototype.getIdentity = function() {
  return /** @type{?proto.pb.Identity} */ (
    jspb.Message.getWrapperField(this, proto.pb.Identity, 8));
};


/**
 * @param {?proto.pb.Identity|undefined} value
 * @return {!proto.pb.CoolInfo} returns this
*/
proto.pb.CoolInfo.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CoolInfo.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string Hostname = 9;
 * @return {string}
 */
proto.pb.CoolInfo.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * map<string, string> Labels = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.pb.CoolInfo.prototype.getLabelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.pb.CoolInfo} returns this
 */
proto.pb.CoolInfo.prototype.clearLabelsMap = function() {
  this.getLabelsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Ethernet.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Ethernet.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Ethernet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Ethernet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Ethernet.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hwaddr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ipaddrList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Ethernet}
 */
proto.pb.Ethernet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Ethernet;
  return proto.pb.Ethernet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Ethernet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Ethernet}
 */
proto.pb.Ethernet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwaddr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addIpaddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Ethernet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Ethernet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Ethernet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Ethernet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHwaddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIpaddrList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.pb.Ethernet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Ethernet} returns this
 */
proto.pb.Ethernet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string HWAddr = 2;
 * @return {string}
 */
proto.pb.Ethernet.prototype.getHwaddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Ethernet} returns this
 */
proto.pb.Ethernet.prototype.setHwaddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string IPAddr = 3;
 * @return {!Array<string>}
 */
proto.pb.Ethernet.prototype.getIpaddrList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pb.Ethernet} returns this
 */
proto.pb.Ethernet.prototype.setIpaddrList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.Ethernet} returns this
 */
proto.pb.Ethernet.prototype.addIpaddr = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.Ethernet} returns this
 */
proto.pb.Ethernet.prototype.clearIpaddrList = function() {
  return this.setIpaddrList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Release.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Release.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Release} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Release.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    versionid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Release}
 */
proto.pb.Release.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Release;
  return proto.pb.Release.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Release} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Release}
 */
proto.pb.Release.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Release.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Release.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Release} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Release.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersionid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.pb.Release.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Release} returns this
 */
proto.pb.Release.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Version = 2;
 * @return {string}
 */
proto.pb.Release.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Release} returns this
 */
proto.pb.Release.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ID = 3;
 * @return {string}
 */
proto.pb.Release.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Release} returns this
 */
proto.pb.Release.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string VersionID = 4;
 * @return {string}
 */
proto.pb.Release.prototype.getVersionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Release} returns this
 */
proto.pb.Release.prototype.setVersionid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Identity.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Identity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Identity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Identity.toObject = function(includeInstance, msg) {
  var f, obj = {
    controllerid: (f = msg.getControllerid()) && proto.pb.Identity_ControllerID.toObject(includeInstance, f),
    controlleridx: jspb.Message.getFieldWithDefault(msg, 2, 0),
    controllermodel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cprocagentname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hostid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    siteid: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Identity}
 */
proto.pb.Identity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Identity;
  return proto.pb.Identity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Identity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Identity}
 */
proto.pb.Identity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pb.Identity_ControllerID;
      reader.readMessage(value,proto.pb.Identity_ControllerID.deserializeBinaryFromReader);
      msg.setControllerid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlleridx(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllermodel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCprocagentname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Identity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Identity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Identity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Identity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControllerid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pb.Identity_ControllerID.serializeBinaryToWriter
    );
  }
  f = message.getControlleridx();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getControllermodel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCprocagentname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHostid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSiteid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional Identity_ControllerID ControllerID = 1;
 * @return {?proto.pb.Identity_ControllerID}
 */
proto.pb.Identity.prototype.getControllerid = function() {
  return /** @type{?proto.pb.Identity_ControllerID} */ (
    jspb.Message.getWrapperField(this, proto.pb.Identity_ControllerID, 1));
};


/**
 * @param {?proto.pb.Identity_ControllerID|undefined} value
 * @return {!proto.pb.Identity} returns this
*/
proto.pb.Identity.prototype.setControllerid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Identity} returns this
 */
proto.pb.Identity.prototype.clearControllerid = function() {
  return this.setControllerid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Identity.prototype.hasControllerid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 ControllerIDX = 2;
 * @return {number}
 */
proto.pb.Identity.prototype.getControlleridx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Identity} returns this
 */
proto.pb.Identity.prototype.setControlleridx = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string ControllerModel = 3;
 * @return {string}
 */
proto.pb.Identity.prototype.getControllermodel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Identity} returns this
 */
proto.pb.Identity.prototype.setControllermodel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string CPROCAgentName = 4;
 * @return {string}
 */
proto.pb.Identity.prototype.getCprocagentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Identity} returns this
 */
proto.pb.Identity.prototype.setCprocagentname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string HostID = 5;
 * @return {string}
 */
proto.pb.Identity.prototype.getHostid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Identity} returns this
 */
proto.pb.Identity.prototype.setHostid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string SiteID = 6;
 * @return {string}
 */
proto.pb.Identity.prototype.getSiteid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Identity} returns this
 */
proto.pb.Identity.prototype.setSiteid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Identity_ControllerID.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Identity_ControllerID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Identity_ControllerID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Identity_ControllerID.toObject = function(includeInstance, msg) {
  var f, obj = {
    be: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Identity_ControllerID}
 */
proto.pb.Identity_ControllerID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Identity_ControllerID;
  return proto.pb.Identity_ControllerID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Identity_ControllerID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Identity_ControllerID}
 */
proto.pb.Identity_ControllerID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Identity_ControllerID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Identity_ControllerID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Identity_ControllerID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Identity_ControllerID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBe();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string BE = 1;
 * @return {string}
 */
proto.pb.Identity_ControllerID.prototype.getBe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Identity_ControllerID} returns this
 */
proto.pb.Identity_ControllerID.prototype.setBe = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.InstanceRange.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.InstanceRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.InstanceRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.InstanceRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    instance: (f = msg.getInstance()) && proto.pb.Instance.toObject(includeInstance, f),
    start: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.InstanceRange}
 */
proto.pb.InstanceRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.InstanceRange;
  return proto.pb.InstanceRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.InstanceRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.InstanceRange}
 */
proto.pb.InstanceRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pb.Instance;
      reader.readMessage(value,proto.pb.Instance.deserializeBinaryFromReader);
      msg.setInstance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.InstanceRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.InstanceRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.InstanceRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.InstanceRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstance();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pb.Instance.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional Instance Instance = 1;
 * @return {?proto.pb.Instance}
 */
proto.pb.InstanceRange.prototype.getInstance = function() {
  return /** @type{?proto.pb.Instance} */ (
    jspb.Message.getWrapperField(this, proto.pb.Instance, 1));
};


/**
 * @param {?proto.pb.Instance|undefined} value
 * @return {!proto.pb.InstanceRange} returns this
*/
proto.pb.InstanceRange.prototype.setInstance = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.InstanceRange} returns this
 */
proto.pb.InstanceRange.prototype.clearInstance = function() {
  return this.setInstance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.InstanceRange.prototype.hasInstance = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 Start = 2;
 * @return {number}
 */
proto.pb.InstanceRange.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.InstanceRange} returns this
 */
proto.pb.InstanceRange.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 Limit = 3;
 * @return {number}
 */
proto.pb.InstanceRange.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.InstanceRange} returns this
 */
proto.pb.InstanceRange.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.pb);
