<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Agents</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip class="ma-2" color="red lighten-1" outlined label v-on="on">
                    <v-avatar left>
                      <v-icon small>waves</v-icon>
                    </v-avatar>
                    {{ agentsnum }}
                  </v-chip>
                </template>
                <span>All Agents</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip class="ma-2" color="green lighten-1" outlined label v-on="on">
                    <v-avatar left>
                      <v-icon small>waves</v-icon>
                    </v-avatar>
                    {{ agentsonline }}
                  </v-chip>
                  </template>
                <span>Online Agents</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip class="ma-2" color="blue lighten-1" outlined v-on="on">
                    <v-avatar left>
                      <v-icon small>waves</v-icon>
                    </v-avatar>
                    {{ agentsfiltered }}
                  </v-chip>
                  </template>
                <span>Filtered in View</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <NewTask :filters.sync="filter" ref="NewTask" @submitted="onChildSubmit" />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-switch
                    v-model="pinned"
                    prepend-icon="push_pin"
                    dense
                    v-on="on"
                  ></v-switch>
                </template>
                <span v-if="pinned">Show all</span>
                <span v-else>Show pinned</span>
              </v-tooltip>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small text color="primary" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-card>
                      <v-card-actions>
                        <v-list dense>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportAgents()">
                              <v-icon dark left>get_app</v-icon>
                              Export
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
              <v-layout>Filter</v-layout>
              <FilteringEditor ref="FilteringEditor"
                               :filters.sync="filter"
                               :filter-values="filterValues"
              />
            </v-card-text>
            <v-card-actions>
              <!-- <v-btn text color="primary" @click.prevent="updateAgents()">Apply</v-btn> -->
              <!-- <v-btn text color="primary" @click.prevent="clearFilter()">Clear</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :footer-props="{
                'items-per-page-options': itemsPerPage
              }"
              :items="myAgents"
              :loading="isLoading.agents"
              :search="search"
              :sort-by="'agentid'"
              item-key="agentid"
              class="elevation-1"
              dense
              @update:options="updatePagination"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.agentid">
                    <td ><v-img max-height="12px" max-width="12px" contain :src="require(`../assets/walrus.jpg`)"></v-img></td>
                    <td >
                      <v-btn small text :color="pinnedColor(item.agentid)" @click.prevent="pinOrUnpin(item.agentid)">
                        <v-icon small dark left>push_pin</v-icon>
                      </v-btn>
                    </td>
                    <td @click="openAgentTab(item.agentid)" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                    <td class="text-xs-left" @click="openAgentTab(item.agentid)" :style="{cursor:'pointer'}">
                      <v-icon small v-if='item.status == "Registered"' color="success">check_circle_outline</v-icon>
                      <v-icon small v-else-if='item.status == "Offline"' color="error">error_outline</v-icon>
                      <v-icon small v-else-if='item.status == "RegisteredRedundant"' color="success">check_circle_outline</v-icon>
                      <v-icon small v-else-if='item.status == "RegisteredConflict"' color="warning">warning</v-icon>
                      <v-icon small v-else-if='item.status == "RegisteredDegraded"' color="warning">warning</v-icon>
                      {{ item.status }}
                    </td>
                    <td @click="openAgentTab(item.agentid)" :style="{cursor:'pointer'}">{{ prettyTags(item.tagsList) }}</td>
                    <td @click="openAgentTab(item.agentid)" :style="{cursor:'pointer'}">{{ prettyLabels(item.labelsMap) }}</td>
                    <td @click="openAgentTab(item.agentid)" :style="{cursor:'pointer'}" v-if='item.status == "Offline"'></td>
                    <td @click="openAgentTab(item.agentid)" :style="{cursor:'pointer'}" v-else class="error--text">{{ prettyAlarms(agentAlarms(item.agentid)) }}</td>
                    <td @click="openAgentTab(item.agentid)" :style="{cursor:'pointer'}">{{ dateToString(item.lastseen) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>

      </v-layout>
    </v-container>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import libvue from '../lib/vue.js'
import config from '../../config/config.json';
import NewTask from '../components/NewTask.vue'
import FilteringEditor from "./common/FilteringEditor";

export default {
  mixins: [libvue],
  data() {
    return {
      search: '',
      headers: [
        { text: '', sortable: false, value: '' },
        { text: 'Pin', sortable: true, value: 'pin' },
        { text: 'Name', sortable: true, value: 'agentid' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Tags', sortable: true, value: 'tagsList'},
        { text: 'Labels', sortable: true, value: 'labelsMap'},
        { text: 'Alarms', sortable: true, value: 'alarmsMap' },
        { text: 'Last Seen', sortable: true, value: 'lastseen' }
      ],
      filter: [{field: '', value: [], operator: 'is', multioperator: 'AND'}],
      filterValues: {},
      snack: false,
      snackColor: 'primary',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  components: { NewTask, FilteringEditor },
  watch: {
    myAgents: function() {
      this.filterValues = this.getFilterValues(this.allAgents);
    }
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    pinned: {
      get() {
        if (!this.userConfig) return false;
        return this.userConfig.pinned;
      },
      set(v) {
        this.setPinned(v);
      }
    },
    pinnedAgents: {
      get() {
        if (!this.pinned || !this.userConfig || !this.userConfig.pinnedagentsidsList) {
          return this.allAgents;
        }
        var pinnedAgents = {};
        for (const [key, value] of Object.entries(this.allAgents)) {
          this.userConfig.pinnedagentsidsList.forEach((v) => {
            if (key == v) pinnedAgents[key] = value;
          })
        }
        return pinnedAgents;
      }
    },
    agentsnum: {
      get() {
        return Object.keys(this.allAgents).length;
      }
    },
    agentsonline: {
      get() {
        return Object.values(this.allAgents).filter((a) => a.status != "Offline").length;
      }
    },
    agentsfiltered: {
      get() {
        return Object.keys(this.myAgents).length;
      }
    },
    myAgents: {
      get() {
        if (!this.validateFilter(this.filter)) {
          if (this.filter.length == 1) {
            return Object.values(this.pinnedAgents);
          }
        }
        var parent = this;
        var my = [];
        Object.values(this.pinnedAgents).forEach((a) => {
          var y = true;
          Object.values(parent.filter).forEach((f) => {
            var z = false;
            if (f.field != "" && f.value.length > 0) {
              switch (f.field) {
                case "Agent ID":
                  // z = a.agentid == f.value;
                  z = f.value.some((e) => { return e == a.agentid} );
                  break;
                case "Plant ID":
                  z = f.value.some((e) => {
                    var re = new RegExp('^'+e+'-[a-z]{1}[0-9]+[n]?[0-9]*.insolar-plants.net$',"g");
                    return a.agentid.match(re);
                  });
                  // z = f.value.some((e) => { return a.agentid.includes(e)} );
                  break;
                case "Tags":
                  z = f.value.some((e) => {
                    return a.tagsList.some((t) => { return t.toLowerCase() == e.toLowerCase() })
                  });
                  break;
                case "Labels":
                    // z = a.labels == f.value;
                    z = f.value.some((e) => { return e == this.prettyLabels(a.labelsMap) });
                  break;
                case "OS":
                  if (a.lastinstance.info) {
                    // z = a.lastinstance.info.os == f.value;
                    z = f.value.some((e) => { return e == a.lastinstance.info.os });
                  }
                  break;
                case "Arch":
                  if (a.lastinstance.info) {
                    // z = a.lastinstance.info.arch == f.value;
                    z = f.value.some((e) => { return e == a.lastinstance.info.arch });
                  }
                  break;
                case "Release":
                  if (a.lastinstance.info && a.lastinstance.info.release) {
                    // z = a.lastinstance.info.release.name + " " + a.lastinstance.info.release.version == f.value;
                    z = f.value.some((e) => { return e == a.lastinstance.info.release.name + " " + a.lastinstance.info.release.version });
                  }
                  break;
                case "Controller Model":
                  if (a.lastinstance.info && a.lastinstance.info.identity) {
                    // z = a.lastinstance.info.identity.controllermodel == f.value;
                    z = f.value.some((e) => { return e == a.lastinstance.info.identity.controllermodel });
                  }
                  break;
                case "Site ID":
                  if (a.lastinstance.info && a.lastinstance.info.identity) {
                    // z = a.lastinstance.info.identity.siteid == f.value;
                    z = f.value.some((e) => { return e == a.lastinstance.info.identity.siteid });
                  }
                  break;
                case "Status":
                    // z = a.version == f.value;
                    z = f.value.some((e) => { return e == a.status });
                  break;
                case "Version":
                    // z = a.version == f.value;
                    z = f.value.some((e) => { return e == a.version });
                  break;
              }
              if (f.operator == "is not") {
                z = !z;
              }
              if (f.multioperator == "AND") {
                y = y && z;
              }
              if (f.multioperator == "OR") {
                y = y || z;
              }
              if (y && f.multioperator == "AND NOT") {
                y = y && !z;
              }
            }
          });
          if (y) {
            my.push(a);
          }
        });
        return my;
      }
    },
    ...mapGetters(['allAgents', 'allAlarms', 'allWatchers', 'userConfig', 'isLoading'])
  },
  mounted() {
    this.filterValues = this.getFilterValues(this.allAgents);
    var pf = this.$route.params.filter;
    if (this.validateFilter(pf)) {
      this.filter = [...pf];
    }
    this.filterAlarms({
      start: (new Date().getTime()-config.historyMs) * 1e6,
      limit: new Date().getTime() * 1e6
    });
  },
  beforeDestroy() {
  },
  methods: {
    onChildSubmit() {
      this.$router.push({ name: 'tasks-page' });
    },
    openAgentTab(agentid) {
      this.$router.push({ name: 'agent-details', params: { AgentID: agentid, filter: this.filter } });
    },
    validateFilter(filterList) {
      if (!filterList) return false;
      for (const filter of filterList) {
        if (!filter.field || filter.field == "") {
          return false; // Empty field in filter
        }
        if (!filter.value || filter.value.length == 0) {
          return false; // Empty value in filter
        }
      }
      return true;
    },
    setPinned(v) {
      if (!this.userConfig || !this.userConfig.sub) return;
      var config = Object.assign({}, this.userConfig);
      config.pinned = v;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    pinnedColor(agentid) {
      if (this.userConfig && this.userConfig.pinnedagentsidsList) {
        for (const v of this.userConfig.pinnedagentsidsList) {
          if (v == agentid) return 'primary';
        }
      }
      return 'grey';
    },
    pinOrUnpin(agentid) {
      if (!this.userConfig || !this.userConfig.sub) return;
      var config = Object.assign({}, this.userConfig);
      var pinned = false;
      for (const v of config.pinnedagentsidsList) {
        if (v == agentid) pinned = true;
      }
      if (!pinned) {
        config.pinnedagentsidsList.push(agentid);
      } else {
        const index = config.pinnedagentsidsList.indexOf(agentid);
        if (index > -1) {
          config.pinnedagentsidsList.splice(index, 1);
        }
      }
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    agentAlarms(agentid) {
      return Object.values(this.allAlarms).filter((a) => a.agentid == agentid)
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportAgents() {
      var data = Object.assign({}, this.myAgents);
      Object.keys(data).forEach((key) => {
        this.agentid = data[key].agentid
        var v = Object.values(this.allWatchers).filter((a) => a.agentid == this.agentid);
        if (v && v[0]) {
          data[key]["watchersMap"] = v[0].watchersMap;
        }
      });
      var dataStr = JSON.stringify(data, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'agents.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['filterAlarms', 'setUserConfig'])
  }
}
</script>
