<template>
<div>
    <v-app-bar color="primary" dark dense fixed app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Cool Management</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.prevent="invertColors()">
        <v-icon>invert_colors</v-icon>
      </v-btn>
      <v-menu bottom left v-if="isAuthenticated">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="36px">
              <v-img v-if="profile" :src="profile.picture"></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-layout row wrap>
          <v-flex md12>
            <v-card v-if="profile" width="344">
              <v-list>
                <v-list-item>
                  <v-list-item-avatar size="64px">
                    <v-img :src="profile.picture" contain></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="headline">{{ profile.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ profile.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn text @click.prevent="logout"
                  ><v-icon>power_settings_new</v-icon>Logout
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-menu>
      <v-btn text @click.prevent="login()" v-else>Login</v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" clipped fixed app v-if="isAuthenticated || !production">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Cool Management
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ version }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" link @click="goTo(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div dense nav v-if="hasAaaAccess()">
          <v-list-item v-for="item in adminItems" :key="item.title" link @click="goTo(item)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav v-if="hasAaaAccess()">
        <v-list-item v-for="item in aaaItems" :key="item.title" link @click="goTo(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '../../config/config.json';
import libvue from '../lib/vue.js'

export default {
  mixins: [libvue],
  data () {
    return {
      production: process.env.NODE_ENV == 'production' ? true : false,
      isAuthenticated: false,
      profile: {},
      drawer: null,
      items: [
        { title: 'Home', icon: 'dashboard', value: 'home-page' },
        { title: 'Agents', icon: 'waves', value: 'agents-page' },
        { title: 'Tasks', icon: 'assignment', value: 'tasks-page' },
        { title: 'Library', icon: 'library_books', value: 'library-page', params: { Tab: 0 } },
        { title: 'Alarms', icon: 'alarm', value: 'alarms-page' }

      ],
      adminItems: [
        { title: 'Watchers', icon: 'remove_red_eye', value: 'watchers-page' },
        { title: 'Settings', icon: 'settings', value: 'settings-page' }
      ],
      aaaItems: [
        { title: 'Users', icon: 'people', value: 'users-page' },
        { title: 'Policies', icon: 'policy', value: 'policies-page' },
        { title: 'Audit', icon: 'security', value: 'audit-page' }
      ],
      right: null,
      version: config.version
    }
  },
  computed: {
    ...mapGetters(['getUserById'])
  },
  mounted() {
    if (this.$cookie.get("dark") == "true") {
      this.$vuetify.theme.dark = true;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
  methods: {
    invertColors() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$cookie.set("dark", this.$vuetify.theme.dark, { expires: '1Y' });
    },
    goTo(item) {
      this.$router.push({ name: item.value, params: item.params });
    },
    login() {
      this.$auth.login();
    },
    logout() {
      this.$auth.logOut();
    },
    handleLoginEvent(data) {
      this.isAuthenticated = data.loggedIn;
      this.profile = data.profile;
    },
    hasAaaAccess() {
      if (this.profile) {
        var u = this.getUserById(this.profile.sub);
        if (u) return u.attributes.aaaaccess;
      }
      return false;
    },
  }
}
</script>
