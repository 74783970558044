<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Library</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-tabs slot="extension" v-model="tabs">
            <v-tab :key="0">Recipes</v-tab>
            <v-tab :key="1">Files</v-tab>
          </v-tabs>

          <v-tabs-items style="width:100%" v-model="tabs">
            <v-tab-item :key="0">
              <v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small text color="primary" @click.prevent="callNewRecipe()" v-if="hasNewTaskFullAccess()">
                    <v-icon dark left>create</v-icon>
                    New Recipe
                  </v-btn>
                  <v-btn small text color="primary" @click.prevent="filterRecipes()">
                    <v-icon dark left>refresh</v-icon>
                    Refresh
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn small text color="primary" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-layout row wrap>
                      <v-flex md12>
                        <v-card>
                          <v-card-actions>
                            <v-list dense>
                              <v-list-item>
                                <v-btn small text color="primary" @click.prevent="exportRecipes()">
                                  <v-icon dark left>get_app</v-icon>
                                  Export
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-actions>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-menu>
                </v-card-actions>
              </v-card>

              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  :headers="headersRecipes"
                  :footer-props="{
                    'items-per-page-options': itemsPerPage
                  }"
                  :items="Object.values(allRecipes)"
                  :loading="isLoading.recipes"
                  :search="search"
                  :sort-by="'name'"
                  :sort-desc="false"
                  item-key="recipeid"
                  class="elevation-1"
                  dense
                  @update:options="updatePagination"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.recipeid" @click="openRecipeTab(item.recipeid)" :style="{cursor:'pointer'}">
                        <td><v-icon small>library_books</v-icon></td>
                        <td >{{ smallId(item.recipeid) }}</td>
                        <td >{{ item.name }}</td>
                        <td >{{ item.version }}</td>
                        <td >{{ item.owner }}</td>
                        <td >{{ dateToString(item.lastmodified) }}</td>
                        <td >{{ item.modifiedby }}</td>
                        <td >
                          <v-icon small v-if="item.approved">check</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="1">
              <v-card>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <template>
                      <v-file-input
                        show-size
                        dense
                        :rules="filerules"
                        :loading="dialog"
                        :disabled="dialog || isLoading.files"
                        label="Choose file"
                        v-model="uploadfile"
                        v-if="hasNewTaskFullAccess()"
                      ></v-file-input>
                      <v-btn small text color="primary" @click.prevent="handleFileUpload()" :disabled="isLoading.files" v-if="hasNewTaskFullAccess()">
                        <v-icon dark left>cloud_upload</v-icon>
                      </v-btn>
                    </template>
                    <v-btn small text color="primary" @click.prevent="filterFiles()" :disabled="isLoading.files">
                      <v-icon dark left>refresh</v-icon>
                      Refresh
                    </v-btn>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn small text color="primary" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-layout row wrap>
                        <v-flex md12>
                          <v-card>
                            <v-card-actions>
                              <v-list dense>
                                <v-list-item>
                                  <v-btn small text color="primary" @click.prevent="exportFiles()">
                                    <v-icon dark left>get_app</v-icon>
                                    Export
                                  </v-btn>
                                </v-list-item>
                              </v-list>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-menu>
                  </v-card-actions>
                </v-card>

                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Search"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-card-title>

                  <v-data-table
                    :headers="headersFiles"
                    :footer-props="{
                      'items-per-page-options': itemsPerPage
                    }"
                    :items="Object.values(allFiles)"
                    :loading="isLoading.files"
                    :search="search"
                    :sort-by="'filename'"
                    :sort-desc="false"
                    item-key="fileobjectid"
                    class="elevation-1"
                    dense
                    @update:options="updatePagination"
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item.fileobjectid" @click="openFileTab(item)" :style="{cursor:'pointer'}">
                          <td><v-icon small>folder_open</v-icon></td>
                          <td >{{ smallId(item.fileobjectid) }}</td>
                          <td >{{ item.filename }}</td>
                          <td >{{ item.version }}</td>
                          <td >{{ item.owner }}</td>
                          <td >{{ dateToString(item.created) }}</td>
                          <td >{{ item.size }}</td>
                          <td >{{ item.etag }}</td>
                          <td >
                            <v-icon small v-if="item.approved">check</v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import APIClient from '../api/apiClient';
import libvue from '../lib/vue.js'

export default {
  mixins: [libvue],
  data() {
    return {
      dialog: false,
      recipe: null,
      file: null,
      uploadfile: null,
      filerules: [
        value => !value || value.size < 200000000 || 'File size should be less than 200 MB!',
      ],
      search: '',
      headersRecipes: [
        { text: '', sortable: false, value: '' },
        { text: 'ID', sortable: true, value: 'recipeid' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Version', sortable: true, value: 'version' },
        { text: 'Owner', sortable: true, value: 'owner' },
        { text: 'Last Modified', sortable: true, value: 'lastmodified' },
        { text: 'Modified By', sortable: true, value: 'modifiedby' },
        { text: 'Approved', sortable: true, value: 'approved' }
      ],
      headersFiles: [
        { text: '', sortable: false, value: '' },
        { text: 'ID', sortable: true, value: 'fileobjectid' },
        { text: 'Filename', sortable: true, value: 'filename' },
        { text: 'Version', sortable: true, value: 'version' },
        { text: 'Owner', sortable: true, value: 'owner' },
        { text: 'Created', sortable: true, value: 'created' },
        { text: 'Size', sortable: true, value: 'size' },
        { text: 'ETag', sortable: true, value: 'etag' },
        { text: 'Approved', sortable: true, value: 'approved' },
      ],
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  computed: {
    tabs: {
      get() {
        if (this.$route.params.Tab) {
          return Number(this.$route.params.Tab);
        }
        return 0;
      },
      set(v) {
        if (this.$route.params.Tab != v) {
          this.$router.push({ name: 'library-page', params: { Tab: v } });
        }
      }
    },
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    ...mapGetters(['allRecipes', 'allFiles', 'userConfig', 'getUserById', 'isLoading'])
  },
  mounted() {
  },
  methods: {
    callNewRecipe() {
      this.dialog = true;
      this.newRecipe({owner: this.$auth.profile.email}).then((v) => {
        this.dialog = false;
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = "New Recipe with ID: " + v.recipeid;
        this.openRecipeTab(v.recipeid);
      }).catch(() => { this.dialog = false; });
    },
    handleFileUpload() {
      if (!this.uploadfile) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackMessage = "Empty file";
        return;
      }
      if (this.uploadfile.size > 200000000) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackMessage = "File size exceeds 200mb limit";
        return;
      }
      this.dialog = true;
      // this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('file', this.uploadfile);
      APIClient.upload(formData).then((response) => {
        this.dialog = false;
        this.snack = true;
        this.snackColor = 'success';
        this.snackMessage = "Uploaded file with ID: " + response.data.FileObjectID;
      }).catch(error => {
        this.dialog = false;
        this.snack = true;
        this.snackColor = 'error'
      if (error.response && error.response.data) {
        this.snackMessage = error.response.data;
      } else {
        this.snackMessage = error;
      }
      });
    },
    openRecipeTab(recipeid) {
      this.$router.push({ name: 'recipe-details', params: { RecipeID: recipeid } });
    },
    openFileTab(file) {
      this.$router.push({ name: 'file-details', params: { FileObjectID: file.fileobjectid } });
    },
    hasNewTaskFullAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.newtaskfullaccess;
      }
      return false;
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportRecipes() {
      var dataStr = JSON.stringify(this.allRecipes, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'recipes.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    exportFiles() {
      var dataStr = JSON.stringify(this.allFiles, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'files.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['newRecipe', 'filterRecipes', 'filterFiles', 'setUserConfig'])
  }
}
</script>
