//import Vue from 'vue';
import axios from 'axios';
import auth from "../auth/authService";
import config from '../../config/config.json';

var BASE_URI = process.env.NODE_ENV == 'production' ? window.location.protocol + '//' + window.location.hostname : config.url;

const client = axios.create({
  baseURL: BASE_URI,
  json: true
});

const APIClient =  {
  hello() {
    return this.perform('get', '/_file1.1/hello', null);
  },
  upload(formData) {
    return this.performUpload('post', '/_file1.1/upload', formData);
  },
  download(fileobjectid) {
    return this.performDownload('get', '/_file1.1/download/'+fileobjectid, null);
  },

  async perform (method, resource, data) {
    var accessToken = await auth.getAccessToken().catch(error => {
      throw error;
    });

    return client({
      method,
      url: resource,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
  },

  async performUpload (method, resource, data) {
    var accessToken = await auth.getAccessToken().catch(error => {
      throw error;
    });

    return client({
      method,
      url: resource,
      data,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
  },

  async performDownload (method, resource, data) {
    var accessToken = await auth.getAccessToken().catch(error => {
      throw error;
    });

    return client({
      method,
      url: resource,
      responseType: 'blob',
      data,
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    });
  }
}

export default APIClient;
