<template>
  <div class="text-xs">
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn small text v-on="on" color="primary" v-if="requestid">
          <div>
            <v-icon dark left>refresh</v-icon>Run Again
          </div>
        </v-btn>
        <v-btn small text v-on="on" color="primary" v-else-if="recipeid">
          <div>
            <v-icon dark left>create</v-icon>Create Task
          </div>
        </v-btn>
        <v-btn small text v-on="on" color="primary" v-else>
          <div>
            <v-icon dark left>create</v-icon>New Task
          </div>
        </v-btn>
      </template>

      <v-toolbar color="primary" dark dense>
        <v-btn icon dark @click.prevent="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>New Task</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click.prevent="viewApplicableAgents(false)">View Applicable Agents</v-btn>
          <v-btn dark text @click.prevent="viewApplicableAgents(true)">Submit</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container grid-list-md>

            <!-------------------------------->
            <!----- Options line: Filter ----->
            <!-------------------------------->

            <v-layout wrap md12>
              <v-flex md11 class="align-self-center">Filter</v-flex>
              <v-flex md1>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn text :color="resolveButtonColor(includePinned)" v-on="on" @click.prevent="setOrRemovePinned()"><v-icon>push_pin</v-icon></v-btn>
                  </template>
                  <span v-if="!includePinned">Include Pinned</span>
                  <span v-else>Remove Pinned</span>
                </v-tooltip>
              </v-flex>
            </v-layout>

            <!-- Filter: agent -->

            <div v-if="agentid">
              <v-layout wrap md12 v-for="(filter, index) in localFilters" :key="index">
                <v-flex md3>
                  <v-select
                    v-model="filter.field"
                    :items="['Agent ID']"
                    @change="filter.value = []"
                    label="Field"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex md2>
                  <v-select
                    v-model="filter.operator"
                    :items="['is']"
                    label="Operator"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex md4>
                  <v-autocomplete
                    v-model="filter.value"
                    :items="[agentid]"
                    label="Value"
                    multiple
                    clearable
                    dense
                  ></v-autocomplete>
                </v-flex>
                <v-flex md2 v-if="index > 0">
                  <v-select
                    v-model="filter.multioperator"
                    label="MultiOperator"
                    :items="['AND']"
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
            </div>

            <!-- Filter: all -->

            <div v-else>
              <FilteringEditor ref="FilteringEditor"
                               :filters.sync="localFilters"
                               :filter-values="filterValues"
              />
            </div>

            <!----- Options line: Type ----->

            <v-layout wrap>
              <v-flex md12>
                <v-radio-group v-model="jobclass" :mandatory="true" row>
                  <v-radio label="Operation" value="operation"></v-radio>
                  <v-radio label="File Transfer" value="filetransfer" v-if="hasNewTaskFullAccess()"></v-radio>
                  <v-radio label="Command" value="command" v-if="hasNewTaskFullAccess()"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>

            <!----------------------------------->
            <!----- Options line: Operation ----->
            <!----------------------------------->

            <v-layout wrap v-if="jobclass === 'operation'">
              <v-flex md8>
                <v-autocomplete
                  v-model="selectedrecipeid"
                  :items="getRecipes()"
                  :loading="isLoading.recipes"
                  item-text="name"
                  item-value="recipeid"
                  clearable
                  label="Search recipes"
                ></v-autocomplete>
              </v-flex>

              <v-flex md2>
                <v-text-field
                  v-model="jobtimeout"
                  label="Job Timeout (sec)"
                  :rules="jobtimeoutRules"
                ></v-text-field>
              </v-flex>

              <v-flex md2>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="jobmaxdelay"
                        label="Job Max Delay (sec)"
                        :rules="jobtimeoutRules"
                        v-on="on"
                      ></v-text-field>
                    </template>
                  <span>Add a random delay on client job</span>
                </v-tooltip>
              </v-flex>

              <v-flex md12 v-if="argsRequired()">
                <v-text-field
                  v-model="oparguments"
                  clearable
                  label="Arguments"
                ></v-text-field>
              </v-flex>

              <v-flex md12 v-if="isCrossSite()">
                <v-autocomplete
                  label="Site"
                  v-model="crosssite"
                  :loading="isLoading.plants"
                  :items="Object.keys(allPlants).sort()"
                  :search-input.sync="search"
                  hide-no-data
                  clearable
                ></v-autocomplete>
              </v-flex>

              <v-flex md12>
                <div v-if="selectedrecipeid">
                  {{ this.getRecipeById(this.selectedrecipeid).description }}
                </div>
              </v-flex>
            </v-layout>

            <!--------------------------------->
            <!----- Options line: Command ----->
            <!--------------------------------->

            <v-layout wrap v-if="jobclass === 'command'">
              <v-flex md8>
                <v-text-field
                v-model="command"
                label="Command"
                :rules="commandRules"
                required
                ></v-text-field>
              </v-flex>

              <v-flex md2>
                <v-text-field
                  v-model="jobtimeout"
                  label="Job Timeout (sec)"
                  :rules="jobtimeoutRules"
                ></v-text-field>
              </v-flex>

              <v-flex md2>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="jobmaxdelay"
                        label="Job Max Delay (sec)"
                        :rules="jobtimeoutRules"
                        v-on="on"
                      ></v-text-field>
                    </template>
                  <span>Add a random delay on client job</span>
                </v-tooltip>
              </v-flex>
            </v-layout>

            <!----- Options line: RunAs ----->

            <v-layout wrap md12 v-if="jobclass === 'command'">
              <v-flex md3>
                <v-checkbox
                  v-model="runasflag"
                  label="Run as"
                  persistent-hint
                ></v-checkbox>
              </v-flex>

              <v-flex md3>
                <v-text-field
                  :disabled="!runasflag"
                  v-model="runas"
                ></v-text-field>
              </v-flex>

              <v-flex md12>
                <v-checkbox
                  v-model="withfile"
                  label="Include file"
                ></v-checkbox>
              </v-flex>
            </v-layout>

            <!--------------------------------------->
            <!----- Options line: FileTransfer ------>
            <!--------------------------------------->

            <div v-if="jobclass === 'filetransfer' || (jobclass === 'command' && withfile)">
              <v-layout wrap md12 v-for="(f, i) in fileobjects" :key="i">
                <v-flex md12>
                  <v-autocomplete
                    v-model="fileobjects[i].fileobjectid"
                    :items="getFiles()"
                    :loading="isLoading.files"
                    item-text="filenameversion"
                    item-value="fileobjectid"
                    clearable
                    label="Search files"
                  ></v-autocomplete>
                </v-flex>
                <v-flex md12>
                  <v-text-field
                  v-model="fileobjects[i].destination"
                  label="Destination"
                  :rules="destinationRules"
                  required
                  ></v-text-field>
                </v-flex>
                <v-flex md2>
                  <v-text-field
                  v-model="fileobjects[i].destinationUser"
                  label="User"
                  :rules="destinationUserGroupRules"
                  ></v-text-field>
                </v-flex>
                <v-flex md2>
                  <v-text-field
                  v-model="fileobjects[i].destinationGroup"
                  label="Group"
                  :rules="destinationUserGroupRules"
                  ></v-text-field>
                </v-flex>
                <v-flex md2>
                  <v-text-field
                  v-model="fileobjects[i].destinationMode"
                  label="Mode"
                  :rules="destinationModeRules"
                  ></v-text-field>
                </v-flex>
                <v-flex md2>
                  <v-checkbox
                    v-model="fileobjects[i].destinationOverwrite"
                    label="Overwrite"
                  ></v-checkbox>
                </v-flex>
                <v-flex md1>
                </v-flex>
                <v-flex md3>
                  <v-text-field
                    v-model="fileobjects[i].transfertimeout"
                    label="Transfer Timeout (sec)"
                    :rules="transfertimeoutRules"
                  ></v-text-field>
                </v-flex>
                <v-flex md12>
                  <span>
                  <v-btn fab x-small @click.prevent="addFileObjectField()">+</v-btn>
                  <v-btn fab x-small @click.prevent="delFileObjectField(i)" v-if="fileobjects.length > 1">-</v-btn>
                  </span>
                </v-flex>
              </v-layout>
            </div>

            <!----- Options line: Checkbox ----->

            <v-layout wrap md12>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-flex md3 v-on="on">
                    <v-checkbox
                      v-model="concurrent"
                      label="Safe to run concurrently"
                      persistent-hint
                    ></v-checkbox>
                  </v-flex>
                </template>
                <span>Task will not run concurrently with another task</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-flex md3 v-on="on">
                    <v-checkbox
                      v-model="sticky"
                      label="Wait for offline agents"
                      persistent-hint
                    ></v-checkbox>
                  </v-flex>
                </template>
                <span>Task will be pending until agent appears online</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-flex md3 v-on="on">
                    <v-checkbox
                      :disabled="true"
                      v-model="future"
                      label="Wait for future agents"
                      persistent-hint
                    ></v-checkbox>
                  </v-flex>
                </template>
                <span>Task will apply for future agents that match filter</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-flex md3 v-on="on">
                    <v-checkbox
                      v-model="schedule"
                      label="Schedule"
                    ></v-checkbox>
                  </v-flex>
                </template>
                <span>Schedule task for a later date</span>
              </v-tooltip>
            </v-layout>

            <!----- Option line: Schedule ----->

            <v-layout wrap md12>
              <v-layout wrap v-if="schedule">
                <v-flex md3>
                  <v-menu
                    ref="startdatemenu"
                    v-model="startdatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="datestart"
                        label="Start date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datestart"
                      no-title
                      @input="startdatemenu = false"
                      :allowed-dates="allowedDates"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex md3>
                  <v-menu
                    ref="starttimemenu"
                    v-model="starttimemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="timestart"
                        label="Start time"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timestart"
                      format="24hr"
                      scrollable
                    >
                    </v-time-picker>
                  </v-menu>
                </v-flex>

                <v-flex md3>
                  <v-menu
                    ref="enddatemenu"
                    v-model="enddatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateend"
                        label="Expiration date"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateend"
                      no-title
                      @input="enddatemenu = false"
                      :allowed-dates="allowedDates"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex md3>
                  <v-menu
                    ref="endtimemenu"
                    v-model="endtimemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="timeend"
                        label="Expiration time"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeend"
                      format="24hr"
                      scrollable
                    >
                    </v-time-picker>
                  </v-menu>
                </v-flex>

                <!----- Options #3.1 ----->

                <v-flex md2>
                  <v-checkbox
                    v-model="intervalenable"
                    :disabled="true"
                    label="Interval"
                  ></v-checkbox>
                </v-flex>

                <v-flex md2>
                  <v-text-field
                    v-model="intervaldays"
                    label="dd"
                    :disabled="!intervalenable"
                    :rules="intervaldaysRules"
                  ></v-text-field>
                </v-flex>

                <v-flex md2>
                  <v-menu
                    ref="intervalmenu"
                    v-model="intervalmenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="interval"
                        :disabled="!intervalenable"
                        label="hh:mm"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="interval"
                      format="24hr"
                      scrollable
                    >
                    </v-time-picker>
                  </v-menu>
                </v-flex>

                <v-flex md2>
                  <v-btn
                    text
                    icon
                    @click="clearSchedule()"
                  >
                  <v-icon small>clear</v-icon>
                  </v-btn>
                </v-flex>

                <v-flex md2>
                  <div>Time is in UTC</div>
                </v-flex>

              </v-layout>
            </v-layout>

          </v-container>
        </v-card-text>
      </v-card>

      <v-dialog
        v-model="confirmView"
        hide-overlay
        width="600"
      >
        <v-card>
          <v-card-title>Applicable Agents</v-card-title>
          <v-card-text>
            <v-progress-linear color="primary" :stream="true" :value="applicableAgentIDs.length" :active="applicableAgentIDsLoading"></v-progress-linear>
            <v-simple-table dense>
              <tbody>
                <tr v-for="id in applicableAgentIDs" :key="id">
                  <td>{{ id }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmView = false">Close</v-btn>
            <v-btn color="primary" text @click.prevent="callNewRequest(); confirmView = false;" v-if="confirm">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="newsnack"
        color="success"
        :elevation="23"
        :timeout="snackTimeout"
        :top="true"
      >
        New Request with ID {{ newrequestid }}
      </v-snackbar>

      <v-snackbar
        v-model="snack"
        :color="snackColor"
        :elevation="24"
        :timeout="snackTimeout"
        :top="true"
      >
        {{ snackMessage }}
        <v-btn
          text
          @click="snack = false"
        >
          <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
          Close
        </v-btn>
      </v-snackbar>
    </v-dialog>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../../config/config.json';
import libvue from "../lib/vue";
import FilteringEditor from "./common/FilteringEditor";

export default {
  mixins: [libvue],
  data () {
    return {
      dialog: false,
      includePinned: false,
      localFilters: null,
      filterValues: {},
      jobclass: "operation",
      command: '',
      runasflag: false,
      runas: 'root',
      fileobjects: [{fileobjectid: null, destination: null, destinationUser: 'root', destinationGroup: 'root', destinationMode: "644", destinationOverwrite: false, transfertimeout: 300}],
      // fileobjectid: null,
      // destination: '',
      // destinationUser: 'root',
      // destinationGroup: 'root',
      // destinationMode: '644',
      destinationRules: [
        v => v && v.length >= 1 && v.startsWith('/') || 'A path is required (filename is appended if it ends with a /)'
      ],
      destinationUserGroupRules: [
        v => v && v.length >= 1 && /^[_a-z][-0-9_a-z]*$/.test(v) || 'Should be a valid UNIX user or group'
      ],
      destinationModeRules: [
        v => { if (v && /^[0-7]+$/.test(v) && parseInt(v, 8) >= 0 && parseInt(v, 8) <= 777) return true ; else return 'Should be valid UNIX absolute permissions' }
      ],
      // destinationOverwrite: false,
      startdatemenu: false,
      starttimemenu: false,
      datestart: new Date().toISOString().substr(0, 10),
      timestart: ('0'+new Date().getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      enddatemenu: false,
      endtimemenu: false,
      dateend: new Date(new Date().getTime() + 86400000).toISOString().substr(0, 10),
      timeend: ('0'+new Date().getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      intervalenable: false,
      intervalmenu: false,
      interval: 0,
      intervaldays: 0,
      intervaldaysRules: [
        v => v >=0 && v <= 7 || 'Interval days must be between 0 and 7'
      ],
      sticky: false,
      future: false,
      withfile: false,
      // selectedfileid: null,
      selectedrecipeid: null,
      oparguments: null,
      commandRules: [
        v => v.length >= 1 || 'A command is required'
      ],
      transfertimeout: 300,
      transfertimeoutRules: [
        v => v && v >=1 && v <= 86400 || 'Timeout must be between 1s and 86400s'
      ],
      jobtimeout: 120,
      jobtimeoutRules: [
        v => v >=1 && v <= 86400 || 'Timeout must be between 1s and 86400s'
      ],
      jobmaxdelay: 0,
      schedule: false,
      concurrent: true,
      crosssite: null,
      search: null,
      confirm: false,
      confirmView: false,
      applicableAgentIDs: [],
      applicableAgentIDsLoading: false,
      cnRegex: /^[a-zA-Z0-9-]+-[svc]{1}[0-9]+[n]?[0-9]*.insolar-plants.net$/,
      cnRules: [
        v => v.match(this.cnRegex) && true || 'Invalid pattern'
      ],
      newrequestid: null,
      newsnack: false,
      snack: false,
      snackColor: 'primary',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  props: {
    agentid: String,
    recipeid: String,
    requestid: String,
    cn: String,
    clientid: String,
    op: String,
    filters: Array
  },
  components: { FilteringEditor },
  watch: {
    filters() {
      this.localFilters = this.filters && this.filters.length ? this.filters : null;
    },
    agentid: function() {
      this.setDefaultagent();
    },
    runasflag: function(v) {
      if (v) {
        this.runas = this.$auth.profile.nickname;
      } else {
        this.runas = 'root';
      }
    },
    sticky: function(v) {
      if (v) {
        this.schedule = true;
      }
    },
    schedule: function(v) {
      if (!v) {
        this.sticky = false;
      }
    },
    selectedrecipeid: function(v) {
      var recipe = this.getRecipeById(v);
      if (recipe) {
        this.jobtimeout = recipe.timeout;
        this.concurrent = recipe.concurrent;
        if (recipe.crosssite) {
          this.filterPlants();
        }
      }
    },
    dialog: function(v) {
      if (v) {
        this.getOpRecipe(this.operation);
        this.filterValues = this.getFilterValues(this.allAgents);
        this.runAgain();
      }
      else {
        this.$emit("update:filters", this.localFilters);
      }
    }
  },
  computed: {
    ...mapGetters(['allFiles', 'allRecipes', 'allAgents', 'allPlants', 'getRecipeById', 'getAgentById', 'getRequestById', 'getUserById', 'userConfig', 'isLoading']),
  },
  mounted() {
    if (this.recipeid) {
      this.jobclass = 'operation';
      this.selectedrecipeid = this.recipeid;
    }
    if (this.agentid) {
      this.setDefaultagent();
    }
    else {
      this.filterValues = this.getFilterValues(this.allAgents);
    }
  },
  methods: {
    resolveButtonColor(condition) {
      if (condition) return 'primary';
      return 'grey';
    },
    setOrRemovePinned() {
      this.includePinned = !this.includePinned;
      var pinnedAgents = [];
      for (const key of Object.keys(this.allAgents)) {
        this.userConfig.pinnedagentsidsList.forEach((v) => {
          if (key === v) pinnedAgents.push(key);
        })
      }
      if (this.includePinned && pinnedAgents.length > 0) {
        var hasfield = false;
        for (const f of this.localFilters) {
          if (f.field === '' || f.field === 'Agent ID') {
            hasfield = true;
          }
        }
        if (!hasfield) {
          this.localFilters.push({
            field: 'Agent ID',
            value: [],
            operator: 'is',
            multioperator: 'AND',
            matchesRegexOnValues: false
          });
        }
        for (const f of this.localFilters) {
          if (f.field === '' || f.field === 'Agent ID') {
            if (f.field === '') f.field = 'Agent ID';
            pinnedAgents.forEach((a) => {
              var exists = false;
              f.value.forEach((v) => {
                if (v === a) exists = true;
              });
              if (!exists) {
                f.value.push(a);
                f.operator = 'is';
                f.multioperator = 'AND';
              }
            });
          }
        }
      }
      if (!this.includePinned && pinnedAgents.length > 0) {
        for (const f of this.localFilters) {
          if (f.field === 'Agent ID') {
            pinnedAgents.forEach((a) => {
              f.value.splice(f.value.indexOf(a), 1);
            });
            if (this.localFilters.length > 1 && f.value.length === 0) {
              this.localFilters.splice(this.localFilters.indexOf({
                field: 'Agent ID',
                value: [],
                operator: 'is',
                multioperator: 'AND',
                matchesRegexOnValues: false
              }))
            }
          }
        }
      }
    },
    addFileObjectField() {
      this.fileobjects.push({fileobjectid: null, destination: null, destinationUser: 'root', destinationGroup: 'root', destinationMode: "644", destinationOverwrite: false, transfertimeout: 300});
    },
    delFileObjectField(i){
      this.fileobjects.splice(i, 1);
    },
    validateTask() {
      // filter validation
      if (!this.validateFilter(this.localFilters)) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackMessage = 'Empty field or value in filter';
        return false;
      }

      // schdedule validation
      if (!this.validateSchedule()) {
        return false;
      }

      // job timeout validation
      if (!this.validateTimeout()) {
        return false;
      }

      // command operation
      if (!this.validateOperation()) {
        return false;
      }

      // command validation
      if (!this.validateCommand()) {
        return false;
      }

      // file validation
      if (!this.validateFile()) {
        return false;
      }

      // validate arguments
      if (!this.validateArguments()) {
        return false;
      }

      // validate crossite
      if (!this.validateCrossSite()) {
        return false;
      }

      return true;
    },
    callNewRequest() {
      if (!this.validateTask()) {
        return
      }

      var args = {
        agentid: "",
        clientid: this.clientid,
        oparguments: this.oparguments,
        jobtimeout: this.jobtimeout,
        jobmaxdelay: this.jobmaxdelay,
        concurrent: this.concurrent,
        crosssite: "",
        recipeid: "",
        command: "",
        runas: this.runas,
        owner: this.$auth.profile.email,
        filterList: this.localFilters,
        schedule: {
          start: Date.parse(this.datestart + 'T' + this.timestart + 'Z') / 1000,
          expiration: Date.parse(this.dateend + 'T' + this.timeend + 'Z') / 1000,
          // interval: this.interval;
          sticky: this.sticky,
          future: this.future,
        },
        filesList: [],
      };

      // operation
      if (this.jobclass === "operation") {
        args.recipeid = this.selectedrecipeid;
        if (this.getRecipeById(this.selectedrecipeid).name.includes('auth::cert')) {
          if (!this.validateAuthCert()) {
            return
          }
        }
        // crosssite
        if (this.isCrossSite()) {
          var plant = this.allPlants[this.crosssite];
          if (plant && plant.ctrl0id) {
            args.crosssite = plant.ctrl0id;
          }
          if (args.crosssite === null || args.crosssite === "") {
            this.snack = true;
            this.snackColor = 'error'
            this.snackMessage = 'Site is invalid';
            return false;
          }
        }
      }

      // command
      if (this.jobclass === "command") {
        args.recipeid = "";
        args.oparguments = "";
        args.crosssite = "";
        args.command = this.command;
      }

      // filetransfer or command with file
      if (this.jobclass === "filetransfer" || (this.jobclass === "command" && this.withfile) && this.fileobjects.length > 0) {
        for (var fo of this.fileobjects) {
          if (!fo.fileobjectid || fo.fileobjectid === "") {
            this.dialog = false;
            this.snack = true;
            this.snackColor = 'error'
            this.snackMessage = 'No file is selected';
            return;
          }
          if (fo.destination.endsWith("/")) {
            fo.destination += this.allFiles[fo.fileobjectid].filename;
          }
          var fail = false;
          if (!fo.destination.startsWith('/') ||
              !/^[_a-z][-0-9_a-z]*$/.test(fo.destinationUser) ||
              !/^[_a-z][-0-9_a-z]*$/.test(fo.destinationGroup) ||
              !/^[0-7]+$/.test(fo.destinationMode) ||
              parseInt(fo.destinationMode, 8) < 0 ||
              parseInt(fo.destinationMode, 8) > 777) {
            fail = true;
          }
          if (fail) {
            this.dialog = false;
            this.snack = true;
            this.snackColor = 'error'
            this.snackMessage = 'Destination, file owner and permissions are required';
            return;
          }
          args.filesList.push({
            fileobjectid: fo.fileobjectid,
            filename: fo.destination,
            overwrite: fo.destinationOverwrite,
            transfertimeout: fo.transfertimeout,
            fileperms: {
              user: fo.destinationUser,
              group: fo.destinationGroup,
              mode: fo.destinationMode,
            }
          });
        }
      }

      // submit
      this.newRequest(args).then((v) => {
        this.snack = true;
        this.snackColor = 'success'
        this.snackMessage = 'Request ID ' + v.requestid;
      }).catch(() => {});

      this.dialog = false;
      this.$emit('submitted');
    },
    setDefaultagent(){
      this.localFilters = [{field: 'Agent ID', value: [this.agentid], operator: 'is', multioperator: 'AND', matchesRegexOnValues: false}];
    },
    viewApplicableAgents(confirm) {
      this.applicableAgentIDsLoading = true;

      if (!this.validateFilter(this.localFilters)) {
        this.applicableAgentIDsLoading = false;
        this.snack = true;
        this.snackColor = 'error';
        this.snackMessage = 'Empty field or value in filter';
        return
      }

      if (confirm && !this.validateTask()) {
        this.applicableAgentIDsLoading = false;
        return
      }

      // confirm=false when "view applicable agents" is selected
      // confirm=true when "submit" is selected
      this.confirm = confirm;
      this.confirmView = true;
      this.applicableAgentIDs = [];

      var args = {
        filterList: this.localFilters,
        owner: this.$auth.profile.email,
      }

      this.getApplicableAgents(args).then((v) => {
        this.applicableAgentIDsLoading = false;
        this.applicableAgentIDs = v;
      }).catch(() => { this.applicableAgentIDsLoading = false; });
    },
    validateFilter(filterList) {
      if (!filterList) return false;
      for (const filter of filterList) {
        if (filter.field === "") {
          return false; // Empty field in filter
        }
        if (!filter.value || filter.value.length === 0) {
          return false; // Empty value in filter
        }
      }
      return true;
    },
    allowedDates(val) {
      var now = new Date();
      return val < now.toISOString().substr(0, 10) || Date.parse(val) - (now.getTime() + config.taskIntervalMs) > 0 ? 0 : 1;
    },
    validateTimeout() {
      if (this.jobtimeout < 1 || this.jobtimeout > 86400) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'Timeout must be between 1s and 86400s';
        return false;
      }
      if (this.jobtimeout > this.transfertimeout) {
        this.transfertimeout = this.jobtimeout;
      }
      return true;
    },
    validateOperation() {
      if (this.jobclass !== "operation") return true;
      if (!this.selectedrecipeid) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'A recipe is required';
        return false;
      }
      return true;
    },
    validateAuthCert() {
      var a = this.getAgentById(this.agentid);
      if (!a.agentid.includes('unconfigured')) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'This Agent already has a valid certificate';
        return false;
      }
      this.snack = true;
      this.snackColor = 'error'
      this.snackMessage = 'Please use the Instances tab for creating certificates';
      return false;
    },
    validateCommand() {
      if (this.jobclass !== "command") return true;
      if (this.command.length < 1) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'A command is required';
        return false;
      }
      return true;
    },
    validateSchedule() {
      if (!this.schedule) return true;
      var now = new Date();
      if (Date.parse(this.dateend + 'T' + this.timeend + 'Z') < now.getTime()) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = "Scheduler end date is in the past";
        return false;
      }
      var timediff = Date.parse(this.dateend + 'T' + this.timeend + 'Z') - Date.parse(this.datestart + 'T' + this.timestart + 'Z');
      if (timediff <= 0) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = "Scheduler date expiration is before start";
        return false;
      }
      // interval validation
      if (this.intervaldays !== 0 || this.interval !== 0) {
        var interval = 0;
        if (this.intervaldays !== 0) {
          interval = this.intervaldays * 86400000;
        }
        if (this.interval !== 0) {
          var i = this.interval.split(":");
          interval = interval + i[0]*3600000 + i[1]*60000;
        }
        if (interval >= timediff) {
          this.snack = true;
          this.snackColor = 'error'
          this.snackMessage = "Interval duration is bigger then time of expiration - start";
          return false;
        }
      }
      return true;
    },
    validateFile() {
      if (this.withfile && this.fileobjects.length === 0) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'A file must be selected';
        return false;
      }

      if ((this.jobclass === "filetransfer" || (this.jobclass === "command" && this.withfile)) && this.fileobjects.length === 0) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'File transfer requires a file';
        return false;
      }

      if ((this.jobclass === "filetransfer" || (this.jobclass === "command" && this.withfile)) && this.fileobjects.length > 0) {
        for (var fo of this.fileobjects) {
          if (!fo.destination || fo.destination === "") {
            this.snack = true;
            this.snackColor = 'error'
            this.snackMessage = 'Destination is required';
            return false;
          }
          if (fo.destination.endsWith("/")) {
            fo.destination += this.allFiles[fo.fileobjectid].filename;
          }
          var fail = false;
          if (!fo.fileobjectid ||
              !fo.destination.startsWith('/') ||
              !/^[_a-z][-0-9_a-z]*$/.test(fo.destinationUser) ||
              !/^[_a-z][-0-9_a-z]*$/.test(fo.destinationGroup) ||
              !/^[0-7]+$/.test(fo.destinationMode) ||
              parseInt(fo.destinationMode, 8) < 0 ||
              parseInt(fo.destinationMode, 8) > 777) {
            fail = true;
          }
          if (fail) {
            this.snack = true;
            this.snackColor = 'error'
            this.snackMessage = 'There are errors in the form';
            return false;
          }
        }
      }
      return true;
    },
    validateArguments() {
      if (this.jobclass !== "operation") return true;
      if (this.argsRequired() && (this.oparguments === null || this.oparguments === "")) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'Recipe requires arguments';
        return false;
      }
      if (!this.argsRequired()) {
        this.oparguments = null; // Just to be safe (from what?)
      }
      return true;
    },
    validateCrossSite() {
      if (this.jobclass !== "operation") return true;
      if (this.isCrossSite() && (this.crosssite === null || this.crosssite === "")) {
        this.snack = true;
        this.snackColor = 'error'
        this.snackMessage = 'Recipe requires a site';
        return false;
      }
      if (!this.isCrossSite()) {
        this.crosssite = null; // Just to be safe (from what?)
      }
      return true;
    },
    clearSchedule() {
      this.intervaldays = 0;
      this.interval = 0;
      this.datestart = new Date().toISOString().substr(0, 10);
      this.timestart = ('0'+new Date().getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2);
      this.dateend = this.datestart;
      this.timeend = this.timestart;
    },
    getFiles() {
      var files = [];
      Object.values(this.allFiles).filter((v) => v.approved || v.owner === this.$auth.profile.email).sort((a, b) => a.filename.localeCompare(b.filename)).forEach((v) => {
        if (v.version && v.version !== "") {
          v.filenameversion = v.filename + " (v " + v.version + ")";
        } else {
          v.filenameversion = v.filename;
        }
        files.push(v);
      });
      return files;
    },
    getRecipes() {
      return Object.values(this.allRecipes).filter((v) => v.approved || v.owner === this.$auth.profile.email).sort((a, b) => a.name.localeCompare(b.name));
    },
    argsRequired(){
      if (this.selectedrecipeid) {
        var recipe = this.getRecipeById(this.selectedrecipeid);
        if (recipe) {
          return recipe.argsrequired;
        }
      }
      return false;
    },
    isCrossSite(){
      if (this.selectedrecipeid) {
        var recipe = this.getRecipeById(this.selectedrecipeid);
        if (recipe) {
          return recipe.crosssite;
        }
      }
      return false;
    },
    getOpRecipe(operation) {
      Object.values(this.allRecipes).forEach((v) => {
        if (v.name === operation) {
          this.selectedrecipeid = v.recipeid;
          this.jobclass = 'operation';
        }
      });
    },
    hasNewTaskFullAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.newtaskfullaccess;
      }
      return false;
    },
    runAgain() {
      var request = this.getRequestById(this.requestid);
      if (request) {
        if (request.recipeid !== "") {
          var recipe = this.getRecipeById(request.recipeid);
          if (recipe) {
            this.jobclass = 'operation';
            this.selectedrecipeid = recipe.recipeid;
            this.oparguments = request.args;
          }
        } else if (request.command !== "") {
          this.jobclass = 'command';
          this.command = request.command;
        } else {
          this.jobclass = 'filetransfer';
        }
        if (request.filesList.length > 0) {
          this.fileobjects = [];
          if (this.jobclass === 'command') {
            this.withfile = true;
          }
          for (var ft of request.filesList) {
            this.fileobjects.push({
              fileobjectid: ft.fileobjectid,
              destination: ft.filename,
              destinationUser: ft.fileperms.user,
              destinationGroup: ft.fileperms.group,
              destinationMode: ft.fileperms.mode,
              destinationOverwrite: ft.overwrite,
              transfertimeout: ft.transfertimeout
            });
          }
        }
        this.localFilters = [];
        request.filterList.forEach((f) => {
          this.localFilters.push({field: f.field, value: f.patternList, operator: f.operator, multioperator: f.multioperator});
        })
        this.concurrent = request.concurrent;
        this.runas = request.runas;
        this.jobtimeout = request.jobtimeout;
        this.jobmaxdelay = request.jobmaxdelay;
        if (request.crosssite && request.crosssite !== "") {
          this.filterPlants().then(() => {
            for (const k in this.allPlants) {
              if (this.allPlants[k].ctrl0id === request.crosssite) {
                this.crosssite = k;
                break;
              }
            }
          });
        }
      }
    },
    ...mapActions(['newRequest', 'getApplicableAgents', 'filterPlants'])
  }
}
</script>
