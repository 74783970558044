import Vue from 'vue';
import VueRouter from 'vue-router';

import auth from "../auth/authService";
import Profile from "../views/Profile";
import Login from '../components/Login.vue'
import Home from '../components/Home';
import Callback from '../components/Callback';
import Agents from '../components/Agents';
import AgentDetails from '../components/AgentDetails';
import Tasks from '../components/Tasks';
import FileDetails from '../components/FileDetails';
import Library from '../components/Library';
import RecipeDetails from '../components/RecipeDetails';
import Watchers from '../components/Watchers';
import Users from '../components/Users';
import Policies from '../components/Policies';
import Audit from '../components/Audit';
import Alarms from '../components/Alarms';
import Settings from '../components/Settings';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Home},
    { name: 'home-page', path: '/', component: Home} ,
    { name: 'login-page', path: '/login', component: Login },
    { name: 'callback', path: '/callback', component: Callback },
    { name: 'agents-page', path: '/agents', component: Agents },
    { name: 'agent-details', path: '/agents/:AgentID', component: AgentDetails },
    { name: 'tasks-page', path: '/tasks', component: Tasks, props: true },
    { name: 'file-details', path: '/files/:FileObjectID', component: FileDetails },
    { name: 'library-page', path: '/library/:Tab', component: Library },
    { name: 'recipe-details', path: '/recipes/:RecipeID', component: RecipeDetails },
    { name: 'watchers-page', path: '/watchers', component: Watchers },
    { name: 'users-page', path: '/users', component: Users },
    { name: 'policies-page', path: '/policies', component: Policies },
    { name: 'audit-page', path: '/audit', component: Audit },
    { name: 'alarms-page', path: '/alarms', component: Alarms },
    { name: 'settings-page', path: '/settings', component: Settings },
    { name: 'profile', path: '/profile', component: Profile }
  ] 
});

// NEW - add a `beforeEach` handler to each route
router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV == 'development') { 
    return next();
  }

  if (to.path === "/" || to.path === "/callback" || auth.isAuthenticated()) {
    return next();
  }

  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth.
  auth.renewTokens().then(() => { return next(); })
  .catch(() => { auth.login({ target: to.path })});
});

export default router;
