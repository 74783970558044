import Vue from "vue";
import Vuex from "vuex";
import config from "../../config/config.json";
import auth from "../auth/authService";

Vue.use(Vuex);

var BASE_URI =
  process.env.NODE_ENV == "production"
    ? window.location.protocol + "//" + window.location.hostname
    : config.url;

const { Audit, AuditRange } = require("../pb/audit_pb.js");
const { User } = require("../pb/user_pb.js");
const { Policy } = require("../pb/policy_pb.js");
const { Attributes } = require("../pb/attributes_pb.js");
const { AaaServiceClient } = require("../pb/aaa_service_grpc_web_pb.js");

const { Watcher, Daemon } = require("../pb/watcher_pb.js");
const { Config, UserConfig, Notification } = require("../pb/config_pb.js");

const { Agent } = require("../pb/agent_pb.js");
const {
  Instance,
  InstanceRange,
  CoolInfo,
  Identity,
  Release,
} = require("../pb/instance_pb.js");
const { Alarm, AlarmRange } = require("../pb/alarm_pb.js");
const { Problem, ProblemRange } = require("../pb/problem_pb.js");
const { WebMessage } = require("../pb/web_pb.js");
const { StoreServiceClient } = require("../pb/store_service_grpc_web_pb.js");

const {
  Request,
  RequestRange,
  Filter,
  Schedule,
} = require("../pb/request_pb.js");
const { Task, TaskRange } = require("../pb/task_pb.js");
const { Job, JobRange } = require("../pb/job_pb.js");
const { TaskServiceClient } = require("../pb/task_service_grpc_web_pb.js");
const {
  FileTransfer,
  FileTransferRange,
  FilePerms,
} = require("../pb/file_transfer_pb.js");
const { FileObject, FileAccess } = require("../pb/file_pb.js");
const { FileServiceClient } = require("../pb/file_service_grpc_web_pb.js");

const { Recipe, RecipeAccess } = require("../pb/recipe_pb.js");
const {
  LibraryServiceClient,
} = require("../pb/library_service_grpc_web_pb.js");

const { Plant, SvnServiceClient } = require("../pb/svn_service_grpc_web_pb.js");

const grpc = {};
grpc.web = require("grpc-web");

var aaClient = new AaaServiceClient(BASE_URI + "/_aaa", null, null);
var ssClient = new StoreServiceClient(BASE_URI + "/_store", null, null);
var ttClient = new TaskServiceClient(BASE_URI + "/_task", null, null);
var ffClient = new FileServiceClient(BASE_URI + "/_file", null, null);
var llClient = new LibraryServiceClient(BASE_URI + "/_library", null, null);
var svClient = new SvnServiceClient(BASE_URI + "/_svn", null, null);

const store = new Vuex.Store({
  data() {
    return {
      agentStream: {},
      taskStream: {},
      requestStream: {},
      jobStream: {},
      filetransferStream: {},
      fileStream: {},
      recipeStream: {},
      alarmStream: {},
      watcherStream: {},
    };
  },
  state: {
    agents: {},
    meminstances: {},
    instances: {},
    tasks: {},
    requests: {},
    jobs: {},
    filetransfers: {},
    files: {},
    recipes: {},
    plants: {},
    alarms: {},
    watchers: {},
    problems: {},
    audit: {},
    users: {},
    policies: {},
    config: {},
    userconfig: {},
    isLoading: {
      agents: false,
      meminstances: false,
      instances: false,
      requests: false,
      tasks: false,
      jobs: false,
      filetransfers: false,
      files: false,
      recipes: false,
      plants: false,
      alarms: false,
      watchers: false,
      problems: false,
      audit: false,
      users: false,
      policies: false,
      config: false,
    },
    error: {
      value: false,
      stream: false,
      code: null,
      message: "",
    },
  },
  mutations: {
    resetAgents(state, data) {
      state.agents = data;
      state.isLoading.agents = false;
    },
    updateAgents(state, agent) {
      if (agent.deleted) {
        Vue.delete(state.agents, agent.agentid);
      } else {
        Vue.set(state.agents, agent.agentid, agent);
      }
    },
    resetMemInstances(state, data) {
      state.meminstances = data;
      state.isLoading.meminstances = false;
    },
    resetInstances(state, data) {
      state.instances = data;
      state.isLoading.instances = false;
    },
    updateInstances(state, instance) {
      Vue.set(state.instances, instance.clientid, instance);
    },
    resetRequests(state, data) {
      state.requests = data;
      state.isLoading.requests = false;
    },
    updateRequests(state, request) {
      Vue.set(state.requests, request.requestid, request);
    },
    resetTasks(state, data) {
      state.tasks = data;
      state.isLoading.tasks = false;
    },
    updateTasks(state, task) {
      Vue.set(state.tasks, task.taskid, task);
    },
    resetJobs(state, data) {
      state.jobs = data;
      state.isLoading.jobs = false;
    },
    updateJobs(state, job) {
      Vue.set(state.jobs, job.jobid, job);
    },
    resetFileTransfers(state, data) {
      state.filetransfers = data;
      state.isLoading.filetransfers = false;
    },
    updateFileTransfers(state, filetransfer) {
      Vue.set(state.filetransfers, filetransfer.filetransferid, filetransfer);
    },
    resetFiles(state, data) {
      state.files = data;
      state.isLoading.files = false;
    },
    updateFiles(state, file) {
      if (file.deleted) {
        Vue.delete(state.files, file.fileobjectid);
      } else {
        Vue.set(state.files, file.fileobjectid, file);
      }
    },
    resetRecipes(state, data) {
      state.recipes = data;
      state.isLoading.recipes = false;
    },
    updateRecipes(state, recipe) {
      if (recipe.deleted) {
        Vue.delete(state.recipes, recipe.recipeid);
      } else {
        Vue.set(state.recipes, recipe.recipeid, recipe);
      }
    },
    resetPlants(state, data) {
      state.plants = data;
      state.isLoading.plants = false;
    },
    resetWatchers(state, data) {
      state.watchers = data;
      state.isLoading.watchers = false;
    },
    updateWatchers(state, watcher) {
      Vue.set(state.watchers, watcher.agentid, watcher);
    },
    resetAlarms(state, data) {
      state.alarms = data;
      state.isLoading.alarms = false;
    },
    updateAlarms(state, alarm) {
      if (alarm.deleted) {
        Vue.delete(state.alarms, alarm.alarmid);
      } else {
        Vue.set(state.alarms, alarm.alarmid, alarm);
      }
    },
    resetProblems(state, data) {
      state.problems = data;
      state.isLoading.problems = false;
    },
    updateProblems(state, problem) {
      Vue.set(state.problems, problem.agentid + problem.type, problem);
    },
    resetAudit(state, data) {
      state.audit = data;
      state.isLoading.audit = false;
    },
    updateAudit(state, audit) {
      Vue.set(state.audit, audit.auditid, audit);
    },
    resetUsers(state, data) {
      state.users = data;
      state.isLoading.users = false;
    },
    updateUsers(state, user) {
      if (user.deleted) {
        Vue.delete(state.users, user.sub);
      } else {
        Vue.set(state.users, user.sub, user);
      }
    },
    resetPolicies(state, data) {
      state.policies = data;
      state.isLoading.policies = false;
    },
    updatePolicies(state, policy) {
      if (policy.deleted) {
        Vue.delete(state.policies, policy.policyid);
      } else {
        Vue.set(state.policies, policy.policyid, policy);
      }
    },
    resetConfig(state, data) {
      Vue.set(state, "config", data);
      state.isLoading.config = false;
    },
    resetUserConfig(state, data) {
      Vue.set(state, "userconfig", data);
      state.isLoading.config = false;
    },
    updateUserConfig(state, data) {
      Vue.set(state, "userconfig", data);
    },
    isLoading(state, component) {
      switch (component) {
        case "agents":
          state.isLoading.agents = true;
          break;
        case "meminstances":
          state.isLoading.meminstances = true;
          break;
        case "instances":
          state.isLoading.instances = true;
          break;
        case "requests":
          state.isLoading.requests = true;
          break;
        case "tasks":
          state.isLoading.tasks = true;
          break;
        case "jobs":
          state.isLoading.jobs = true;
          break;
        case "filetransfers":
          state.isLoading.filetransfers = true;
          break;
        case "files":
          state.isLoading.files = true;
          break;
        case "recipes":
          state.isLoading.recipes = true;
          break;
        case "plants":
          state.isLoading.plants = true;
          break;
        case "alarms":
          state.isLoading.alarms = true;
          break;
        case "problems":
          state.isLoading.problems = true;
          break;
        case "audit":
          state.isLoading.audit = true;
          break;
        case "users":
          state.isLoading.users = true;
          break;
        case "policies":
          state.isLoading.policies = true;
          break;
        case "config":
          state.isLoading.config = true;
          break;
      }
    },
    isNotLoading(state, component) {
      switch (component) {
        case "agents":
          state.isLoading.agents = false;
          break;
        case "meminstances":
          state.isLoading.meminstances = false;
          break;
        case "instances":
          state.isLoading.instances = false;
          break;
        case "requests":
          state.isLoading.requests = false;
          break;
        case "tasks":
          state.isLoading.tasks = false;
          break;
        case "jobs":
          state.isLoading.jobs = false;
          break;
        case "filetransfers":
          state.isLoading.filetransfers = false;
          break;
        case "files":
          state.isLoading.files = false;
          break;
        case "recipes":
          state.isLoading.recipes = false;
          break;
        case "plants":
          state.isLoading.plants = false;
          break;
        case "watchers":
          state.isLoading.watchers = false;
          break;
        case "alarms":
          state.isLoading.alarms = false;
          break;
        case "problems":
          state.isLoading.problems = false;
          break;
        case "audit":
          state.isLoading.audit = false;
          break;
        case "users":
          state.isLoading.users = false;
          break;
        case "policies":
          state.isLoading.policies = false;
          break;
        case "config":
          state.isLoading.config = false;
          break;
      }
    },
    updateError(state, err) {
      state.error.value = true;
      if (err.stream) state.error.stream = true;
      else state.error.stream = false;
      state.error.code = err.err.code;
      if (err.err.details) {
        state.error.message = " " + err.err.details;
      } else if (err.err.message) {
        state.error.message = " " + err.err.message;
      }
      if (process.env.NODE_ENV != "production") {
        state.error.message += " (" + err.func + ")";
      }
      state.isLoading.agents = false;
      (state.isLoading.meminstances = false),
        (state.isLoading.instances = false);
      state.isLoading.requests = false;
      state.isLoading.tasks = false;
      state.isLoading.jobs = false;
      state.isLoading.filetransfers = false;
      state.isLoading.files = false;
      state.isLoading.recipes = false;
      state.isLoading.plants = false;
      state.isLoading.watchers = false;
      state.isLoading.alarms = false;
      state.isLoading.problems = false;
      state.isLoading.audit = false;
      state.isLoading.users = false;
      state.isLoading.policies = false;
      state.isLoading.config = false;
    },
    clearError(state) {
      state.error.value = false;
      state.error.stream = false;
      state.error.code = null;
      state.error.message = "";
    },
  },
  getters: {
    allAgents: (state) => {
      return state.agents;
    },
    getAgentById: (state) => (id) => {
      return state.agents[id];
    },
    allMemInstances: (state) => {
      return state.meminstances;
    },
    allInstances: (state) => {
      return state.instances;
    },
    allTasks: (state) => {
      return state.tasks;
    },
    allRequests: (state) => {
      return state.requests;
    },
    getRequestById: (state) => (id) => {
      return state.requests[id];
    },
    allJobs: (state) => {
      return state.jobs;
    },
    allFiles: (state) => {
      return state.files;
    },
    allFileTransfers: (state) => {
      return state.filetransfers;
    },
    getFileById: (state) => (id) => {
      return state.files[id];
    },
    allRecipes: (state) => {
      return state.recipes;
    },
    getRecipeById: (state) => (id) => {
      return state.recipes[id];
    },
    allPlants: (state) => {
      return state.plants;
    },
    allWatchers: (state) => {
      return state.watchers;
    },
    allAlarms: (state) => {
      return state.alarms;
    },
    allProblems: (state) => {
      return state.problems;
    },
    allAudit: (state) => {
      return state.audit;
    },
    allUsers: (state) => {
      return state.users;
    },
    getUserById: (state) => (id) => {
      return state.users[id];
    },
    allPolicies: (state) => {
      return state.policies;
    },
    allConfig: (state) => {
      return state.config;
    },
    userConfig: (state) => {
      return state.userconfig;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    error: (state) => {
      return state.error;
    },
  },
  actions: {
    filterAgents({ commit }) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "agents");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Agent();
          var stream = ssClient.filterAgents(request, metadata);
          var agents = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            agents[o.agentid] = o;
          });
          stream.on("end", function () {
            commit("resetAgents", agents);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterAgents" });
            reject();
          });
        });
      });
    },
    watchAgents({ dispatch, commit }, retry) {
      if (!retry) retry = 0;
      if (this.agentStream) this.agentStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Agent();
        this.agentStream = ssClient.watchAgents(request, metadata);
        this.agentStream.on("data", function (response) {
          retry = 0;
          commit("updateAgents", response.toObject());
        });
        this.agentStream.on("end", function () {});
        this.agentStream.on("error", (err) => {
          if (retry < 5) {
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * retry * retry)
            ).then(() => {
              dispatch("watchAgents", retry + 1);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchAgents",
              stream: true,
            });
          }
        });
      });
    },
    deleteAgent({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Agent();
          if (args) {
            request.setAgentid(args.agentid);
          }
          ssClient.deleteAgent(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "deleteAgent" });
              reject();
            } else {
              commit("updateAgents", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    openWeb({ commit }, args) {
      return new Promise(function (resolve, reject) {
        var request = new WebMessage();
        request.setAgentid(args.agentid);
        request.setClientid(args.clientid);
        request.setRemote(args.remote);
        request.setDisablegrpc(args.disablegrpc);
        auth.getAccessToken().then((accessToken) => {
          var deadline = new Date();
          deadline.setSeconds(deadline.getSeconds() + 30);
          var metadata = {
            Authorization: "Bearer " + accessToken,
            deadline: deadline.getTime(),
          };
          ssClient.openWeb(request, metadata, (err, response) => {
            if (err) {
              if (args.disablegrpc) {
                commit("updateError", { err: err, func: "openWeb" });
              }
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterMemInstances({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "meminstances");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Instance();
          if (args) {
            request.setAgentid(args.agentid);
          }
          var stream = ssClient.filterMemInstances(request, metadata);
          var instances = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            // Create these with empty values instead of checking their existence every time.
            if (!o.info) {
              o.info = new CoolInfo();
            }
            if (!o.info.identity) {
              o.info.identity = new Identity();
            }
            if (!o.info.release) {
              o.info.release = new Release();
            }
            instances[o.clientid] = o;
          });
          stream.on("end", function () {
            commit("resetMemInstances", instances);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterMemInstances" });
            reject();
          });
        });
      });
    },
    clearMemInstances({ commit }) {
      commit("resetMemInstances", {});
    },
    filterInstances({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "instances");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new InstanceRange();
          var instance = new Instance();
          if (args) {
            instance.setAgentid(args.agentid);
            request.setStart(args.start);
            request.setLimit(args.limit);
            request.setInstance(instance);
          }
          var stream = ssClient.filterInstances(request, metadata);
          var instances = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            instances[o.clientid] = o;
            if (args.more) commit("updateInstances", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetInstances", instances);
            else commit("isNotLoading", "instances");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterInstances" });
            reject();
          });
        });
      });
    },
    getInstance({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Instance();
          if (args) {
            request.setClientid(args.recipeid);
            request.setOwner(args.owner);
          }
          ssClient.getInstance(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getInstance" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    clearInstances({ commit }) {
      commit("resetInstances", {});
    },
    deregisterInstance({ commit }, args) {
      return new Promise(function (resolve, reject) {
        var request = new Instance();
        request.setAgentid(args.agentid);
        request.setClientid(args.clientid);
        auth.getAccessToken().then((accessToken) => {
          var deadline = new Date();
          deadline.setSeconds(deadline.getSeconds() + 30);
          var metadata = {
            Authorization: "Bearer " + accessToken,
            deadline: deadline.getTime(),
          };
          ssClient.deregisterInstance(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "deregisterInstance" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    getApplicableAgents({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var filterList = [];
          args.filterList.forEach((f) => {
            var filter = new Filter();
            filter.setField(f.field);
            filter.setPatternList(f.value);
            filter.setOperator(f.operator);
            filter.setMultioperator(f.multioperator);
            filterList.push(filter);
          });
          var request = new Request();
          request.setOwner(args.owner);
          request.setFilterList(filterList);
          var metadata = { Authorization: "Bearer " + accessToken };
          var stream = ssClient.getApplicableAgents(request, metadata);
          var agents = [];
          stream.on("data", function (response) {
            var o = response.toObject();
            agents.push(o.agentid);
          });
          stream.on("end", function () {
            resolve(agents);
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "getApplicableAgents" });
            reject();
          });
        });
      });
    },
    newRequest({ commit }, args) {
      return new Promise(function (resolve, reject) {
        var filterList = [];
        args.filterList.forEach((f) => {
          var filter = new Filter();
          filter.setField(f.field);
          filter.setPatternList(f.value);
          filter.setOperator(f.operator);
          filter.setMultioperator(f.multioperator);
          filterList.push(filter);
        });
        var schedule = new Schedule();
        if (args.schedule) {
          schedule.setStart(args.schedule.start);
          schedule.setExpiration(args.schedule.expiration);
          // schedule.setInterval(args.schedule.interval);
          schedule.setSticky(args.schedule.sticky);
          schedule.setFuture(args.schedule.future);
        }
        var filesList = [];
        if (args.filesList) {
          args.filesList.forEach((ft) => {
            var filetransfer = new FileTransfer();
            var fileperms = new FilePerms();
            filetransfer.setFileobjectid(ft.fileobjectid);
            filetransfer.setFilename(ft.filename);
            filetransfer.setOverwrite(ft.overwrite);
            filetransfer.setTransfertimeout(ft.transfertimeout);
            if (ft.fileperms) {
              fileperms.setUser(ft.fileperms.user);
              fileperms.setGroup(ft.fileperms.group);
              fileperms.setMode(ft.fileperms.mode);
            }
            filetransfer.setFileperms(fileperms);
            filesList.push(filetransfer);
          });
        }
        var request = new Request();
        request.setClientid(args.clientid);
        request.setArgs(args.oparguments);
        request.setJobtimeout(args.jobtimeout);
        request.setJobmaxdelay(args.jobmaxdelay);
        request.setConcurrent(args.concurrent);
        request.setCrosssite(args.crosssite);
        request.setRecipeid(args.recipeid);
        request.setCommand(args.command);
        request.setRunas(args.runas);
        request.setOwner(args.owner);
        request.setRemoteaddress(args.remoteaddress);
        request.setFilterList(filterList);
        request.setSchedule(schedule);
        request.setFilesList(filesList);
        auth.getAccessToken().then((accessToken) => {
          var deadline = new Date();
          deadline.setSeconds(deadline.getSeconds() + 30);
          var metadata = {
            Authorization: "Bearer " + accessToken,
            deadline: deadline.getTime(),
          };
          if (args.recipeid != "") {
            ttClient.newFromRecipe(request, metadata, (err, response) => {
              if (err) {
                commit("updateError", { err: err, func: "newRequest" });
                reject();
              } else {
                resolve(response.toObject());
              }
            });
          } else {
            ttClient.newRequest(request, metadata, (err, response) => {
              if (err) {
                commit("updateError", { err: err, func: "newRequest" });
                reject();
              } else {
                resolve(response.toObject());
              }
            });
          }
        });
      });
    },
    cancelRequest({ commit }, args) {
      return new Promise(function (resolve, reject) {
        var request = new Request();
        request.setRequestid(args.requestid);
        request.setOwner(args.owner);
        // eslint-disable-next-line no-undef
        request.setStatus(proto.pb.TaskStatus.CANCELLED);
        request.setSubmitted(args.submitted);
        auth.getAccessToken().then((accessToken) => {
          var deadline = new Date();
          deadline.setSeconds(deadline.getSeconds() + 30);
          var metadata = {
            Authorization: "Bearer " + accessToken,
            deadline: deadline.getTime(),
          };
          ttClient.cancelRequest(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "cancelRequest" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterRequests({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "requests");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new RequestRange();
          var taskrequest = new Request();
          if (args) {
            request.setStart(args.start);
            request.setLimit(args.limit);
            request.setAgentid(args.agentid);
            taskrequest.setOwner(args.owner);
          }
          request.setRequest(taskrequest);
          var stream = ttClient.filterRequests(request, metadata);
          var requests = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            requests[o.requestid] = o;
            if (args.more) commit("updateRequests", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetRequests", requests);
            else commit("isNotLoading", "requests");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterRequests" });
            reject();
          });
        });
      });
    },
    watchRequests({ dispatch, commit }, args) {
      if (!args) args = {};
      if (!args.retry) args.retry = 0;
      if (this.requestStream) this.requestStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Request();
        this.requestStream = ttClient.watchRequests(request, metadata);
        this.requestStream.on("data", function (response) {
          args.retry = 0;
          commit("updateRequests", response.toObject());
        });
        this.requestStream.on("end", function () {});
        this.requestStream.on("error", (err) => {
          if (args.retry < 5) {
            args.retry += 1;
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * args.retry * args.retry)
            ).then(() => {
              dispatch("watchRequests", args);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchRequests",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchRequests() {
      if (this.requestStream) this.requestStream.cancel();
    },
    filterTasks({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "tasks");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new TaskRange();
          var task = new Task();
          if (args) {
            request.setStart(args.start);
            request.setLimit(args.limit);
            task.setAgentid(args.agentid);
            request.setOwner(args.owner);
          }
          request.setTask(task);
          var stream = ttClient.filterTasks(request, metadata);
          var tasks = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            tasks[o.taskid] = o;
            if (args.more) commit("updateTasks", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetTasks", tasks);
            else commit("isNotLoading", "tasks");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterTasks" });
            reject();
          });
        });
      });
    },
    watchTasks({ dispatch, commit }, args) {
      if (!args) args = {};
      if (!args.retry) args.retry = 0;
      if (this.taskStream) this.taskStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Task();
        if (args.agentid) request.setAgentid(args.agentid);
        this.taskStream = ttClient.watchTasks(request, metadata);
        this.taskStream.on("data", function (response) {
          args.retry = 0;
          commit("updateTasks", response.toObject());
        });
        this.taskStream.on("end", function () {});
        this.taskStream.on("error", (err) => {
          if (args.retry < 5) {
            args.retry += 1;
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * args.retry * args.retry)
            ).then(() => {
              dispatch("watchTasks", args);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchTasks",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchTasks() {
      if (this.taskStream) this.taskStream.cancel();
    },
    filterJobs({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "jobs");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new JobRange();
          var job = new Job();
          if (args) {
            request.setStart(args.start);
            request.setLimit(args.limit);
            job.setAgentid(args.agentid);
            request.setOwner(args.owner);
          }
          request.setJob(job);
          var stream = ttClient.filterJobs(request, metadata);
          var jobs = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            jobs[o.jobid] = o;
            if (args.more) commit("updateJobs", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetJobs", jobs);
            else commit("isNotLoading", "jobs");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterJobs" });
            reject();
          });
        });
      });
    },
    watchJobs({ dispatch, commit }, args) {
      if (!args) args = {};
      if (!args.retry) args.retry = 0;
      if (this.jobStream) this.jobStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Job();
        if (args.agentid) request.setAgentid(args.agentid);
        this.jobStream = ttClient.watchJobs(request, metadata);
        this.jobStream.on("data", function (response) {
          commit("updateJobs", response.toObject());
        });
        this.jobStream.on("end", function () {});
        this.jobStream.on("error", (err) => {
          if (args.retry < 5) {
            args.retry += 1;
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * args.retry * args.retry)
            ).then(() => {
              dispatch("watchJobs", args);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchJobs",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchJobs() {
      if (this.jobStream) this.jobStream.cancel();
    },
    filterFileTransfers({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "filetransfers");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileTransferRange();
          var fileTransfer = new FileTransfer();
          if (args) {
            request.setStart(args.start);
            request.setLimit(args.limit);
            fileTransfer.setAgentid(args.agentid);
            request.setOwner(args.owner);
          }
          request.setFiletransfer(fileTransfer);
          var stream = ttClient.filterFileTransfers(request, metadata);
          var filetransfers = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            filetransfers[o.filetransferid] = o;
            if (args.more) commit("updateFileTransfers", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetFileTransfers", filetransfers);
            else commit("isNotLoading", "filetransfers");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterFileTransfers" });
            reject();
          });
        });
      });
    },
    watchFileTransfers({ dispatch, commit }, args) {
      if (!args) args = {};
      if (!args.retry) args.retry = 0;
      if (this.filetransferStream) this.filetransferStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new FileTransfer();
        if (args.agentid) request.setAgentid(args.agentid);
        this.filetransferStream = ttClient.watchFileTransfers(
          request,
          metadata
        );
        this.filetransferStream.on("data", function (response) {
          commit("updateFileTransfers", response.toObject());
        });
        this.filetransferStream.on("end", function () {});
        this.filetransferStream.on("error", (err) => {
          if (args.retry < 5) {
            args.retry += 1;
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * args.retry * args.retry)
            ).then(() => {
              dispatch("watchFileTransfers", args);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchFileTransfers",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchFileTransfers() {
      if (this.filetransferStream) this.filetransferStream.cancel();
    },
    getFile({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileObject();
          request.setFileobjectid(args.fileobjectid);
          request.setOwner(args.owner);
          ffClient.getFile(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getFile" });
              reject();
            } else {
              commit("updateFiles", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterFiles({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "files");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileObject();
          if (args) {
            request.setFileobjectid(args.fileobjectid);
          }
          var stream = ffClient.filterFiles(request, metadata);
          var files = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            files[o.fileobjectid] = o;
          });
          stream.on("end", function () {
            commit("resetFiles", files);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterFiles" });
            reject();
          });
        });
      });
    },
    watchFiles({ dispatch, commit }, args) {
      if (!args) args = {};
      if (!args.retry) args.retry = 0;
      if (this.fileStream) this.fileStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new FileObject();
        if (args && args.fileobjectid) {
          request.setFileobjectid(args.fileobjectid);
        }
        this.fileStream = ffClient.watchFiles(request, metadata);
        this.fileStream.on("data", function (response) {
          args.retry = 0;
          commit("updateFiles", response.toObject());
        });
        this.fileStream.on("end", function () {});
        this.fileStream.on("error", (err) => {
          if (args.retry < 5) {
            args.retry += 1;
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * args.retry * args.retry)
            ).then(() => {
              dispatch("watchFiles", args);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchFiles",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchFiles() {
      if (this.fileStream) this.fileStream.cancel();
    },
    previewFile({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileObject();
          request.setFileobjectid(args.fileobjectid);
          request.setOwner(args.owner);
          ffClient.previewFile(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "previewFile" });
              reject();
            } else {
              commit("updateFiles", response.toObject());
              resolve(response);
            }
          });
        });
      });
    },
    approveFile({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileObject();
          if (args) {
            request.setFileobjectid(args.fileobjectid);
            request.setApproved(args.approved);
            request.setOwner(args.owner);
          }
          ffClient.approveFile(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "approveFile" });
              reject();
            } else {
              commit("updateFiles", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    deleteFile({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileObject();
          if (args) {
            request.setFileobjectid(args.fileobjectid);
            request.setOwner(args.owner);
          }
          ffClient.deleteFile(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "deleteFile" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    updateFile({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileObject();
          if (args) {
            request.setFileobjectid(args.fileobjectid);
            request.setFilename(args.filename);
            request.setDescription(args.description);
            request.setVersion(args.version);
            request.setOwner(args.owner);
          }
          ffClient.updateFile(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "updateFile" });
              reject();
            } else {
              commit("updateFiles", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    getFileAccess({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileAccess();
          if (args) {
            request.setFileobjectid(args.fileobjectid);
            request.setOwner(args.owner);
          }
          ffClient.getFileAccess(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getFileAccess" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    setFileAccess({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new FileAccess();
          if (args) {
            request.setFileobjectid(args.fileobjectid);
            request.setOwner(args.owner);
            request.setSubList(args.subList);
            request.setSharewithall(args.sharewithall);
          }
          ffClient.setFileAccess(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "setFileAccess" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterRecipes({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "recipes");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Recipe();
          if (args) {
            request.setRecipeid(args.recipeid);
          }
          var stream = llClient.filterRecipes(request, metadata);
          var recipes = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            recipes[o.recipeid] = o;
          });
          stream.on("end", function () {
            commit("resetRecipes", recipes);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterRecipes" });
            reject();
          });
        });
      });
    },
    watchRecipes({ dispatch, commit }, args) {
      if (!args) args = {};
      if (!args.retry) args.retry = 0;
      if (this.recipeStream) this.recipeStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Recipe();
        if (args && args.recipeid) {
          request.setRecipeid(args.recipeid);
        }
        this.recipeStream = llClient.watchRecipes(request, metadata);
        this.recipeStream.on("data", function (response) {
          args.retry = 0;
          commit("updateRecipes", response.toObject());
        });
        this.recipeStream.on("end", function () {});
        this.recipeStream.on("error", (err) => {
          if (args.retry < 5) {
            args.retry += 1;
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * args.retry * args.retry)
            ).then(() => {
              dispatch("watchRecipes", args);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchRecipes",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchRecipes() {
      if (this.recipeStream) this.recipeStream.cancel();
    },
    newRecipe({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Recipe();
          if (args) {
            request.setOwner(args.owner);
          }
          llClient.newRecipe(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "newRecipe" });
              reject();
            } else {
              commit("updateRecipes", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    getRecipe({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Recipe();
          if (args) {
            request.setRecipeid(args.recipeid);
            request.setOwner(args.owner);
          }
          llClient.getRecipe(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getRecipe" });
              reject();
            } else {
              commit("updateRecipes", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    approveRecipe({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Recipe();
          if (args) {
            request.setRecipeid(args.recipeid);
            request.setApproved(args.approved);
            request.setOwner(args.owner);
          }
          llClient.approveRecipe(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "approveRecipe" });
              reject();
            } else {
              commit("updateRecipes", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    updateRecipe({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Recipe();
          if (args) {
            var filesList = [];
            if (args.filesList) {
              args.filesList.forEach((ft) => {
                var filetransfer = new FileTransfer();
                var fileperms = new FilePerms();
                filetransfer.setFileobjectid(ft.fileobjectid);
                filetransfer.setFilename(ft.filename);
                filetransfer.setOverwrite(ft.overwrite);
                filetransfer.setTransfertimeout(ft.transfertimeout);
                if (ft.fileperms) {
                  fileperms.setUser(ft.fileperms.user);
                  fileperms.setGroup(ft.fileperms.group);
                  fileperms.setMode(ft.fileperms.mode);
                }
                filetransfer.setFileperms(fileperms);
                filesList.push(filetransfer);
              });
            }
            request.setRecipeid(args.recipeid);
            request.setName(args.name);
            request.setDescription(args.description);
            request.setSwitch(args.pb_switch);
            request.setRunas(args.runas);
            request.setData(args.data);
            request.setArgsrequired(args.argsrequired);
            request.setOwner(args.owner);
            request.setTimeout(args.timeout);
            request.setConcurrent(args.concurrent);
            request.setCrosssite(args.crosssite);
            request.setFilesList(filesList);
            request.setSticky(args.sticky);
          }
          llClient.updateRecipe(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "updateRecipe" });
              reject();
            } else {
              commit("updateRecipes", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    deleteRecipe({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Recipe();
          if (args) {
            request.setRecipeid(args.recipeid);
            request.setOwner(args.owner);
          }
          llClient.deleteRecipe(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "deleteRecipe" });
              reject();
            } else {
              commit("updateRecipes", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    getRecipeAccess({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new RecipeAccess();
          if (args) {
            request.setRecipeid(args.recipeid);
            request.setOwner(args.owner);
          }
          llClient.getRecipeAccess(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getRecipeAccess" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    setRecipeAccess({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new RecipeAccess();
          if (args) {
            request.setRecipeid(args.recipeid);
            request.setOwner(args.owner);
            request.setSubList(args.subList);
            request.setSharewithall(args.sharewithall);
          }
          llClient.setRecipeAccess(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "setRecipeAccess" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterWatchers({ commit }) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "watchers");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Watcher();
          var stream = ssClient.filterWatchers(request, metadata);
          var watchers = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            watchers[o.agentid] = o;
          });
          stream.on("end", function () {
            commit("resetWatchers", watchers);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterWatchers" });
            reject();
          });
        });
      });
    },
    watchWatchers({ dispatch, commit }, retry) {
      if (!retry) retry = 0;
      if (this.watcherStream) this.watcherStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Watcher();
        this.watcherStream = ssClient.watchWatchers(request, metadata);
        this.watcherStream.on("data", function (response) {
          retry = 0;
          commit("updateWatchers", response.toObject());
        });
        this.watcherStream.on("end", function () {});
        this.watcherStream.on("error", (err) => {
          if (retry < 5) {
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * retry * retry)
            ).then(() => {
              dispatch("watchWatchers", retry + 1);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchWatchers",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchWatchers() {
      if (this.watcherStream) this.watcherStream.cancel();
    },
    filterAlarms({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "alarms");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new AlarmRange();
          var alarm = new Alarm();
          if (args) {
            request.setStart(args.start);
            request.setLimit(args.limit);
            alarm.setAgentid(args.agentid);
            alarm.setName(args.name);
          }
          request.setAlarm(alarm);
          var stream = ssClient.filterAlarms(request, metadata);
          var alarms = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            alarms[o.alarmid] = o;
            if (args.more) commit("updateAlarms", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetAlarms", alarms);
            else commit("isNotLoading", "alarms");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterAlarms" });
            reject();
          });
        });
      });
    },
    getAlarm({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Alarm();
          if (args) {
            request.setAgentid(args.agentid);
            request.setAlarmid(args.alarmid);
            request.setDate(args.date);
          }
          ssClient.getAlarm(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getAlarm" });
              reject();
            } else {
              commit("updateAlarms", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    watchAlarms({ dispatch, commit }, retry) {
      if (!retry) retry = 0;
      if (this.alarmStream) this.alarmStream.cancel();
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Alarm();
        this.alarmStream = ssClient.watchAlarms(request, metadata);
        this.alarmStream.on("data", function (response) {
          retry = 0;
          commit("updateAlarms", response.toObject());
        });
        this.alarmStream.on("end", function () {});
        this.alarmStream.on("error", (err) => {
          if (retry < 5) {
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * retry * retry)
            ).then(() => {
              dispatch("watchAlarms", retry + 1);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchAlarms",
              stream: true,
            });
          }
        });
      });
    },
    cancelWatchAlarms() {
      if (this.alarmStream) this.alarmStream.cancel();
    },
    filterProblems({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "problems");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new ProblemRange();
          var problem = new Problem();
          if (args) {
            request.setStart(args.start);
            request.setLimit(args.limit);
            problem.setAgentid(args.agentid);
            problem.setValue(args.threshold);
            problem.setType(args.type);
          }
          request.setProblem(problem);
          var stream = ssClient.filterProblems(request, metadata);
          var problems = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            problems[o.agentid + o.type] = o;
            if (args.more) commit("updateProblems", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetProblems", problems);
            else commit("isNotLoading", "problems");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterProblems" });
            reject();
          });
        });
      });
    },
    getConfig({ commit }) {
      commit("isLoading", "config");
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Config();
          ssClient.getConfig(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getConfig" });
              reject();
            } else {
              commit("resetConfig", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    watchConfig({ dispatch, commit }, retry) {
      if (!retry) retry = 0;
      auth.getAccessToken().then((accessToken) => {
        var metadata = { Authorization: "Bearer " + accessToken };
        var request = new Config();
        var stream = ssClient.watchConfig(request, metadata);
        stream.on("data", function (response) {
          retry = 0;
          commit("resetConfig", response.toObject());
        });
        stream.on("end", function () {});
        stream.on("error", (err) => {
          if (retry < 5) {
            new Promise((resolve) =>
              setTimeout(resolve, 2000 * retry * retry)
            ).then(() => {
              dispatch("watchConfig", retry + 1);
            });
          } else {
            commit("updateError", {
              err: err,
              func: "watchConfig",
              stream: true,
            });
          }
        });
      });
    },
    getUserConfig({ commit }, args) {
      return new Promise(function (resolve, reject) {
        if (!args) {
          reject("Invalid arguments");
          return;
        }
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new UserConfig();
          request.setSub(args.sub);
          request.setOwner(args.owner);
          ssClient.getUserConfig(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getUserConfig" });
              reject();
            } else {
              commit("resetUserConfig", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    setUserConfig({ commit }, args) {
      return new Promise(function (resolve, reject) {
        if (!args) {
          reject("Invalid arguments");
          return;
        }
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new UserConfig();
          request.setSub(args.sub);
          request.setOwner(args.owner);
          request.setPinnedagentsidsList(args.pinnedagentsidsList);
          request.setItemsperpage(args.itemsperpage);
          request.setPinned(args.pinned);
          ssClient.setUserConfig(request, metadata, (err, response) => {
            if (err) {
              reject(err);
            } else {
              commit("resetUserConfig", response.toObject());
              resolve(response);
            }
          });
        });
      });
    },
    setNotification({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Notification();
          request.setMessage(args.message);
          request.setWarning(args.warning);
          ssClient.setNotification(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "setNotification" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    applyWatchers({ commit }) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var config = new Config();
          ssClient.applyWatchers(config, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "applyWatchers" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    setWatcher({ commit }, args) {
      return new Promise(function (resolve, reject) {
        if (!args) {
          reject("Invalid arguments");
          return;
        }
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Watcher();
          request.setName(args.name);
          request.setTag(args.tag);
          var daemonsList = [];
          args.daemonsList.forEach((name) => {
            var daemon = new Daemon();
            daemon.setName(name);
            daemonsList.push(daemon);
          });
          request.setDaemonsList(daemonsList);
          request.setGlob(args.glob);
          ssClient.setWatcher(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "setWatcher" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    deleteWatcher({ commit }, args) {
      return new Promise(function (resolve, reject) {
        if (!args) {
          reject("Invalid arguments");
          return;
        }
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Watcher();
          request.setName(args.name);
          request.setTag(args.tag);
          ssClient.deleteWatcher(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "deleteWatcher" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterPlants({ commit }) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "plants");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Plant();
          var stream = svClient.filterPlants(request, metadata);
          var plants = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            plants[o.plantfolder] = o;
          });
          stream.on("end", function () {
            commit("resetPlants", plants);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterPlants" });
            reject();
          });
        });
      });
    },
    filterAudit({ commit }, args) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "audit");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new AuditRange();
          var audit = new Audit();
          if (args) {
            request.setStart(args.start);
            request.setLimit(args.limit);
            audit.setEmail(args.email);
            audit.setFullmethod(args.fullmethod);
          }
          request.setAudit(audit);
          var stream = aaClient.filterAudit(request, metadata);
          var audits = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            audits[o.auditid] = o;
            if (args.more) commit("updateAudit", o);
          });
          stream.on("end", function () {
            if (!args.more) commit("resetAudit", audits);
            else commit("isNotLoading", "audit");
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterAudit" });
            reject();
          });
        });
      });
    },
    fromContext({ commit }) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new User();
          aaClient.fromContext(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "fromContext" });
              reject();
            } else {
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterUsers({ commit }) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "users");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new User();
          var stream = aaClient.filterUsers(request, metadata);
          var users = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            users[o.sub] = o;
          });
          stream.on("end", function () {
            commit("resetUsers", users);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterUsers" });
            reject();
          });
        });
      });
    },
    newUser({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new User();
          var attributes = new Attributes();
          attributes.setAccess(args.attributes.access);
          attributes.setCoolaccess(args.attributes.coolaccess);
          attributes.setAaaaccess(args.attributes.aaaaccess);
          attributes.setApproveaccess(args.attributes.approveaccess);
          attributes.setCertificateaccess(args.attributes.certificateaccess);
          attributes.setNewtaskfullaccess(args.attributes.newtaskfullaccess);
          attributes.setSpacesaccess(args.attributes.spacesaccess);
          attributes.setCertmakeraccess(args.attributes.certmakeraccess);
          attributes.setImagemakeraccess(args.attributes.imagemakeraccess);
          attributes.setDashboardaccess(args.attributes.dashboardaccess);
          request.setAttributes(attributes);
          request.setSub(args.sub);
          request.setEmail(args.email);
          aaClient.newUser(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "newUser" });
              reject();
            } else {
              commit("updateUsers", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    deleteUser({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new User();
          request.setSub(args.sub);
          request.setEmail(args.email);
          aaClient.deleteUser(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "deleteUser" });
              reject();
            } else {
              commit("updateUsers", response.toObject());
              // dispatch('filterUsers');
              resolve(response.toObject());
            }
          });
        });
      });
    },
    updateUserAttributes({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new User();
          var attributes = new Attributes();
          attributes.setAccess(args.attributes.access);
          attributes.setCoolaccess(args.attributes.coolaccess);
          attributes.setAaaaccess(args.attributes.aaaaccess);
          attributes.setApproveaccess(args.attributes.approveaccess);
          attributes.setCertificateaccess(args.attributes.certificateaccess);
          attributes.setNewtaskfullaccess(args.attributes.newtaskfullaccess);
          attributes.setSpacesaccess(args.attributes.spacesaccess);
          attributes.setCertmakeraccess(args.attributes.certmakeraccess);
          attributes.setImagemakeraccess(args.attributes.imagemakeraccess);
          attributes.setDashboardaccess(args.attributes.dashboardaccess);
          request.setAttributes(attributes);
          request.setSub(args.sub);
          request.setEmail(args.email);
          aaClient.updateUserAttributes(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "updateUserAttributes" });
              reject();
            } else {
              commit("updateUsers", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    filterPolicies({ commit }) {
      return new Promise(function (resolve, reject) {
        commit("isLoading", "policies");
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Policy();
          var stream = aaClient.filterPolicies(request, metadata);
          var policies = {};
          stream.on("data", function (response) {
            var o = response.toObject();
            policies[o.policyid] = o;
          });
          stream.on("end", function () {
            commit("resetPolicies", policies);
            resolve();
          });
          stream.on("error", (err) => {
            commit("updateError", { err: err, func: "filterPolicies" });
            reject();
          });
        });
      });
    },
    syncPolicies({ commit, dispatch }) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Policy();
          aaClient.syncPolicies(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "syncPolicies" });
              reject(err);
            } else {
              dispatch("filterPolicies");
              resolve(response.toObject());
            }
          });
        });
      });
    },
    getPolicy({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Policy();
          request.setPolicyid(args.policyid);
          request.setOwner(args.owner);
          aaClient.getPolicy(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "getPolicy" });
              reject();
            } else {
              commit("updatePolicies", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    addPolicy({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Policy();
          request.setPolicyid(args.policyid);
          request.setOwner(args.owner);
          request.setSub(args.sub);
          request.setSubrule(args.subrule);
          request.setObj(args.obj);
          request.setAct(args.act);
          request.setEft(args.eft);
          request.setComment(args.comment);
          aaClient.addPolicy(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "addPolicy" });
              reject();
            } else {
              commit("updatePolicies", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    removePolicy({ commit }, args) {
      return new Promise(function (resolve, reject) {
        auth.getAccessToken().then((accessToken) => {
          var metadata = { Authorization: "Bearer " + accessToken };
          var request = new Policy();
          request.setPolicyid(args.policyid);
          request.setOwner(args.owner);
          aaClient.removePolicy(request, metadata, (err, response) => {
            if (err) {
              commit("updateError", { err: err, func: "removePolicy" });
              reject();
            } else {
              commit("updatePolicies", response.toObject());
              resolve(response.toObject());
            }
          });
        });
      });
    },
    // updateError({commit}, err) {
    //   commit('updateError', err);
    // },
    clearError({ commit }) {
      commit("clearError");
    },
  },
});

export default store;
