// source: request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var task_status_pb = require('./task_status_pb.js');
goog.object.extend(proto, task_status_pb);
var file_transfer_pb = require('./file_transfer_pb.js');
goog.object.extend(proto, file_transfer_pb);
goog.exportSymbol('proto.pb.Filter', null, global);
goog.exportSymbol('proto.pb.Request', null, global);
goog.exportSymbol('proto.pb.RequestRange', null, global);
goog.exportSymbol('proto.pb.Schedule', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Request.repeatedFields_, null);
};
goog.inherits(proto.pb.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Request.displayName = 'proto.pb.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Filter.repeatedFields_, null);
};
goog.inherits(proto.pb.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Filter.displayName = 'proto.pb.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Schedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Schedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Schedule.displayName = 'proto.pb.Schedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RequestRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RequestRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.RequestRange.displayName = 'proto.pb.RequestRange';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Request.repeatedFields_ = [5,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    submitted: jspb.Message.getFieldWithDefault(msg, 3, 0),
    completed: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filterList: jspb.Message.toObjectList(msg.getFilterList(),
    proto.pb.Filter.toObject, includeInstance),
    jobtimeout: jspb.Message.getFieldWithDefault(msg, 6, 0),
    schedule: (f = msg.getSchedule()) && proto.pb.Schedule.toObject(includeInstance, f),
    concurrent: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    recipeid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    command: jspb.Message.getFieldWithDefault(msg, 10, ""),
    runas: jspb.Message.getFieldWithDefault(msg, 11, ""),
    tasks: jspb.Message.getFieldWithDefault(msg, 13, 0),
    owner: jspb.Message.getFieldWithDefault(msg, 14, ""),
    clientid: jspb.Message.getFieldWithDefault(msg, 15, ""),
    args: jspb.Message.getFieldWithDefault(msg, 16, ""),
    modifiedby: jspb.Message.getFieldWithDefault(msg, 17, ""),
    lastmodified: jspb.Message.getFieldWithDefault(msg, 18, 0),
    crosssite: jspb.Message.getFieldWithDefault(msg, 19, ""),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    file_transfer_pb.FileTransfer.toObject, includeInstance),
    sticky: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    remoteaddress: jspb.Message.getFieldWithDefault(msg, 22, ""),
    jobmaxdelay: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Request}
 */
proto.pb.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Request;
  return proto.pb.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Request}
 */
proto.pb.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 2:
      var value = /** @type {!proto.pb.TaskStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubmitted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompleted(value);
      break;
    case 5:
      var value = new proto.pb.Filter;
      reader.readMessage(value,proto.pb.Filter.deserializeBinaryFromReader);
      msg.addFilter(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobtimeout(value);
      break;
    case 7:
      var value = new proto.pb.Schedule;
      reader.readMessage(value,proto.pb.Schedule.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConcurrent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipeid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommand(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunas(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTasks(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setArgs(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedby(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastmodified(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrosssite(value);
      break;
    case 20:
      var value = new file_transfer_pb.FileTransfer;
      reader.readMessage(value,file_transfer_pb.FileTransfer.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSticky(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteaddress(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobmaxdelay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSubmitted();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getJobtimeout();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.pb.Schedule.serializeBinaryToWriter
    );
  }
  f = message.getConcurrent();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRecipeid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCommand();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRunas();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTasks();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getArgs();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getModifiedby();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLastmodified();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getCrosssite();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      file_transfer_pb.FileTransfer.serializeBinaryToWriter
    );
  }
  f = message.getSticky();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getRemoteaddress();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getJobmaxdelay();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
};


/**
 * optional string RequestID = 1;
 * @return {string}
 */
proto.pb.Request.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TaskStatus Status = 2;
 * @return {!proto.pb.TaskStatus}
 */
proto.pb.Request.prototype.getStatus = function() {
  return /** @type {!proto.pb.TaskStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.pb.TaskStatus} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 Submitted = 3;
 * @return {number}
 */
proto.pb.Request.prototype.getSubmitted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setSubmitted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 Completed = 4;
 * @return {number}
 */
proto.pb.Request.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Filter Filter = 5;
 * @return {!Array<!proto.pb.Filter>}
 */
proto.pb.Request.prototype.getFilterList = function() {
  return /** @type{!Array<!proto.pb.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.pb.Filter, 5));
};


/**
 * @param {!Array<!proto.pb.Filter>} value
 * @return {!proto.pb.Request} returns this
*/
proto.pb.Request.prototype.setFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.pb.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Filter}
 */
proto.pb.Request.prototype.addFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pb.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.clearFilterList = function() {
  return this.setFilterList([]);
};


/**
 * optional int32 JobTimeout = 6;
 * @return {number}
 */
proto.pb.Request.prototype.getJobtimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setJobtimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Schedule Schedule = 7;
 * @return {?proto.pb.Schedule}
 */
proto.pb.Request.prototype.getSchedule = function() {
  return /** @type{?proto.pb.Schedule} */ (
    jspb.Message.getWrapperField(this, proto.pb.Schedule, 7));
};


/**
 * @param {?proto.pb.Schedule|undefined} value
 * @return {!proto.pb.Request} returns this
*/
proto.pb.Request.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Request.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool Concurrent = 8;
 * @return {boolean}
 */
proto.pb.Request.prototype.getConcurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setConcurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string RecipeID = 9;
 * @return {string}
 */
proto.pb.Request.prototype.getRecipeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setRecipeid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string Command = 10;
 * @return {string}
 */
proto.pb.Request.prototype.getCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string RunAs = 11;
 * @return {string}
 */
proto.pb.Request.prototype.getRunas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setRunas = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 Tasks = 13;
 * @return {number}
 */
proto.pb.Request.prototype.getTasks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setTasks = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string Owner = 14;
 * @return {string}
 */
proto.pb.Request.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ClientID = 15;
 * @return {string}
 */
proto.pb.Request.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Args = 16;
 * @return {string}
 */
proto.pb.Request.prototype.getArgs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setArgs = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string ModifiedBy = 17;
 * @return {string}
 */
proto.pb.Request.prototype.getModifiedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setModifiedby = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 LastModified = 18;
 * @return {number}
 */
proto.pb.Request.prototype.getLastmodified = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setLastmodified = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string CrossSite = 19;
 * @return {string}
 */
proto.pb.Request.prototype.getCrosssite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setCrosssite = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated FileTransfer Files = 20;
 * @return {!Array<!proto.pb.FileTransfer>}
 */
proto.pb.Request.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.pb.FileTransfer>} */ (
    jspb.Message.getRepeatedWrapperField(this, file_transfer_pb.FileTransfer, 20));
};


/**
 * @param {!Array<!proto.pb.FileTransfer>} value
 * @return {!proto.pb.Request} returns this
*/
proto.pb.Request.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.pb.FileTransfer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.FileTransfer}
 */
proto.pb.Request.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.pb.FileTransfer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


/**
 * optional bool Sticky = 21;
 * @return {boolean}
 */
proto.pb.Request.prototype.getSticky = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setSticky = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string RemoteAddress = 22;
 * @return {string}
 */
proto.pb.Request.prototype.getRemoteaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setRemoteaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional int32 JobMaxDelay = 23;
 * @return {number}
 */
proto.pb.Request.prototype.getJobmaxdelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Request} returns this
 */
proto.pb.Request.prototype.setJobmaxdelay = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Filter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    patternList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    operator: jspb.Message.getFieldWithDefault(msg, 3, ""),
    multioperator: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Filter}
 */
proto.pb.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Filter;
  return proto.pb.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Filter}
 */
proto.pb.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPattern(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperator(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMultioperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPatternList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getOperator();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMultioperator();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string Field = 1;
 * @return {string}
 */
proto.pb.Filter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Filter} returns this
 */
proto.pb.Filter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string Pattern = 2;
 * @return {!Array<string>}
 */
proto.pb.Filter.prototype.getPatternList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.pb.Filter} returns this
 */
proto.pb.Filter.prototype.setPatternList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.Filter} returns this
 */
proto.pb.Filter.prototype.addPattern = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.Filter} returns this
 */
proto.pb.Filter.prototype.clearPatternList = function() {
  return this.setPatternList([]);
};


/**
 * optional string Operator = 3;
 * @return {string}
 */
proto.pb.Filter.prototype.getOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Filter} returns this
 */
proto.pb.Filter.prototype.setOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string MultiOperator = 4;
 * @return {string}
 */
proto.pb.Filter.prototype.getMultioperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Filter} returns this
 */
proto.pb.Filter.prototype.setMultioperator = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Schedule.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Schedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Schedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Schedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    expiration: jspb.Message.getFieldWithDefault(msg, 2, 0),
    interval: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sticky: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    future: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Schedule}
 */
proto.pb.Schedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Schedule;
  return proto.pb.Schedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Schedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Schedule}
 */
proto.pb.Schedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSticky(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFuture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Schedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Schedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Schedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Schedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getExpiration();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSticky();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFuture();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 Start = 1;
 * @return {number}
 */
proto.pb.Schedule.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Schedule} returns this
 */
proto.pb.Schedule.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 Expiration = 2;
 * @return {number}
 */
proto.pb.Schedule.prototype.getExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Schedule} returns this
 */
proto.pb.Schedule.prototype.setExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 Interval = 3;
 * @return {number}
 */
proto.pb.Schedule.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Schedule} returns this
 */
proto.pb.Schedule.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool Sticky = 4;
 * @return {boolean}
 */
proto.pb.Schedule.prototype.getSticky = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.Schedule} returns this
 */
proto.pb.Schedule.prototype.setSticky = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool Future = 5;
 * @return {boolean}
 */
proto.pb.Schedule.prototype.getFuture = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.pb.Schedule} returns this
 */
proto.pb.Schedule.prototype.setFuture = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.RequestRange.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.RequestRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.RequestRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RequestRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.pb.Request.toObject(includeInstance, f),
    start: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    agentid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RequestRange}
 */
proto.pb.RequestRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.RequestRange;
  return proto.pb.RequestRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RequestRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RequestRange}
 */
proto.pb.RequestRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pb.Request;
      reader.readMessage(value,proto.pb.Request.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RequestRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.RequestRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RequestRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RequestRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pb.Request.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Request Request = 1;
 * @return {?proto.pb.Request}
 */
proto.pb.RequestRange.prototype.getRequest = function() {
  return /** @type{?proto.pb.Request} */ (
    jspb.Message.getWrapperField(this, proto.pb.Request, 1));
};


/**
 * @param {?proto.pb.Request|undefined} value
 * @return {!proto.pb.RequestRange} returns this
*/
proto.pb.RequestRange.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.RequestRange} returns this
 */
proto.pb.RequestRange.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.RequestRange.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 Start = 2;
 * @return {number}
 */
proto.pb.RequestRange.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.RequestRange} returns this
 */
proto.pb.RequestRange.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 Limit = 3;
 * @return {number}
 */
proto.pb.RequestRange.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.RequestRange} returns this
 */
proto.pb.RequestRange.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string AgentID = 4;
 * @return {string}
 */
proto.pb.RequestRange.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.RequestRange} returns this
 */
proto.pb.RequestRange.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.pb);
