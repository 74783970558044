<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Settings</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small text color="primary" @click.prevent="refreshSettings()">
                <v-icon dark left>refresh</v-icon>
                Refresh
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small text color="primary" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-card>
                      <v-card-actions>
                        <v-list dense>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportConfig()">
                              <v-icon dark left>get_app</v-icon>
                              Export
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex m12>
          <v-card>
            <v-card-text>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Message</td>
                    <td>{{ message }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Message'; xvalue = message; xdialog = true" :disabled="!hasAaaAccess()">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                      <v-btn x-small text @click.prevent="xkey = 'Message'; xvalue = null; callUpdateNotification();" :disabled="!hasAaaAccess()">
                        <v-icon small>clear</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Warning</td>
                    <td>{{ warning }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Warning'; xvalue = warning; xdialog = true" :disabled="!hasAaaAccess()">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                      <v-btn x-small text @click.prevent="xkey = 'Warning'; xvalue = null; callUpdateNotification();" :disabled="!hasAaaAccess()">
                        <v-icon small>clear</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-flex>

      </v-layout>
    </v-container>

    <v-dialog
      v-model="xdialog"
      width="500"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-text-field
            v-model="xvalue"
            :label="xkey"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="xdialog = false;">Close</v-btn>
          <v-btn color="primary" text @click.prevent="callUpdateNotification(); xdialog = false;">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import libvue from '../lib/vue.js'

export default {
  mixins: [libvue],
  data() {
    return {
      xdialog: false,
      xkey: null,
      xvalue: null,
      dialog: false,
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  computed: {
    message: {
      get() {
        if (this.allConfig && this.allConfig.notification) {
          return this.allConfig.notification.message;
        }
        return "";
      },
    },
    warning: {
      get() {
        if (this.allConfig && this.allConfig.notification) {
          return this.allConfig.notification.warning;
        }
        return "";
      },
    },
    ...mapGetters(['allConfig', 'getUserById', 'isLoading'])
  },
  mounted() {
    this.refreshSettings();
  },
  methods: {
    refreshSettings() {
      return this.getConfig().then(() => {}).catch(() => {});
    },
    callUpdateNotification() {
      this.dialog = true;
      var args = {
        message: this.message,
        warning: this.warning
      };
      switch (this.xkey) {
        case 'Message':
          args.message = this.xvalue;
          break;
        case 'Warning':
          args.warning = this.xvalue;
          break;
      }
      this.setNotification(args).then(() => {
          this.dialog = false;
          this.snack = true;
          this.snackColor = 'success';
          this.snackMessage = 'Message updated';
      }).catch(() => { this.dialog = false; });
    },
    hasAaaAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.aaaaccess;
      }
      return false;
    },
    exportConfig() {
      var dataStr = JSON.stringify(this.allConfig, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'coolConfig.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['getConfig', 'setNotification'])
  }
}
</script>
