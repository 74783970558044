
<template>
  <div>   
    <v-container grid-list-md fluid>
      <v-layout align-center justify-space-around wrap>
        <v-flex md6>
          <v-card>
            <v-layout>
              <v-flex xs5>
                <v-img max-height="100px" max-width="100px" contain :src="require(`../assets/walrus.jpg`)"></v-img>
              </v-flex>
              <v-flex xs7>
                <v-card-title primary-title>
                  <div>
                    <v-btn @click.prevent="login"><v-icon>exit_to_app</v-icon>Login</v-btn>
                  </div>
                </v-card-title>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  async created() {
    try {
      await this.$auth.renewTokens();
    } catch (e) {
    //  console.log(e);
    }
  },
  methods: {
    login() {
      this.$auth.login();
    },
    logout() {
      this.$auth.logOut();
    },
    handleLoginEvent(data) {
      this.isAuthenticated = data.loggedIn;
      this.profile = data.profile;
    },
  }
};
</script>
