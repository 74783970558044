<template>
  <div>
    <v-container grid-list-md fluid>      
      <v-layout align-center justify-space-around wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Tasks</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <NewTask ref="NewTask" />
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small text color="primary" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-card>
                      <v-card-actions>
                        <v-list dense>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportTasksCSV()">
                              <v-icon dark left>get_app</v-icon>
                              Export (CSV)
                            </v-btn>
                          </v-list-item>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportTasks()">
                              <v-icon dark left>get_app</v-icon>
                              Export (JSON)
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-title>

                <v-layout wrap md12>
                  <v-flex md6>
                    <v-checkbox
                      v-model="advancedsearch"
                      label="Advanced Search"
                      persistent-hint
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Quick Search"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-flex>

                  <v-layout wrap v-if="advancedsearch">
                    <v-flex md3>
                      <v-menu
                        ref="startdatemenu"
                        v-model="startdatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="datestart"
                            label="Start date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datestart"
                          no-title
                          @input="startdatemenu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex md3>
                      <v-menu
                        ref="starttimemenu"
                        v-model="starttimemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="timestart"
                            label="Start time"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="timestart"
                          format="24hr"
                          scrollable
                        >
                        </v-time-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex md3>
                      <v-menu
                        ref="enddatemenu"
                        v-model="enddatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="dateend"
                            label="End date"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateend"
                          no-title
                          @input="enddatemenu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex md3>
                      <v-menu
                        ref="endtimemenu"
                        v-model="endtimemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="timeend"
                            label="End time"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="timeend"
                          format="24hr"
                          scrollable
                        >
                        </v-time-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        v-model="email"
                        prepend-icon="email"
                        :items="getEmails()"
                        label="Email"
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        v-model="agentid"
                        prepend-icon="waves"
                        :items="getAgents()"
                        label="AgentID"
                        clearable
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md2>
                      <v-btn text color="primary" @click.prevent="getSearch()">
                        <v-icon dark left>search</v-icon>Search</v-btn>
                    </v-flex>

                  </v-layout>
                </v-layout>
              </v-card-title>

            <!-- <v-card-actions>
              <v-row justify="space-around">
                <v-checkbox v-model="showmine" label="My Requests"></v-checkbox>
                <v-checkbox v-model="showrecipe" label="With Recipe"></v-checkbox>
                <v-checkbox v-model="showschedule" label="With Schedule"></v-checkbox>
                <v-checkbox v-model="showfile" label="With File"></v-checkbox>
              </v-row>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn text color="primary" v-on="on" @click.prevent="clearFilter()"><v-icon>clear_all</v-icon></v-btn>
                </template>
              <span>Clear All</span>
              </v-tooltip>
            </v-card-actions> -->
          </v-card>
        </v-flex>

        <v-flex md12>
          <v-tabs slot="extension" v-model="tabs">
            <v-tab :key="0">Aggregated</v-tab>
            <v-tab :key="1">Requests</v-tab>
            <v-tab :key="2">Tasks</v-tab>
            <v-tab :key="3">Jobs</v-tab>
            <v-tab :key="4">File Transfers</v-tab>
          </v-tabs>

          <v-tabs-items style="width:100%" v-model="tabs">

          <v-tab-item :key="0">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="aggregatedHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="aggregatedList"
                :loading="loadingAggregated"
                :search="search"
                :sort-by="'submitted'"
                :sort-desc="true"
                :page.sync="pageAggregated"
                @page-count="pageCountAgregated = $event"
                item-key="skey"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.skey">
                      <td><v-icon small>assignment</v-icon></td>
                      <td @click.stop="selectRequest(item.requestid)" class="underline" :style="{cursor:'pointer'}">{{ smallId(item.requestid) }}</td>
                      <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                      <!-- <td>{{ smallId(item.clientid) }}</td> -->
                      <td>{{ item.owner }}</td>
                      <td>{{ dateToString(item.submitted) }}</td>
                      <td>{{ dateToString(item.completed) }}</td>
                      <td>{{ smallStr(item.command, 20) }}</td>
                      <td v-if='(item.jobid || item.filetransferid) && item.status == jobStatus().STARTING'><v-icon small color="info">hourglass_full</v-icon>STARTING</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().STARTED'><v-icon small color="info">hourglass_full</v-icon>STARTED</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().DONE'><v-icon small color="success">done</v-icon>DONE</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().FAILED'><v-icon small color="error">error</v-icon>FAILED</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().TIMEOUT'><v-icon small color="error">hourglass_empty</v-icon>TIMEOUT</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().NOTFOUND'><v-icon small color="error">error</v-icon>NOTFOUND</td>
                      <td v-else-if='(item.jobid || item.filetransferid) && item.status == jobStatus().NOREPLY'><v-icon small color="warning">warning</v-icon>NOREPLY</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                      <td v-else-if='item.taskid && item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                      <td v-else-if='item.requestid && item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                      <td v-else><v-icon small color="error">error</v-icon>UNKNOWN</td>
                      <td>{{ item.exitstatus }}</td>
                      <td>
                        <v-btn color="primary" x-small @click.prevent="displayCombinedOutput(item)" :style="{cursor:'pointer'}" v-if="item.combinedoutput">Show</v-btn>
                        <v-btn color="error" x-small @click.prevent="displayErrorOutput(item)" :style="{cursor:'pointer'}" v-else-if="item.error">Show</v-btn>
                        <v-btn color="info" x-small @click.prevent="displayFileTransfer(item)" v-else-if="item.fileobjectid && item.fileobjectid != ''">Show</v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageAggregated == pageCountAgregated || pageCountAgregated == 0">
                    <v-btn small text color="primary" @click.prevent="getEverythingMore()" :disabled="bce(start)" v-if="!loadingAggregated">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="1">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="requestsHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="Object.values(allRequests)"
                :loading="isLoading.requests"
                :search="search"
                :sort-by="'submitted'"
                :sort-desc="true"
                :page.sync="pageRequest"
                @page-count="pageCountRequest = $event"
                item-key="requestid"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.requestid" @click.stop="selectRequest(item.requestid)" :style="{cursor:'pointer'}">
                      <td><v-icon small>assignment</v-icon></td>
                      <td @click.stop="selectRequest(item.requestid)" class="underline" :style="{cursor:'pointer'}">{{ smallId(item.requestid) }}</td>
                      <td>{{ item.owner }}</td>
                      <td>{{ dateToString(item.submitted) }}</td>
                      <td>{{ dateToString(item.completed) }}</td>
                      <td>{{ smallStr(recipeOrCommand(item), 20) }}</td>
                      <td v-if='item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                      <td v-else-if='item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                      <td v-else-if='item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                      <td v-else-if='item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                      <td v-else-if='item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                      <td v-else-if='item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                      <td v-else><v-icon small color="error">error</v-icon>UNKNOWN</td>
                      <td>{{ hasSchedule(item) }}</td>
                      <td>{{ item.filesList.length }}</td>
                      <td>{{ item.tasks }}</td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageRequest == pageCountRequest || pageCountRequest == 0">
                    <v-btn small text color="primary" @click.prevent="getEverythingMore()" :disabled="bce(start)" v-if="!isLoading.requests">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="tasksHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="Object.values(allTasks)"
                :loading="isLoading.tasks"
                :search="search"
                :sort-by="'submitted'"
                :sort-desc="true"
                :page.sync="pageTask"
                @page-count="pageCountTask = $event"
                item-key="taskid"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.taskid">
                      <td><v-icon small>assignment</v-icon></td>
                      <td @click.stop="selectRequest(idFromKey(item.requestkey))" class="underline" :style="{cursor:'pointer'}">{{ smallKey(item.requestkey) }}</td>
                      <td>{{ smallId(item.taskid) }}</td>
                      <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                      <td>{{ dateToString(item.submitted) }}</td>
                      <td>{{ dateToString(item.completed) }}</td>
                      <td>{{ dateToString(item.lastrun) }}</td>
                      <td v-if='item.status == taskStatus().PENDING'><v-icon small color="info">hourglass_full</v-icon>PENDING</td>
                      <td v-else-if='item.status == taskStatus().PREPARING'><v-icon small color="info">hourglass_full</v-icon>PREPARING</td>
                      <td v-else-if='item.status == taskStatus().RUNNING'><v-icon small color="info">hourglass_full</v-icon>RUNNING</td>
                      <td v-else-if='item.status == taskStatus().COMPLETED'><v-icon small color="success">done</v-icon>COMPLETED</td>
                      <td v-else-if='item.status == taskStatus().CANCELLED'><v-icon small color="warning">warning</v-icon>CANCELLED</td>
                      <td v-else-if='item.status == taskStatus().EXPIRED'><v-icon small color="error">hourglass_empty</v-icon>EXPIRED</td>
                      <td v-else><v-icon small color="error">error</v-icon> {{ taskStatusToString(item.status) }}</td>
                      <td>{{ item.jobs }}</td>
                      <td>{{ item.filetransfers }}</td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageTask == pageCountTask || pageCountTask == 0">
                    <v-btn small text color="primary" @click.prevent="getEverythingMore()" :disabled="bce(start)" v-if="!isLoading.tasks">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="3">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>
            
              <v-data-table
                :headers="jobsHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="Object.values(allJobs)"
                :loading="isLoading.jobs"
                :search="search"
                :sort-by="'submitted'"
                :sort-desc="true"
                :page.sync="pageJob"
                @page-count="pageCountJob = $event"
                item-key="jobid"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.jobid">
                      <td><v-icon small>assignment</v-icon></td>
                      <!-- <td>{{ smallKey(item.taskkey) }}</td> -->
                      <td @click.stop="selectRequest(idFromKey(item.requestkey))" class="underline" :style="{cursor:'pointer'}">{{ smallKey(item.requestkey) }}</td>
                      <td>{{ smallId(item.jobid) }}</td>
                      <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                      <td>{{ smallId(item.clientid) }}</td>
                      <td>{{ dateToString(item.submitted) }}</td>
                      <td>{{ dateToString(item.completed) }}</td>
                      <!-- <td>{{ dateToString(item.received) }}</td> -->
                      <td v-if='item.status == jobStatus().STARTING'><v-icon small color="info">hourglass_full</v-icon>STARTING</td>
                      <td v-else-if='item.status == jobStatus().STARTED'><v-icon small color="info">hourglass_full</v-icon>STARTED</td>
                      <td v-else-if='item.status == jobStatus().DONE'><v-icon small color="success">done</v-icon>DONE</td>
                      <td v-else-if='item.status == jobStatus().FAILED'><v-icon small color="error">error</v-icon>FAILED</td>
                      <td v-else-if='item.status == jobStatus().TIMEOUT'><v-icon small color="error">hourglass_empty</v-icon>TIMEOUT</td>
                      <td v-else-if='item.status == jobStatus().NOTFOUND'><v-icon small color="error">error</v-icon>NOTFOUND</td>
                      <td v-else-if='item.status == jobStatus().NOREPLY'><v-icon small color="warning">warning</v-icon>NOREPLY</td>
                      <td v-else>{{ jobStatusToString(item.status) }}</td>
                      <td>{{ item.exitstatus }}</td>
                      <td><v-btn color="primary" x-small @click.prevent="displayCombinedOutput(item)" :style="{cursor:'pointer'}" v-if="item.combinedoutput">Show</v-btn></td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageJob == pageCountJob || pageCountJob == 0">
                    <v-btn small text color="primary" @click.prevent="getEverythingMore()" :disabled="bce(start)" v-if="!isLoading.jobs">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="4">
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-card-title>
            
              <v-data-table
                :headers="filetransferHeaders"
                :footer-props="{
                  'items-per-page-options': itemsPerPage
                }"
                :items="Object.values(allFileTransfers)"
                :loading="isLoading.filetransfers"
                :search="search"
                :sort-by="'submitted'"
                :sort-desc="true"
                :page.sync="pageFileTransfer"
                @page-count="pageCountFileTransfer = $event"
                item-key="filetransferid"
                class="elevation-1"
                dense
                @update:options="updatePagination"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.filetransferid">
                      <td><v-icon small>assignment</v-icon></td>
                      <td @click.stop="selectRequest(idFromKey(item.requestkey))" class="underline" :style="{cursor:'pointer'}">{{ smallKey(item.requestkey) }}</td>
                      <td>{{ smallId(item.filetransferid) }}</td>
                      <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                      <td>{{ smallId(item.clientid) }}</td>
                      <td>{{ item.filename }}</td>
                      <td>{{ dateToString(item.submitted) }}</td>
                      <td>{{ dateToString(item.completed) }}</td>
                      <td v-if='item.status == jobStatus().STARTING'><v-icon small color="info">hourglass_full</v-icon>STARTING</td>
                      <td v-else-if='item.status == jobStatus().STARTED'><v-icon small color="info">hourglass_full</v-icon>STARTED</td>
                      <td v-else-if='item.status == jobStatus().DONE'><v-icon small color="success">done</v-icon>DONE</td>
                      <td v-else-if='item.status == jobStatus().FAILED'><v-icon small color="error">error</v-icon>FAILED</td>
                      <td v-else-if='item.status == jobStatus().TIMEOUT'><v-icon small color="error">hourglass_empty</v-icon>TIMEOUT</td>
                      <td v-else-if='item.status == jobStatus().NOTFOUND'><v-icon small color="error">error</v-icon>NOTFOUND</td>
                      <td v-else-if='item.status == jobStatus().NOREPLY'><v-icon small color="warning">warning</v-icon>NOREPLY</td>
                      <td v-else>{{ jobStatusToString(item.status) }}</td>
                      <td>{{ item.error }}</td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:footer>
                  <div align="center" v-if="pageFileTransfer == pageCountFileTransfer || pageCountFileTransfer == 0">
                    <v-btn small text color="primary" @click.prevent="getEverythingMore()" :disabled="bce(start)" v-if="!isLoading.filetransfers">
                      Load More
                    </v-btn>
                    <v-btn small text color="primary" :disabled="true" v-else>
                      Loading...
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          </v-tabs-items>

        </v-flex>
      </v-layout>

      <RequestDetails
        v-model="showRequest"
        :_request="request"
        :_aggregatedList="aggregatedList"
        v-on:search="search=$event"
      />

      <v-dialog
        v-model="showCombinedOutput"
        hide-overlay
        width="800"
      >
        <v-card>
          <v-card-title>Output on {{ hostid }}</v-card-title>
          <v-card-text>
            <pre>{{ combinedOutput }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showCombinedOutput = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snack"
        :color="snackColor"
        :timeout="snackTimeout"
        :top="true"
      >
        {{ snackMessage }}
        <v-btn
          text
          @click="snack = false"
        >
          <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
          Close
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import libvue from '../lib/vue.js'
import config from '../../config/config.json';
import RequestDetails from '../components/RequestDetails.vue'
import NewTask from '../components/NewTask.vue'

export default {
  mixins: [libvue],
  data() {
    return {
      tabs: 0,
      pageAggregated: 1,
      pageCountAgregated: 0,
      pageRequest: 1,
      pageCountRequest: 0,
      pageTask: 1,
      pageCountTask: 0,
      pageJob: 1,
      pageCountJob: 0,
      pageFileTransfer: 1,
      pageCountFileTransfer: 0,
      start: 0,
      limit: 0,
      advancedsearch: false,
      email: null,
      agentid: null,
      startdatemenu: false,
      starttimemenu: false,
      datestart: new Date(new Date().getTime()-config.historyMs).toISOString().substr(0, 10),
      timestart: ('0'+new Date(new Date().getTime()).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      enddatemenu: false,
      endtimemenu: false,
      dateend: new Date(new Date().getTime()).toISOString().substr(0, 10),
      timeend: ('0'+new Date().getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      showCombinedOutput: false,
      combinedOutput: null,
      hostid: null,
      showmine: false,
      showrecipe: false,
      showschedule: false,
      showfile: false,
      search: null,
      aggregatedHeaders: [
        { text: '', sortable: false, value: '' },
        { text: 'RequestID', sortable: true, value: 'requestid' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        // { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Owner', sortable: true, value: 'owner' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Completed', sortable: true, value: 'completed' },
        { text: 'Recipe/Command', sortable: true, value: 'command' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Exit Status', sortable: true, value: 'exitstatus' },
        { text: 'Output', sortable: false, value: 'combinedoutput' }
      ],
      requestsHeaders: [
        { text: '', sortable: false, value: '' },
        { text: 'RequestID', sortable: true, value: 'requestid' },
        { text: 'Owner', sortable: true, value: 'owner' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Completed', sortable: true, value: 'completed' },
        { text: 'Recipe/Command', sortable: true, value: 'recipeid' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Schedule', sortable: true, value: 'schedule' },
        { text: 'Files', sortable: true, value: 'filesList' },
        { text: 'Tasks', sortable: true, value: 'tasks' }
      ],
      tasksHeaders: [
        { text: '', sortable: false, value: '' },
        { text: 'RequestID', sortable: true, value: 'requestkey' },
        { text: 'TaskID', sortable: true, value: 'taskid' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Completed', sortable: true, value: 'completed' },
        { text: 'LastRun', sortable: true, value: 'lastrun' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Jobs', sortable: true, value: 'jobs' },
        { text: 'FileTransfers', sortable: true, value: 'filetransfers' },
      ],
      jobsHeaders: [
        { text: '', sortable: false, value: '' },
        // { text: 'TaskID', sortable: true, value: 'taskkey' },
        { text: 'RequestID', sortable: true, value: 'requestkey' },
        { text: 'JobID', sortable: true, value: 'jobid' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Completed', sortable: true, value: 'completed' },
        // { text: 'Received', sortable: true, value: 'received' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Exit Status', sortable: true, value: 'exitstatus' },
        { text: 'Output', sortable: false, value: 'combinedoutput' }
      ],
      filetransferHeaders: [
        { text: '', sortable: false, value: '' },
        // { text: 'TaskID', sortable: true, value: 'taskkey' },
        { text: 'RequestID', sortable: true, value: 'requestkey' },
        { text: 'FileTransferID', sortable: true, value: 'filetransferid' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Filename', sortable: true, value: 'filename' },
        { text: 'Submitted', sortable: true, value: 'submitted' },
        { text: 'Completed', sortable: true, value: 'completed' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Error', sortable: false, value: 'error' }
      ],
      showRequest: false,
      request: null,
      snack: false,
      snackColor: 'primary',
      snackMessage: '',
      snackTimeout: 5000
    }
  },
  props: {
    _requestid: String
  },
  mounted() {
    this.datestart = new Date(new Date().getTime()-config.historyMs).toISOString().substr(0, 10);
    this.timestart = ('0'+new Date(new Date().getTime()).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2);
    this.dateend = new Date(new Date().getTime()).toISOString().substr(0, 10);
    this.timeend = ('0'+new Date().getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2);
    this.start = Date.parse(this.datestart + 'T' + this.timestart + 'Z') * 1e6;
    this.limit = Date.parse(this.dateend + 'T' + this.timeend + 'Z') * 1e6 + 60 * 1e9;
    this.watchRequests();
    this.watchTasks();
    this.watchJobs();
    this.watchFileTransfers();
    this.getEverything().then(() => {}).catch(() => {});
    this.filterUsers().then(() => {}).catch(() => {});
  },
  beforeDestroy() {
    this.cancelWatchFileTransfers();
    this.cancelWatchJobs();
    this.cancelWatchTasks();
    this.cancelWatchRequests();
  },
  watch: {
    advancedsearch: function(v) {
      if (!v) {
        this.watchRequests();
        this.watchTasks();
        this.watchJobs();
        this.watchFileTransfers();
        this.getEverything().then(() => {}).catch(() => {});
      } else {
        this.cancelWatchFileTransfers();
        this.cancelWatchJobs();
        this.cancelWatchTasks();
        this.cancelWatchRequests();
      }
    }
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    loadingAggregated: {
      get() {
        return this.isLoading.requests || this.isLoading.tasks || this.isLoading.jobs || this.isLoading.filetransfers;
      }
    },
    aggregatedList: {
      get() {
        var s = {};
        // Jobs
        if (this.allJobs) {
          Object.values(this.allJobs).forEach((j) => {
            var o = Object.assign({}, j);
            var r = this.allRequests[this.idFromKey(j.requestkey)];
            if (r) {
              o.owner = r.owner;
              o.command = this.recipeOrCommand(r);
            } else {
              // TODO: getRequest
            }
            o.requestid = this.idFromKey(o.requestkey);
            o.skey = this.idFromKey(o.requestkey)+o.jobid;
            s[o.skey] = o;
          });
        }
        // FileTransfers
        if (this.allFileTransfers) {
          Object.values(this.allFileTransfers).forEach((ft) => {
            var o = Object.assign({}, ft);
            var r = this.allRequests[this.idFromKey(ft.requestkey)];
            if (r) {
              o.owner = r.owner;
              o.command = this.recipeOrCommand(r);
            } else {
              // TODO: getRequest
            }
            o.requestid = this.idFromKey(o.requestkey);
            o.skey = this.idFromKey(o.requestkey)+o.filetransferid;
            s[o.skey] = o;
          });
        }
        // Tasks without Jobs and FileTrasfers (yet or not)
        if (this.allTasks) {
          Object.values(this.allTasks).forEach((t) => {
            if (t.jobs == 0 && t.filetransfers == 0) {
              var o = Object.assign({}, t);
              var r = this.allRequests[this.idFromKey(t.requestkey)];
              if (r) {
                o.owner = r.owner;
                o.command = this.recipeOrCommand(r);
              } else {
              // TODO: getRequest
            }
              o.requestid = this.idFromKey(o.requestkey);
              o.skey = this.idFromKey(o.requestkey)+o.taskid;
              s[o.skey] = o;
            }
          });
        }
        // Request without Tasks (yet or not - remove on non match)
        if (this.allRequests && !(this.advancedsearch && this.agentid)) {
          Object.values(this.allRequests).forEach((r) => {
            if (r.tasks == 0) {
              var o = Object.assign({}, r);
              o.skey = r.requestid;
              s[o.skey] = o;
            }
          });
        }
        return Object.values(s);
      }
    },
    ...mapGetters(['allRequests', 'allTasks', 'allJobs', 'allFileTransfers', 'allRecipes', 'allUsers', 'allAgents', 'userConfig', 'isLoading'])
  },
  components: { RequestDetails, NewTask },
  methods: {
    getSearch() {
      this.start = Date.parse(this.datestart + 'T' + this.timestart + 'Z') * 1e6;
      this.limit = Date.parse(this.dateend + 'T' + this.timeend + 'Z') * 1e6 + 60 * 1e9;
      this.getEverything().then(() => {}).catch(() => {});
    },
    getEverything(more) {
      return new Promise((resolve, reject) => {
        this.getRequests(more).then(() => {
          this.getTasks(more).then(() => {
            this.getJobs(more).then(() => {
              this.getFileTransfers(more).then(() => {
                resolve();
              }).catch((err) => {
                reject({code: err.code, message: err.message});
              });
            }).catch((err) => {
              reject({code: err.code, message: err.message});
            });
          }).catch((err) => {
            reject({code: err.code, message: err.message});
          });
        }).catch((err) => {
          reject({code: err.code, message: err.message});
        });
      });
    },
    getEverythingMore(warp) {
      if (!warp) warp = 1;
      var n = Object.keys(this.allRequests).length;
      this.limit = this.start;
      this.start = this.start - config.historyMs * 1e6 * warp;
      this.datestart = new Date(this.start/1e6).toISOString().substr(0, 10);
      this.timestart = ('0'+new Date(this.start/1e6).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2);
      this.getEverything(true).then(() => {
        if (Object.keys(this.allRequests).length === n) {
          if (this.start < config.beginningMs * 1e6) return; // before 20200101
          this.getEverythingMore(warp + 1); // incremental
        }
      }).catch(() => {});
    },
    getRequests(more) {
      var args = {start: this.start, limit: this.limit, more: more};
      if (this.advancedsearch) {
        args.agentid = this.agentid;
        args.owner = this.email;
      }
      return this.filterRequests(args);
    },
    getTasks(more) {
      var args = {start: this.start, limit: this.limit, more: more};
      if (this.advancedsearch) {
        args.agentid = this.agentid;
        args.owner = this.email;
      }
      return this.filterTasks(args);
    },
    getJobs(more) {
      var args = {start: this.start, limit: this.limit, more: more};
      if (this.advancedsearch) {
        args.agentid = this.agentid;
        args.owner = this.email;
      }
      return this.filterJobs(args);
    },
    getFileTransfers(more) {
      var args = {start: this.start, limit: this.limit, more: more};
      if (this.advancedsearch) {
        args.agentid = this.agentid;
        args.owner = this.email;
      }
      return this.filterFileTransfers(args);
    },
    getEmails() {
      var emails = [];
      if (this.allUsers) {
        Object.values(this.allUsers).forEach((u) => {
          emails.push(u.email);
        });
      }
      return emails.sort();
    },
    getAgents() {
      var agents = [];
      if (this.allAgents) {
        Object.values(this.allAgents).forEach((a) => {
          agents.push(a.agentid);
        });
      }
      return agents;
    },
    selectRequest(requestid) {
      this.request = this.allRequests[requestid];
      this.showRequest = true;
      // this.$router.push({ path: `/requests/${requestid}`});
    },
    openAgentsPage() {
      this.$router.push({ name: 'agents-page' });
    },
    openAgentDetailsPage(agentid) {
      this.$router.push({ name: 'agent-details', params: { AgentID: agentid } });
    },
    clearFilter() {
      this.showmine = false;
      this.showrecipe = false;
      this.showschedule = false;
      this.showfile = false;
    },
    recipeOrCommand(request) {
      if (this.allRecipes[request.recipeid]) {
        return this.allRecipes[request.recipeid].name;
      }
      if (request.filesList && request.filesList.length > 0) {
        return request.filesList[0].filename;
      }
      return request.command;
    },
    displayCombinedOutput(v) {
      this.combinedOutput = this.decodeCombinedOutput(v.combinedoutput);
      if (v.hostid) {
        this.hostid = v.hostid + " (Job)";
      } else {
        this.hostid = v.clientid + " (Job)";
      }
      this.showCombinedOutput = true
    },
    displayErrorOutput(v) {
      this.combinedOutput = v.error;
      if (v.hostid) {
        this.hostid = v.hostid + " (File Transfer)";
      } else {
        this.hostid = v.clientid + " (File Transfer)";
      }
      this.showCombinedOutput = true;
    },
    displayFileTransfer(v) {
      this.combinedOutput = v.filename + " (" + v.fileperms.user + ":" + v.fileperms.group + " " + v.fileperms.mode + ")";
      if (v.hostid) {
        this.hostid = v.hostid + " (File Transfer)";
      } else {
        this.hostid = v.clientid + " (File Transfer)";
      }
      this.showCombinedOutput = true;
    },
    hasSchedule(v) {
      if (v.schedule && v.schedule.start > 0) {
        return "Yes";
      }
      return "No";
    },
    hasFile(v) {
      if (v.filesList && v.filesList.length > 0) {
        return "Yes";
      }
      return "No";
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportTasks() {
      var myRequests = [];
      Object.values(this.allTasks).forEach((t) => {
        var request = Object.assign({}, this.allRequests[t.requestid]);
        request.status = this.taskStatusToString(request.status);
        myRequests.push(request);
      });
      var allData = [];
      var parent = this;
      Object.values(this.allRequests).forEach((r) => {
        var data = Object.assign({}, r);
        data["tasks"] = (function() {
          var tasks = [];
          var grandpa = parent;
          Object.values(parent.allTasks).forEach((t) => {
            if (parent.idFromKey(t.requestkey) == r.requestid) {
              var task = Object.assign({}, t);
              task.status = parent.taskStatusToString(task.status);
              task["jobs"] = (function() {
                var jobs = [];
                Object.values(grandpa.allJobs).forEach((j) => {
                  if (grandpa.idFromKey(j.taskkey) == t.taskid) {
                    var job = Object.assign({}, j);
                    job.status = grandpa.jobStatusToString(job.status);
                    jobs.push(job);
                  }
                });
                return jobs;
              })();
              tasks.push(task);
            }
          });
          return tasks;
        })();
        allData.push(data);
      });
      var dataStr = JSON.stringify(allData, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'tasks.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    exportTasksCSV() {
      var parent = this;
      var dataStr = "RequestID,AgentID,Owner,Submitted,Completed,Recipe,Command,Status,ExitStatus,Output\n";
      this.aggregatedList.forEach((a) => {
        var r = parent.allRequests[a.requestid];
        var data = a.requestid + "," + a.agentid + "," + a.owner + ","
                  + parent.dateToStringEmpty(a.submitted) + "," + parent.dateToStringEmpty(a.completed) + ","
                  + r.recipeid + "," + JSON.stringify(r.command).replace(",", "&#44;") + ","
                  + parent.jobStatusToString(a.status) + "," + a.exitstatus + "," + JSON.stringify(this.decodeCombinedOutput(a.combinedoutput)).replace(",", "&#44;") + "\n";
        dataStr += data;
      });
      // var dataStr = JSON.stringify(allData, null, 2);
      var dataUri = 'data:application/csv;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'tasks.csv';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['filterRequests', 'filterTasks', 'filterJobs', 'filterFileTransfers', 'filterUsers',
                    'watchRequests', 'watchTasks', 'watchJobs', 'watchFileTransfers',
                    'cancelWatchRequests', 'cancelWatchTasks', 'cancelWatchJobs', 'cancelWatchFileTransfers',
                    'setUserConfig'])
  }
}
</script>
