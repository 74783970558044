<template>
  <div class="text-xs">

    <v-alert
        dense
        dismissible
        type="warning"
        transition="scale-transition"
        v-model="listIdsModalUIState.isRemovedIdsNotificationDisplayed"
        @click:close-icon="listIdsModalUIState.duplicateIds = []; listIdsModalUIState.unresolvedIds = [];"
    >
      <span>
        {{
          listIdsModalUIState.duplicateIds.length && listIdsModalUIState.unresolvedIds.length
              ? listIdsModalUIState.duplicateIds.length + ' duplicate and ' + listIdsModalUIState.unresolvedIds.length + ' unresolved '
              : listIdsModalUIState.duplicateIds.length
                  ? listIdsModalUIState.duplicateIds.length + ' duplicate '
                  : listIdsModalUIState.unresolvedIds.length + ' unresolved '
        }}
        IDs have been removed from the list
      </span>
    </v-alert>

    <v-layout wrap md12 v-for="(filter, index) in localFilters" :key="index">
      <v-flex md3>
        <v-select
            v-model="filter.field"
            :items="filterFields"
            @change="filter.value = []"
            label="Field"
            dense
        ></v-select>
      </v-flex>
      <v-flex md2>
        <v-select
            v-model="filter.operator"
            :items="filterOperatorFields"
            label="Operator"
            dense
        ></v-select>
      </v-flex>
      <v-flex md4>
        <v-autocomplete
            v-model="filter.value"
            :items="filterValues[filter.field]"
            :filter="customFilter"
            :menu-props="{closeOnClick:true}"
            @click="setSelectedFilter(index)"
            @focus="setSelectedFilter(index)"
            label="Value"
            multiple
            clearable
            dense
        >
          <template v-if="index === selectedFilterIndex && filter.field" v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="custom-filter-btn px-1"
                       text
                       :color="resolveButtonColor(filter.matchesRegexOnValues)"
                       v-on="on"
                       @click.native.stop="toggleFilterRegexMode(index);"
                       @mouseup.stop>
                  <v-icon>mdi-regex</v-icon>
                </v-btn>
              </template>
              <span>Toggle glob pattern match</span>
            </v-tooltip>
            <v-tooltip top v-if="filter.field === 'Agent ID' || filter.field === 'Site ID'">
              <template v-slot:activator="{ on }">
                <v-btn class="custom-filter-btn px-1"
                       text
                       color="primary"
                       v-on="on"
                       @click.native.stop="openListIdsModal();"
                       @mouseup.stop>
                  <v-icon>edit_note</v-icon>
                </v-btn>
              </template>
              <span>Select from a text input list of IDs</span>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex md1 v-if="index === 0">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn text
                   color="primary"
                   v-on="on"
                   @click.prevent="clearFilters()">
              <v-icon>clear_all</v-icon>
            </v-btn>
          </template>
          <span>Clear All</span>
        </v-tooltip>
      </v-flex>
      <v-flex md2 v-if="index > 0">
        <v-select
            v-model="filter.multioperator"
            label="MultiOperator"
            :items="filterMultiOperatorFields"
            dense
        ></v-select>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex md1>
        <span>
        <v-btn fab x-small @click.prevent="addFilterField(index)">+</v-btn>
        <v-btn v-if="localFilters.length > 1" fab x-small @click.prevent="deleteFilterField(index); resetSelectedFilterOnDeletion(index);">-</v-btn>
        </span>
      </v-flex>
    </v-layout>

    <v-dialog
        width="600"
        persistent
        v-model="listIdsModalUIState.isModalOpen"
    >
      <v-card>
        <v-card-title v-if="localFilters[selectedFilterIndex]">Edit the list of desired
          {{ localFilters[selectedFilterIndex].field }}s
        </v-card-title>
        <v-card-text>
          <v-textarea
              placeholder="Type or paste the IDs, separated with commas..."
              clearable
              v-model="listIdsModalUIState.textInput"
              @click:clear="clearListIdsModalTextInput()"
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="clearListIdsModalTextInput(); toggleListIdsModal();">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text
                 :disabled="!listIdsModalUIState.textInput || listIdsModalUIState.textInput.length === 0"
                 @click.prevent="selectModalListIds()"
          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import libvue from "../../lib/vue";
import minimatch from "minimatch";

export default {
  name: "FilteringEditor",
  mixins: [libvue],
  data() {
    return {
      localFilters: [],
      emptyFilter: {field: '', value: [], operator: 'is', multioperator: 'AND', matchesRegexOnValues: false},
      filterOperatorFields: ['is', 'is not'],
      filterMultiOperatorFields: ['AND', 'OR', 'AND NOT'],
      filterFields: ['Agent ID', 'Plant ID', 'Tags', 'Labels', 'OS', 'Arch', 'Release', 'Controller Model', 'Site ID', 'Status', 'Version'],
      listIdsModalUIState: {
        isRemovedIdsNotificationDisplayed: false,
        isModalOpen: false,
        textInput: "",
        duplicateIds: [],
        unresolvedIds: [],
      },
      selectedFilterIndex: -1,
      shouldEmitFilterChanges: false
    }
  },
  props: {
    filters: Array,
    filterValues: Object,
  },
  watch: {
    filters() {
      this.setFilters();
    },
    localFilters: {
      handler(filters) {
        if (this.shouldEmitFilterChanges) {
          this.$emit('update:filters', [...filters]);
        }
        else {
          this.shouldEmitFilterChanges = true;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.setFilters();
  },
  methods: {
    setFilters() {
      this.localFilters = this.filters ? [...this.filters] : [Object.assign({}, this.emptyFilter)];
      this.shouldEmitFilterChanges = false;
    },
    addFilterField(index) {
      this.localFilters.splice(index+1, 0, Object.assign({}, this.emptyFilter));
    },
    deleteFilterField(index) {
      this.localFilters.splice(index, 1);
    },
    clearFilters() {
      this.localFilters.splice(0, this.localFilters.length, Object.assign({}, this.emptyFilter));
    },
    resetSelectedFilterOnDeletion(index) {
      if (this.selectedFilterIndex === index) {
        this.selectedFilterIndex = -1;
      }
    },
    setSelectedFilter(index) {
      if (this.selectedFilterIndex !== index) {
        this.selectedFilterIndex = index;
      }
    },
    resolveButtonColor(condition) {
      if (condition) return 'primary';
      return 'grey';
    },
    toggleFilterRegexMode(index) {
      var clonedFilter = Object.assign({}, this.localFilters[index]);
      clonedFilter.matchesRegexOnValues = !clonedFilter.matchesRegexOnValues;
      this.localFilters.splice(index, 1, clonedFilter);
    },
    customFilter(item, queryText) { // if filter field value has Regex Match enabled else search with literals
      var result;

      if (this.localFilters[this.selectedFilterIndex] && this.localFilters[this.selectedFilterIndex].matchesRegexOnValues) {
         // var queryExpression = new RegExp(this.escapeRegEx(queryText));
         // result = item.match(queryExpression);

        result = minimatch(item, queryText);
      } else {
        result = item.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
      }

      return result;
    },
    toggleListIdsModal() {
      this.listIdsModalUIState.isModalOpen = !this.listIdsModalUIState.isModalOpen;
    },
    openListIdsModal() {
      this.listIdsModalUIState.isRemovedIdsNotificationDisplayed = false;
      this.listIdsModalUIState.textInput = this.localFilters[this.selectedFilterIndex].value.join(" , "); //update Modal list Ids with selected filter value
      this.listIdsModalUIState.duplicateIds = [];
      this.listIdsModalUIState.unresolvedIds = [];
      this.toggleListIdsModal();
    },
    clearListIdsModalTextInput() {
      this.listIdsModalUIState.textInput = "";
    },
    validateModalListIds() {
      const validatedValues = this.listIdsModalUIState.textInput.split(",")
          .map(id => id.trim().replace(/^['\\"]?(.+?)['\\"]?$/, '$1')) // remove single and double quotes at both ends of the string
          .filter(id => id.length > 0);

      this.listIdsModalUIState.duplicateIds = validatedValues.filter((id, index) => validatedValues.indexOf(id) !== index);
      this.listIdsModalUIState.textInput = [...new Set(validatedValues)].join(","); //remove duplicates
    },
    updateSelectedFilterValueWithModalListIds() {
      const selectedIds = this.listIdsModalUIState.textInput.split(",");
      const availableIds = this.filterValues[this.localFilters[this.selectedFilterIndex].field];
      const [applicableIds, unresolvedIds] = this.partitionArray(selectedIds, (id) => availableIds.includes(id));

      this.listIdsModalUIState.unresolvedIds = unresolvedIds;
      this.localFilters[this.selectedFilterIndex].value = applicableIds;
    },
    selectModalListIds() {
      this.validateModalListIds();
      this.updateSelectedFilterValueWithModalListIds();
      this.toggleListIdsModal();
      this.clearListIdsModalTextInput();
      if (this.listIdsModalUIState.duplicateIds.length || this.listIdsModalUIState.unresolvedIds.length) {
        this.listIdsModalUIState.isRemovedIdsNotificationDisplayed = true;
        setTimeout(() => {
          this.listIdsModalUIState.isRemovedIdsNotificationDisplayed = false;
        }, 3000);
      }
    },
  }
}
</script>

<style scoped>
.v-btn.custom-filter-btn {
  height: auto !important;
  min-width: auto !important;
}
</style>

