<template>
  <div>   
    <v-container grid-list-md fluid>
      <v-layout align-center justify-space-around wrap>
        <v-flex md6>
          <v-card>
            <v-layout>
              <v-flex>
                <v-img max-height="100px" max-width="100px" contain :src="require(`../assets/walrus.jpg`)"></v-img>
              </v-flex>
              <v-flex>
                <v-card-title primary-title>
                  <div v-if="$auth.isAuthenticated()">
                    <h3 class="headline mb-0">Cool Agents: {{ agentsnum }}, Online: {{ agentsonline }} </h3>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-progress-linear color="primary" :stream="true" :value="Object.keys(allAgents).length" :active="isLoading.agents"></v-progress-linear>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    agentsnum: {
      get() {
        return Object.keys(this.allAgents).length;
      }
    },
    agentsonline: {
      get() {
        return Object.values(this.allAgents).filter((a) => a.status != "Offline").length;
      }
    },
    ...mapGetters(['allAgents', 'isLoading']),
  },
  methods: {
  },
  beforeDestroy() {
  }
}
</script>
