<template>
  <v-container grid-list-md fluid>
    <v-layout align-center justify-space-around wrap>
      <v-flex md12>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title>Library</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>

      <v-flex md12>
        <v-card v-if="(typeof getFileById(fileobjectid) !== 'undefined')">
          <v-card-title>
            <v-btn text icon color="primary" @click.prevent="openLibraryPage()"> 
              <v-icon dark>arrow_back</v-icon>
            </v-btn>
            <h4> {{ fileobjectid }} </h4>
            <v-spacer></v-spacer>
            <v-btn small text color="primary" :loading="snack" :disabled="snack" @click.prevent="downloadFile()">
              <v-icon dark left>get_app</v-icon>
              Download
            </v-btn>
            <v-btn small text color="primary" @click.prevent="shareFile" v-if="hasAaaAccess()">
              <v-icon dark left>share</v-icon>
              Share
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn small text color="primary" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-layout row wrap>
                <v-flex md12>
                  <v-card>
                    <v-card-actions>
                      <v-list dense>
                        <v-list-item>
                          <v-btn small text color="red" :loading="snack" :disabled="snack || approved || !hasNewTaskFullAccess()" @click.prevent="confirm = true">
                            <v-icon dark left>delete</v-icon>
                            Delete
                          </v-btn>
                        </v-list-item>
                        <v-list-item>
                          <v-btn small text color="primary" @click.prevent="exportFile()">
                            <v-icon dark left>get_app</v-icon>
                            Export
                          </v-btn>
                        </v-list-item>
                      </v-list>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-menu>
          </v-card-title>

          <v-divider></v-divider>

          <v-layout wrap>
            <v-flex md6>
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td colspan="2">{{ file.fileobjectid }}</td>
                  </tr>
                  <tr>
                    <td>Filename</td>
                    <td colspan="2">{{ file.filename }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{{ file.description }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Description'; xvalue = file.description; xdialog = true" :disabled="approved">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Version</td>
                    <td>{{ file.version }}</td>
                    <td style="text-align: right;">
                      <v-btn x-small text @click.prevent="xkey = 'Version'; xvalue = file.version; xdialog = true" :disabled="approved">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Owner</td>
                    <td colspan="2">{{ file.owner }}</td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td colspan="2">{{ dateToString(file.created) }}</td>
                  </tr>
                  <tr>
                    <td>Last Modified</td>
                    <td colspan="2">{{ dateToString(file.lastmodified) }}</td>
                  </tr>
                  <tr>
                    <td>Modified By</td>
                    <td colspan="2">{{ file.modifiedby }}</td>
                  </tr>
                  <tr>
                    <td>ETag</td>
                    <td colspan="2">{{ file.etag }}</td>
                  </tr>
                  <tr>
                    <td>ContentType</td>
                    <td colspan="2">{{ file.contenttype }}</td>
                  </tr>
                  <tr>
                    <td>Size</td>
                    <td colspan="2">{{ file.size }}</td>
                  </tr>
                  <tr>
                    <td>Approved</td>
                    <td colspan="2">
                      <input type="checkbox" v-model="approved" :loading="snack" :disabled="snack || !hasApproveAccess()" v-on:change="callApproveFile()">
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-flex>

            <v-flex md6> 
              <v-card elevation="0">
                <v-card-title>Preview</v-card-title>
                <v-card-text v-if="preview">
                  <div>
                    <pre >{{ preview }}</pre>
                  </div>
                </v-card-text>
                <v-card-actions v-else>
                  <v-btn color="primary" text @click.prevent="callPreviewFile()">Load Preview</v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>

      </v-flex>
    </v-layout>

    <v-dialog
      v-model="xdialog"
      width="500"
      v-if="file"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-text-field
            v-model="xvalue"
            :label="xkey"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="xdialog = false">Close</v-btn>
          <v-btn color="primary" text @click.prevent="callUpdateFile(); xdialog = false;">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sdialog"
      width="600"
      v-if="file"
    >
      <v-card>
        <v-card-title>
          <v-icon dark left color="primary">share</v-icon>
          Share file
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="svalue"
            :items="getUsers()"
            :loading="isLoading.users"
            item-text="key"
            item-value="email"
            label="Add people"
            :disabled="sall"
          ></v-autocomplete>

          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">person</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ getUserEntry(file.owner) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>Owner</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-for="sub in subList" :key="sub">
              <v-list-item-icon>
                <v-icon color="primary">person</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ getUserEntry(sub) }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small text color="primary" @click.prevent="removeSvalue(sub)">
                  <v-icon dark>clear</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-layout>
            <v-flex>
              <v-checkbox v-model="sall" label="Share with all"></v-checkbox>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="sdialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callSetFileAccess(); sdialog = false;">Save</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirm"
      width="500"
    >
      <v-card>
        <v-card-title>Delete selected file?</v-card-title>
        <v-card-text>
          "{{ fileobjectid }}"" will be deleted. You can't undo this action.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.prevent="confirm = false">Cancel</v-btn>
          <v-btn color="primary" text @click.prevent="callDeleteFile(); confirm = false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackdownload"
      :color="snackColor"
      :timeout="-1"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="abortDownloadFile(); snackdownload = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Cancel
      </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import auth from "../auth/authService";
import config from '../../config/config.json';
import libvue from '../lib/vue.js'
import streamSaver from 'streamsaver'

export default {
  mixins: [libvue],
  data() {
    return {
      fileobjectid: this.$route.params.FileObjectID,
      baseurl: process.env.NODE_ENV == 'production' ? window.location.protocol + '//' + window.location.hostname : config.url,
      preview: null,
      confirm: false,
      dialog: false,
      sdialog: false,
      svalue: null,
      subList: [],
      sall: false,
      xdialog: false,
      xkey: null,
      xvalue: null,
      controller: null,
      snack: false,
      snackColor: 'primary',
      snackMessage: '',
      snackTimeout: 2000,
      snackdownload: false
    }
  },
  components: {},
  mounted() {
    this.callPreviewFile();
    this.controller = new AbortController();
    this.signal = this.controller.signal;
  },
  computed: {
    file: {
      get() {
        return this.allFiles[this.fileobjectid];
      }
    },
    approved: {
      get() {
        return this.file.approved;
      },
      set(v) {
        this.file.approved = v;
      },
    },
    ...mapGetters(['getFileById', 'allFiles', 'getUserById', 'allUsers', 'isLoading'])
  },
  methods: {
    openLibraryPage() {
      this.$router.push({ name: 'library-page', params: { Tab: 1 } });
    },
    callPreviewFile() {
      if (!this.file) return;
      this.previewFile(this.file).then((v) => {
        var preview = String.fromCharCode.apply(String, v.getPreview());
        this.preview = preview.split('\n').splice(0, 10).join('\n');
      }).catch(() => {});
    },
    callUpdateFile() {
      this.dialog = true;
      var args = Object.assign({}, this.file);
      switch (this.xkey) {
        case 'Description':
          args.description = this.xvalue;
          break;
        case 'Version':
          args.version = this.xvalue;
          break;
      }
      this.updateFile(args).then(() => { this.dialog = false; }).catch(() => { this.dialog = false; });
    },
    callApproveFile() {
      this.dialog = true;
      this.approveFile({fileobjectid: this.fileobjectid, owner: this.file.owner, approved: this.approved}).then(() => {
        this.dialog = false;
      }).catch(() => {
        this.dialog = false;
      });
    },
    callDeleteFile() {
      this.dialog = true;
      this.deleteFile({fileobjectid: this.fileobjectid, owner: this.file.owner}).then(() => {
        this.dialog = false;
        this.$router.push({ name: 'files-page' });
        this.openLibraryPage();
      }).catch(() => { this.dialog = false; });
    },
    downloadFile() {
      this.dialog = false;
      this.snackdownload = true;
      this.snackColor = 'info'
      this.snackMessage = "Downloading file";
      auth.getAccessToken().then(accessToken => {
        const url = this.baseurl+'/_file1.1/download/'+this.file.fileobjectid;
        const fileStream = streamSaver.createWriteStream(this.file.filename);
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);
        const request = new Request(url, {
          method: 'GET',
          headers: headers,
          mode: 'cors',
          cache: 'default',
          signal: this.signal,
        });
        fetch(request).then(res => {
          const readableStream = res.body

          // more optimized
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream).then(() => {
              this.snackdownload = false;
            });
          }

          // abort so it dose not look stuck
          window.onunload = () => {
            window.WritableStream.abort()
          }

          // window.onbeforeunload = evt => {
          //   if (!done) {
          //     evt.returnValue = `Are you sure you want to leave?`;
          //   }
          // }

          // window.writer = fileStream.getWriter()

          // const reader = res.body.getReader()
          // const pump = () => reader.read()
          //   .then(res => res.done
          //     ? writer.close()
          //     : writer.write(res.value).then(pump))

          // pump()
        }).catch(err => {
          this.dialog = false;
          this.snack = true;
          this.snackColor = 'error'
          if (err.response && err.response.data != "") {
            this.snackMessage = err.response.data;
          } else {
            this.snackMessage = err;
          }
        });
      });
     },
    abortDownloadFile() {
      this.controller.abort();
    },
    getUsers() {
      var users = [];
      if (this.allUsers) {
        Object.values(this.allUsers).filter((v) => v.sub != v.email).sort((a, b) => a.name.localeCompare(b.name)).forEach((v) => {
          v.key = v.name + " (" + v.email + ")";
          users.push(v);
        });
      }
      return users;
    },
    getUserEntry(email) {
      var entry = "";
      if (email && this.allUsers) {
        var v = Object.values(this.allUsers).filter((v) => v.email == email);
        if (v && v.length == 1) {
          entry = v[0].name + " (" + v[0].email + ")";
        }
      }
      return entry;
    },
    removeSvalue(sub) {
      var index = this.subList.indexOf(sub);
      if (index > -1) {
        this.subList.splice(index, 1);
      }
    },
    shareFile() {
      var args = {
        fileobjectid: this.file.fileobjectid,
        owner: this.file.owner,
      }
      this.getFileAccess(args).then((v) => {
        this.sall = v.sharewithall;
        this.svalue = null,
        this.subList = v.subList;
        this.sdialog = true;
      }).catch(() => {});
    },
    callSetFileAccess() {
      this.dialog = true;
      var args = {
        fileobjectid: this.file.fileobjectid,
        owner: this.file.owner,
        subList: this.subList,
        sharewithall: this.sall,
      }
      this.setFileAccess(args).then(() => { this.dialog = false; }).catch(() => { this.dialog = false; });
    },
    hasAaaAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.aaaaccess;
      }
      return false;
    },
    hasNewTaskFullAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.newtaskfullaccess;
      }
      return false;
    },
    hasApproveAccess() {
      if (this.$auth.profile) {
        var u = this.getUserById(this.$auth.profile.sub);
        if (u) return u.attributes.approveaccess;
      }
      return false;
    },
    exportFile() {
      var dataStr = JSON.stringify(this.file, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = this.fileobjectid+'.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['approveFile', 'updateFile', 'previewFile', 'deleteFile', 'getFileAccess', 'setFileAccess'])
  }
}
</script>
