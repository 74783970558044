<template>
  <v-app>
    <Navigation />
    <v-main>
      <v-container fluid>
        <v-system-bar color="success" v-if="message != ''"> {{ message }} </v-system-bar>
        <v-system-bar color="warning" v-if="warning != ''"> {{ warning }} </v-system-bar>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-snackbar
      v-model="error.value"
      color="error"
      :elevation="24"
      :timeout="-1"
      :top="true"
      v-if="!error.stream"
    >
      error {{ error.code }} {{ error.message }}
      <v-btn
        dark
        text
        @click="clearError()"
      >
      <!-- @click="error.value = false" -->
      <!-- @click="$router.go()" -->
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="error.stream"
      color="error"
      :elevation="24"
      :timeout="-1"
      :top="true"
    >
      Connection lost
      <v-btn
        dark
        text
        @click="tryAgain()"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Try Again
      </v-btn>
    </v-snackbar>

  </v-app>
</template>

<script>
import auth from "./auth/authService";
import Navigation from './components/Navigation.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  components: { Navigation },
  data: () => ({
  }),
  computed: {
    message: {
      get() {
        if (this.allConfig && this.allConfig.notification) {
          return this.allConfig.notification.message;
        }
        return "";
      },
    },
    warning: {
      get() {
        if (this.allConfig && this.allConfig.notification) {
          return this.allConfig.notification.warning;
        }
        return "";
      },
    },
    ...mapGetters(['allConfig', 'error'])
  },
  watch: {
  },
  created() {
    auth.renewTokens().then(() => {
      this.watchAgents();
      this.filterAgents();
      this.watchWatchers();
      this.filterWatchers();
      this.watchAlarms();
      // this.watchRequests();
      // this.filterRequests();
      // this.watchTasks();
      // this.filterTasks();
      // this.watchJobs();
      // this.filterJobs();
      // this.watchFileTransfers();
      // this.filterFileTransfers();
      this.watchFiles();
      this.filterFiles();
      this.watchRecipes();
      this.filterRecipes();
      this.filterUsers();
      this.watchConfig();
      this.getConfig();
      this.getUserConfig({sub: auth.profile.sub, owner: auth.profile.email}).then(() => {}).catch((err) => {
        this.updateError(err);
      });
    })
        .catch(() => { });
  },
  methods: {
    tryAgain() {
      auth.renewTokens().then(() => {
        this.clearError();
        this.watchAgents();
        this.filterAgents();
        this.watchWatchers();
        this.filterWatchers();
        this.watchAlarms();
        // this.watchRequests();
        // this.filterRequests();
        // this.watchTasks();
        // this.filterTasks();
        // this.watchJobs();
        // this.filterJobs();
        // this.watchFileTransfers();
        // this.filterFileTransfers();
        this.watchFiles();
        this.filterFiles();
        this.watchRecipes();
        this.filterRecipes();
        // this.filterUsers();
        this.watchConfig();
        this.getConfig();
        // this.getUserConfig();
      })
          .catch(() => { });

    },
    ...mapActions(['filterAgents', 'watchAgents', 'filterTasks', 'watchTasks',
                  'filterRequests', 'watchRequests', 'filterJobs', 'watchJobs',
                  'filterFiles', 'watchFiles', 'filterFileTransfers', 'watchFileTransfers',
                  'filterRecipes', 'watchRecipes', 'filterUsers', 'getUserConfig', 'getConfig', 'watchConfig',
                  'watchAlarms', 'watchWatchers', 'filterWatchers',
                  'updateError', 'clearError'])
  }
}
</script>

<style>
  .v-tabs__content {
    padding-bottom: 2px;
  }
  ul {
    list-style-type: none;
  }
  table tbody tr {
    border: 0px solid black !important;
  }
  table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .02);
  }
  .v-icon {
    display: inline-flex !important;
  }
  .underline {
    text-decoration: underline;
    color: #0000EE;
    cursor: pointer;
  }
  .tt20 td:first-child {
    width: 20%;
  }
  .inline {
    display: inline;
  }
  .link-button {
    background: none;
    border: none;
    color: #1976D2;
    text-decoration: underline;
    cursor: pointer;
  }
  pre {
    white-space: pre-wrap;
  }
  /* .v-input--selection-controls {
    margin-top: 0px !important;
  } */
  /* input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    display: inline-block;
    cursor: pointer;
    padding: 6px 12px;
    color: #1976D2;
    font-size: small;
  } */
</style>
