<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Alarms</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip class="ma-2" color="red lighten-1" outlined label v-on="on">
                    <v-avatar left>
                      <v-icon small>alarm_on</v-icon>
                    </v-avatar>
                    {{ activeAlarms.length }}
                  </v-chip>
                </template>
                <span>Active Alarms</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip class="ma-2" color="orange lighten-1" outlined label v-on="on">
                    <v-avatar left>
                      <v-icon small>alarm</v-icon>
                    </v-avatar>
                    {{ alarmHistory.length }}
                  </v-chip>
                </template>
                <span>Alarms since {{ datestart }} {{ timestart }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip class="ma-2" color="blue lighten-1" outlined label v-on="on">
                    <v-avatar left>
                      <v-icon small>warning</v-icon>
                    </v-avatar>
                    {{ problemHistory.length }}
                  </v-chip>
                </template>
                <span>Problems since {{ datestart }} {{ timestart }}</span>
              </v-tooltip>
              <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-switch
                      v-model="pinned"
                      prepend-icon="push_pin"
                      dense
                      :disabled="advancedsearch"
                      v-on="on"
                    ></v-switch>
                  </template>
                  <span v-if="pinned">Show all</span>
                  <span v-else>Show pinned</span>
                </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex m12>
          <v-tabs slot="extension" v-model="tabs">
            <v-tab :key="0">Active</v-tab>
            <v-tab :key="1">History</v-tab>
            <v-tab :key="2">Problems</v-tab>
          </v-tabs>

          <v-tabs-items style="width:100%" v-model="tabs">
            <v-tab-item :key="0">
              <v-card>
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  :headers="headersActive"
                  :footer-props="{
                    'items-per-page-options': itemsPerPage
                  }"
                  :items="activeAlarms"
                  :loading="isLoading.alarms"
                  :search="search"
                  :sort-by="'date'"
                  :sort-desc="true"
                  item-key="alarmid"
                  class="elevation-1"
                  dense
                  @update:options="updatePagination"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.alarmid" @click.prevent="showAlarm(item)" :style="{cursor:'pointer'}">
                        <td><v-icon small>alarm</v-icon></td>
                        <td>{{ smallId(item.alarmid) }}</td>
                        <td>{{ item.name }}</td>
                        <td v-if="item.status=='ON'">
                          <v-icon small color='error'>alarm_on{{ item.status }}</v-icon>
                        </td>
                        <td v-else>
                          <v-icon small color='info'>alarm_off{{ item.status }}</v-icon>
                        </td>
                        <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                        <!-- <td>{{ smallId(item.clientid) }}</td> -->
                        <td>{{ dateToString(item.date) }}</td>
                        <!-- <td>{{ dateToString(item.enddate) }}</td> -->
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="1">
              <v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small text color="primary" @click.prevent="refreshAlarms()" v-if="!advancedsearch">
                    <v-icon dark left>refresh</v-icon>
                    Refresh
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn small text color="primary" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-layout row wrap>
                      <v-flex md12>
                        <v-card>
                          <v-card-actions>
                            <v-list dense>
                              <v-list-item>
                                <v-btn small text color="primary" @click.prevent="exportAlarms()">
                                  <v-icon dark left>get_app</v-icon>
                                  Export
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-actions>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-menu>
                </v-card-actions>
                <v-card-title>
                  <v-layout wrap md12>
                    <v-flex md6>
                      <v-checkbox
                        v-model="advancedsearch"
                        label="Advanced Search"
                        persistent-hint
                      ></v-checkbox>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Quick Search"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-layout wrap v-if="advancedsearch">
                      <v-flex md3>
                        <v-menu
                          ref="startdatemenu"
                          v-model="startdatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="datestart"
                              label="Start date"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datestart"
                            no-title
                            @input="startdatemenu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex md3>
                        <v-menu
                          ref="starttimemenu"
                          v-model="starttimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="timestart"
                              label="Start time"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="timestart"
                            format="24hr"
                            scrollable
                          >
                          </v-time-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex md3>
                        <v-menu
                          ref="enddatemenu"
                          v-model="enddatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="dateend"
                              label="End date"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateend"
                            no-title
                            @input="enddatemenu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex md3>
                        <v-menu
                          ref="endtimemenu"
                          v-model="endtimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="timeend"
                              label="End time"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="timeend"
                            format="24hr"
                            scrollable
                          >
                          </v-time-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex md5>
                        <v-autocomplete
                          v-model="agentid"
                          prepend-icon="widgets"
                          :items="getAgents()"
                          label="Agent ID"
                          clearable
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex md5>
                        <v-autocomplete
                          v-model="alarmname"
                          prepend-icon="alarm"
                          :items="getAlarmNames()"
                          label="Alarm"
                          clearable
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex md2>
                        <v-btn text color="primary" @click.prevent="getAlarmsSearch()">
                          <v-icon dark left>search</v-icon>Search</v-btn>
                      </v-flex>

                    </v-layout>
                  </v-layout>
                </v-card-title>

                <v-data-table
                  :headers="headersHistory"
                  :footer-props="{
                    'items-per-page-options': itemsPerPage
                  }"
                  :items="alarmHistory"
                  :loading="isLoading.alarms"
                  :search="search"
                  :sort-by="'date'"
                  :sort-desc="true"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  item-key="alarmid"
                  class="elevation-1"
                  dense
                  @update:options="updatePagination"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.alarmid" @click.prevent="showAlarm(item)" :style="{cursor:'pointer'}">
                        <td><v-icon small>alarm</v-icon></td>
                        <td>{{ smallId(item.alarmid) }}</td>
                        <td>{{ item.name }}</td>
                        <td v-if="item.status=='ON'">
                          <v-icon small color='error'>alarm_on{{ item.status }}</v-icon>
                        </td>
                        <td v-else>
                          <v-icon small color='info'>alarm_off{{ item.status }}</v-icon>
                        </td>
                        <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                        <!-- <td>{{ smallId(item.clientid) }}</td> -->
                        <td>{{ dateToString(item.date) }}</td>
                        <td>{{ dateToString(item.enddate) }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-slot:footer>
                    <div align="center" v-if="page == pageCount || pageCount == 0">
                      <v-btn small text color="primary" @click.prevent="getAlarmsMore()" :disabled="bce(start)" v-if="!isLoading.alarms">
                        Load More
                      </v-btn>
                      <v-btn small text color="primary" :disabled="true" v-else>
                        Loading...
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item :key="2">
              <v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small text color="primary" @click.prevent="refreshProblems()" v-if="!advancedsearch">
                    <v-icon dark left>refresh</v-icon>
                    Refresh
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn small text color="primary" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-layout row wrap>
                      <v-flex md12>
                        <v-card>
                          <v-card-actions>
                            <v-list dense>
                              <v-list-item>
                                <v-btn small text color="primary" @click.prevent="exportProblems()">
                                  <v-icon dark left>get_app</v-icon>
                                  Export
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-actions>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-menu>
                </v-card-actions>
                <v-card-title>
                  <v-layout wrap md12>
                    <v-flex md6>
                      <v-checkbox
                        v-model="advancedsearch"
                        label="Advanced Search"
                        persistent-hint
                      ></v-checkbox>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Quick Search"
                        single-line
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-flex>

                    <v-layout wrap v-if="advancedsearch">
                      <v-flex md3>
                        <v-menu
                          ref="startdatemenu"
                          v-model="startdatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="datestart"
                              label="Start date"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datestart"
                            no-title
                            @input="startdatemenu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex md3>
                        <v-menu
                          ref="starttimemenu"
                          v-model="starttimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="timestart"
                              label="Start time"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="timestart"
                            format="24hr"
                            scrollable
                          >
                          </v-time-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex md3>
                        <v-menu
                          ref="enddatemenu"
                          v-model="enddatemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="dateend"
                              label="End date"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateend"
                            no-title
                            @input="enddatemenu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex md3>
                        <v-menu
                          ref="endtimemenu"
                          v-model="endtimemenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="timeend"
                              label="End time"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="timeend"
                            format="24hr"
                            scrollable
                          >
                          </v-time-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex md5>
                        <v-autocomplete
                          v-model="agentid"
                          prepend-icon="widgets"
                          :items="getAgents()"
                          label="Agent ID"
                          clearable
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex md3>
                        <v-autocomplete
                          v-model="problemTypeName"
                          prepend-icon="widgets"
                          :items="getProblemTypes()"
                          label="Problem Type"
                          clearable
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex md2>
                        <v-text-field
                          v-model="threshold"
                          prepend-icon="report_problem"
                          label="Threshold"
                          :rules="thresholdRules"
                        ></v-text-field>
                      </v-flex>

                      <v-flex md2>
                        <v-btn text color="primary" @click.prevent="getProblemsSearch()">
                          <v-icon dark left>search</v-icon>Search</v-btn>
                      </v-flex>

                    </v-layout>
                  </v-layout>
                </v-card-title>

                <v-data-table
                  :headers="headersProblems"
                  :footer-props="{
                    'items-per-page-options': itemsPerPage
                  }"
                  :items="problemHistory"
                  :loading="isLoading.problems"
                  :search="search"
                  :sort-by="'value'"
                  :sort-desc="true"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  item-key="agentid+type"
                  class="elevation-1"
                  dense
                  @update:options="updatePagination"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.agentid+item.type">
                        <td><v-icon small>report_problem</v-icon></td>
                        <td @click.stop="openAgentDetailsPage(item.agentid)" class="underline" :style="{cursor:'pointer'}">{{ item.agentid }}</td>
                        <td>{{ problemTypeToString(item.type) }}</td>
                        <td>{{ item.value }}</td>
                        <td>{{ item.description }}</td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-slot:footer>
                    <div align="center" v-if="page == pageCount || pageCount == 0">
                      <v-btn small text color="primary" @click.prevent="getProblemsMore()" :disabled="bce(start)" v-if="!isLoading.problems">
                        Load More
                      </v-btn>
                      <v-btn small text color="primary" :disabled="true" v-else>
                        Loading...
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-flex>

      </v-layout>
    </v-container>

    <v-dialog
      v-model="infodialog"
      hide-overlay
      width="800"
    >
      <v-card v-if="selectedAlarm">
        <v-card-title>{{ selectedAlarm.agentid }}</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
              <tr>
                <td>AlarmID</td>
                <td>{{ selectedAlarm.alarmid }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ selectedAlarm.name }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td v-if="selectedAlarm.status=='ON'">
                  <v-icon small color='error'>alarm_on{{ selectedAlarm.status }}</v-icon>
                </td>
                <td v-if="selectedAlarm.status=='OFF'">
                  <v-icon small color='info'>alarm_off{{ selectedAlarm.status }}</v-icon>
                </td>
              </tr>
              <tr>
                <td>ClientID</td>
                <td>{{ selectedAlarm.clientid }}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{{ dateToString(selectedAlarm.date) }}</td>
              </tr>
              <tr>
                <td>End Date</td>
                <td>{{ dateToString(selectedAlarm.enddate) }}</td>
              </tr>
              <tr>
                <td>Value</td>
                <td>{{ selectedAlarm.value }}</td>
              </tr>
              <tr>
                <td colspan="2">Details</td>
              </tr>
              <tr>
                <td colspan="2"><pre>{{ decodeCombinedOutput(selectedAlarm.details) }}</pre></td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="infodialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import libvue from '../lib/vue.js'
import config from '../../config/config.json';

export default {
  mixins: [libvue],
  data() {
    return {
      tabs: 0,
      page: 1,
      pageCount: 0,
      start: 0,
      limit: 0,
      threshold: 50,
      thresholdRules: [
        v => v >= 0 && v <= 1000 || 'Threshold must be between 0 and 1000'
      ],
      problemTypeName: null,
      search: '',
      headersActive: [
        { text: '', sortable: false, value: '' },
        { text: 'AlarmID', sortable: true, value: 'alarmid' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        // { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Date', sortable: true, value: 'date' },
        // { text: 'End Date', sortable: true, value: 'enddate' },
        { text: 'Value', sortable: true, value: 'value' }
      ],
      headersHistory: [
        { text: '', sortable: false, value: '' },
        { text: 'AlarmID', sortable: true, value: 'alarmid' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        // { text: 'ClientID', sortable: true, value: 'clientid' },
        { text: 'Date', sortable: true, value: 'date' },
        { text: 'End Date', sortable: true, value: 'enddate' },
        { text: 'Value', sortable: true, value: 'value' }
      ],
      headersProblems: [
        { text: '', sortable: false, value: '' },
        { text: 'AgentID', sortable: true, value: 'agentid' },
        { text: 'Type', sortable: true, value: 'type' },
        { text: 'Value', sortable: true, value: 'value' },
        { text: 'Description', sortable: true, value: 'description' }
      ],
      infodialog: false,
      dialog: false,
      selectedAlarm: null,
      advancedsearch: false,
      alarmname: null,
      agentid: null,
      startdatemenu: false,
      starttimemenu: false,
      datestart: new Date(new Date().getTime()-config.historyMs).toISOString().substr(0, 10),
      timestart: ('0'+new Date(new Date().getTime()).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      enddatemenu: false,
      endtimemenu: false,
      dateend: new Date(new Date().getTime()).toISOString().substr(0, 10),
      timeend: ('0'+new Date().getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  mounted() {
    this.refreshAlarms();
    this.refreshProblems();
  },
  watch: {
    advancedsearch: function(v) {
      if (v) {
        this.pinned = false;
      }
    }
  },
  computed: {
    activeAlarms: {
      get() {
        var alarms = [];
        Object.values(this.allAlarms).forEach(a => {
          if (a.status == "ON") {
            if (!this.pinned || !this.userConfig || !this.userConfig.pinnedagentsidsList) {
              alarms.push(a);
            } else {
              for (const v of this.userConfig.pinnedagentsidsList) {
                if (a.agentid == v) {
                  alarms.push(a);
                }
              }
            }
          }
        });
        return alarms;
      }
    },
    alarmHistory: {
      get() {
        var alarms = [];
        if (!this.pinned || !this.userConfig || !this.userConfig.pinnedagentsidsList) {
          Object.values(this.allAlarms).forEach((a) => {
            if (this.advancedsearch) {
              if (this.agentid && this.agentid != a.agentid) {
                return;
              }
              if (this.alarmname && this.alarmname != a.name) {
                return;
              }
            }
            alarms.push(a);
          });
        } else {
          Object.values(this.allAlarms).forEach(a => {
            for (const v of this.userConfig.pinnedagentsidsList) {
              if (a.agentid == v) {
                alarms.push(a);
              }
            }  
          });        
        }
        return alarms;
      }
    },
    problemHistory: {
      get() {
        var problems = [];
        if (!this.pinned || !this.userConfig || !this.userConfig.pinnedagentsidsList) {
          problems = Object.values(this.allProblems);
        } else {
          Object.values(this.allProblems).forEach(a => {
            for (const v of this.userConfig.pinnedagentsidsList) {
              if (a.agentid == v) {
                problems.push(a);
              }
            }  
          });        
        }
        return problems;
      }
    },
    pinned: {
      get() {
        if (!this.userConfig) return false;
        return this.userConfig.pinned;
      },
      set(v) {
        this.setPinned(v);
      }
    },
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    ...mapGetters(['allAgents', 'allAlarms', 'allProblems', 'userConfig', 'isLoading'])
  },
  methods: {
    setPinned(v) {
      if (!this.userConfig || !this.userConfig.sub) return;
      var config = Object.assign({}, this.userConfig);
      config.pinned = v;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    openAgentDetailsPage(agentid) {
      this.$router.push({ name: 'agent-details', params: { AgentID: agentid } });
    },
    getAlarms(more) {
      var args = {start: this.start, limit: this.limit, more: more};
      if (this.advancedsearch) {
        args.agentid = this.agentid;
        args.name = this.alarmname;
      }
      return this.filterAlarms(args);
    },
    getAlarmsMore(warp) {
      if (!warp) warp = 1;
      var n = Object.keys(this.allAlarms).length;
      this.limit = this.start;
      this.start = this.start - config.historyMs * 1e6 * warp;
      this.datestart = new Date(this.start/1e6).toISOString().substr(0, 10);
      this.timestart = ('0'+new Date(this.start/1e6).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2);
      this.getAlarms(true).then(() => {
        if (Object.keys(this.allAlarms).length === n) {
          if (this.start < config.beginningMs * 1e6) return; // before 20200101
          this.getAlarmsMore(warp + 1); // incremental
        }
      }).catch(() => {});
    },
    getAlarmsSearch() {
      this.start = Date.parse(this.datestart + 'T' + this.timestart + 'Z') * 1e6;
      this.limit = Date.parse(this.dateend + 'T' + this.timeend + 'Z') * 1e6;
      this.getAlarms().then(() => {}).catch(() => {});
    },
    refreshAlarms() {
      this.start = (new Date().getTime()-config.historyMs) * 1e6;
      this.limit = new Date().getTime() * 1e6;
      return this.getAlarms().then(() => {}).catch(() => {});
    },
    getAlarmNames() {
      return ['CPUHigh', 'DiskLow', 'DiskRO', 'SvcDown', 'FTTELFault', 'DateSkew'].sort();
    },
    refreshProblems() {
      this.start = (new Date().getTime()-config.historyMs) * 1e6;
      this.limit = new Date().getTime() * 1e6;
      this.getProblems().then(() => {}).catch(() => {});
    },
    getProblems(more) {
      var args = {start: this.start, limit: this.limit, threshold: this.threshold, more: more};
      if (this.advancedsearch) {
        if (this.agentid) args.agentid = this.agentid;
        if (this.threshold) args.threshold = this.threshold;
        switch (this.problemTypeName) {
          case "Disconnects":
            // eslint-disable-next-line no-undef
            args.type = proto.pb.ProblemType.DISCONNECTS;
            break;
          case "Alarms":
            // eslint-disable-next-line no-undef
            args.type = proto.pb.ProblemType.ALARMS;
            break;
        }
      }
      return this.filterProblems(args);
    },
    getProblemsMore(warp) {
      if (!warp) warp = 1;
      var n = Object.keys(this.allProblems).length;
      this.limit = this.start;
      this.start = this.start - config.historyMs * 1e6 * warp;
      this.datestart = new Date(this.start/1e6).toISOString().substr(0, 10);
      this.timestart = ('0'+new Date(this.start/1e6).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2);
      this.getProblems(true).then(() => {
        if (Object.keys(this.allProblems).length === n) {
          if (this.start < config.beginningMs * 1e6) return; // before 20200101
          this.getProblemsMore(warp + 1); // incremental
        }
      }).catch(() => {});
    },
    getProblemsSearch() {
      this.start = Date.parse(this.datestart + 'T' + this.timestart + 'Z') * 1e6;
      this.limit = Date.parse(this.dateend + 'T' + this.timeend + 'Z') * 1e6;
      this.getProblems().then(() => {}).catch(() => {});
    },
    getProblemTypes() {
      return ['Alarms', 'Disconnects'];
    },
    getAgents() {
      var agents = [];
      Object.values(this.allAgents).forEach((a) => {
        agents.push(a.agentid);
      });
      return agents;
    },
    showAlarm(alarm) {
      this.dialog = true;
      var a = this.allAlarms[alarm.alarmid];
      if (a) {
        this.dialog = false;
        this.selectedAlarm = a;
        this.infodialog = true;
      } else {
        var args = {
          agentid: alarm.agentid,
          alarmid: alarm.alarmid,
          start: alarm.date - 1000,
          limit: alarm.date + 1000,
        }
        this.filterAlarms(args).then(() => {
          this.dialog = false;
          var a = this.allAlarms[alarm.alarmid];
          if (a) {
            this.selectedAlarm = a;
            this.infodialog = true;
          }
        }).catch(() => { this.dialog = false; });
      }
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportAlarms() {
      var dataStr = JSON.stringify(this.alarmHistory, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'alarms.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    exportProblems() {
      var dataStr = JSON.stringify(this.problemHistory, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'problems.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['filterAlarms', 'getAlarm', 'filterProblems', 'setUserConfig'])
  }
}
</script>
