<template>
  <div>     
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex md12>
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Audit</v-toolbar-title>
          </v-toolbar>
        </v-flex>

        <v-flex md12>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn small text color="primary" @click.prevent="refreshAudit()" v-if="!advancedsearch">
                <v-icon dark left>refresh</v-icon>
                Refresh
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small text color="primary" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-layout row wrap>
                  <v-flex md12>
                    <v-card>
                      <v-card-actions>
                        <v-list dense>
                          <v-list-item>
                            <v-btn small text color="primary" @click.prevent="exportAudit()">
                              <v-icon dark left>get_app</v-icon>
                              Export
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex m12>
          <v-card>
            <v-card-title>

              <v-layout wrap md12>
                <v-flex md6>
                  <v-checkbox
                    v-model="advancedsearch"
                    label="Advanced Search"
                    persistent-hint
                  ></v-checkbox>
                </v-flex>
                <v-flex md6>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Quick Search"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                </v-flex>

                <v-layout wrap v-if="advancedsearch">
                  <v-flex md3>
                    <v-menu
                      ref="startdatemenu"
                      v-model="startdatemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="datestart"
                          label="Start date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="datestart"
                        no-title
                        @input="startdatemenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex md3>
                    <v-menu
                      ref="starttimemenu"
                      v-model="starttimemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="timestart"
                          label="Start time"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="timestart"
                        format="24hr"
                        scrollable
                      >
                      </v-time-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex md3>
                    <v-menu
                      ref="enddatemenu"
                      v-model="enddatemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateend"
                          label="End date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateend"
                        no-title
                        @input="enddatemenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex md3>
                    <v-menu
                      ref="endtimemenu"
                      v-model="endtimemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="timeend"
                          label="End time"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="timeend"
                        format="24hr"
                        scrollable
                      >
                      </v-time-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex md4>
                    <v-autocomplete
                      v-model="email"
                      prepend-icon="email"
                      :items="getEmails()"
                      label="Email"
                      clearable
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex md4>
                    <v-autocomplete
                      v-model="fullmethod"
                      prepend-icon="functions"
                      :items="getFullMethods()"
                      label="FullMethod"
                      clearable
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex md2>
                    <v-btn text color="primary" @click.prevent="getAuditSearch()">
                      <v-icon dark left>search</v-icon>Search</v-btn>
                  </v-flex>

                </v-layout>
              </v-layout>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :footer-props="{
                'items-per-page-options': itemsPerPage
              }"
              :items="Object.values(allAudit)"
              :loading="isLoading.audit"
              :search="search"
              :sort-by="'timestamp'"
              :sort-desc="true"
              :page.sync="page"
              @page-count="pageCount = $event"
              item-key="auditid"
              class="elevation-1"
              dense
              @update:options="updatePagination"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.auditid" @click="select(item)" :style="{cursor:'pointer'}">
                    <td><v-icon small>security</v-icon></td>
                    <td>{{ smallId(item.auditid) }}</td>
                    <td>{{ dateToString(item.timestamp) }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.fullmethod }}</td>
                    <td>{{ item.remote }}</td>
                    <td>{{ item.code }}</td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:footer>
                <div align="center" v-if="page == pageCount || pageCount == 0">
                  <v-btn small text color="primary" @click.prevent="getAuditMore()" :disabled="bce(start)" v-if="!isLoading.audit">
                    Load More
                  </v-btn>
                  <v-btn small text color="primary" :disabled="true" v-else>
                    Loading...
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog
      v-model="infodialog"
      hide-overlay
      width="700"
    >
      <v-card v-if="selected">
        <v-card-title>{{ selected.auditid }}</v-card-title>
        <v-card-text>
          <pre>{{ selected }}</pre>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="infodialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snack"
      :color="snackColor"
      :timeout="snackTimeout"
      :top="true"
    >
      {{ snackMessage }}
      <v-btn
        text
        @click="snack = false"
      >
        <div class="v-menu__content--active" style="display:none; z-index:1000;"></div>
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import libvue from '../lib/vue.js'
import config from '../../config/config.json';
import methods from '../../config/methods.json';

export default {
  mixins: [libvue],
  data() {
    return {
      page: 1,
      pageCount: 0,
      auditList: [],
      start: 0,
      limit: 0,
      search: '',
      headers: [
        { text: '', sortable: false, value: '' },
        { text: 'AuditID', sortable: true, value: 'auditid' },
        { text: 'Timestamp', sortable: true, value: 'timestamp' },
        { text: 'Email', sortable: true, value: 'email' },
        { text: 'FullMethod', sortable: true, value: 'fullmethod' },
        { text: 'Remote', sortable: true, value: 'remote' },
        { text: 'Code', sortable: true, value: 'code' }
      ],
      infodialog: false,
      selected: null,
      advancedsearch: false,
      email: null,
      fullmethod: null,
      startdatemenu: false,
      starttimemenu: false,
      datestart: new Date(new Date().getTime()-config.historyMs).toISOString().substr(0, 10),
      timestart: ('0'+new Date(new Date().getTime()).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      enddatemenu: false,
      endtimemenu: false,
      dateend: new Date(new Date().getTime()).toISOString().substr(0, 10),
      timeend: ('0'+new Date().getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2),
      intervalenable: false,
      snack: false,
      snackColor: 'success',
      snackMessage: '',
      snackTimeout: 2000
    }
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.pagination(this.userConfig.itemsperpage);
      }
    },
    ...mapGetters(['allAudit', 'allUsers', 'userConfig', 'isLoading'])
  },
  mounted () {
    this.filterUsers().then(() => {
      this.refreshAudit();
    });
  },
  methods: {
    getAudit(more) {
      var args = {start: this.start, limit: this.limit, more: more};
      if (this.advancedsearch) {
        args.email = this.email;
        args.fullmethod = this.fullmethod;
      }
      return this.filterAudit(args);
    },
    refreshAudit() {
      this.start = (new Date().getTime()-config.historyMs) * 1e6;
      this.limit = new Date().getTime() * 1e6;
      this.getAudit().then(() => {}).catch(() => {});
    },
    getAuditMore(warp) {
      if (!warp) warp = 1;
      var n = Object.keys(this.allAudit).length;
      this.limit = this.start;
      this.start = this.start - config.historyMs * 1e6 * warp;
      this.datestart = new Date(this.start/1e6).toISOString().substr(0, 10);
      this.timestart = ('0'+new Date(this.start/1e6).getUTCHours()).slice(-2) + ':' + ('0'+new Date().getUTCMinutes()).slice(-2);
      this.getAudit(true).then(() => {
        if (Object.keys(this.allAudit).length === n) {
          if (this.start < config.beginningMs * 1e6) return; // before 20200101
          this.getAuditMore(warp + 1); // incremental
        }
      }).catch(() => {});
    },
    getAuditSearch() {
      this.start = Date.parse(this.datestart + 'T' + this.timestart + 'Z') * 1e6;
      this.limit = Date.parse(this.dateend + 'T' + this.timeend + 'Z') * 1e6;
      this.getAudit().then(() => {}).catch(() => {});
    },
    getEmails() {
      var emails = [];
      Object.values(this.allUsers).forEach((u) => {
        emails.push(u.email);
      });
      return emails.sort();
    },
    getFullMethods() {
      return methods.sort();
    },
    select(v) {
      this.selected = v;
      this.infodialog = true;
    },
    updatePagination (pagination) {
      if (!this.userConfig || !this.userConfig.sub) return;
      if (this.userConfig.itemsperpage == pagination.itemsPerPage) return
      var config = Object.assign({}, this.userConfig);
      config.itemsperpage = pagination.itemsPerPage;
      this.setUserConfig(config).then(() => {}).catch(() => {});
    },
    exportAudit() {
      var dataStr = JSON.stringify(this.allAudit, null, 2);
      var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      var exportFileDefaultName = 'audit.json';
      var linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    ...mapActions(['filterAudit', 'filterUsers', 'setUserConfig'])
  }
}
</script>
