import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import AuthPlugin from "./plugins/auth"
import VueCookie from 'vue-cookie';
import VueCryptojs from 'vue-cryptojs'


Vue.use(AuthPlugin);
Vue.use(VueCookie);
Vue.use(VueCryptojs)
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
 