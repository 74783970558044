/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: file_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var file_pb = require('./file_pb.js')

var file_transfer_pb = require('./file_transfer_pb.js')
const proto = {};
proto.pb = require('./file_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.FileServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.FileServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileObject,
 *   !proto.pb.FileObject>}
 */
const methodDescriptor_FileService_GetFile = new grpc.web.MethodDescriptor(
  '/pb.FileService/GetFile',
  grpc.web.MethodType.UNARY,
  file_pb.FileObject,
  file_pb.FileObject,
  /**
   * @param {!proto.pb.FileObject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileObject.deserializeBinary
);


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.getFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/GetFile',
      request,
      metadata || {},
      methodDescriptor_FileService_GetFile,
      callback);
};


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileObject>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.getFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/GetFile',
      request,
      metadata || {},
      methodDescriptor_FileService_GetFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileObject,
 *   !proto.pb.FileObject>}
 */
const methodDescriptor_FileService_FilterFiles = new grpc.web.MethodDescriptor(
  '/pb.FileService/FilterFiles',
  grpc.web.MethodType.SERVER_STREAMING,
  file_pb.FileObject,
  file_pb.FileObject,
  /**
   * @param {!proto.pb.FileObject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileObject.deserializeBinary
);


/**
 * @param {!proto.pb.FileObject} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.filterFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.FileService/FilterFiles',
      request,
      metadata || {},
      methodDescriptor_FileService_FilterFiles);
};


/**
 * @param {!proto.pb.FileObject} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServicePromiseClient.prototype.filterFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.FileService/FilterFiles',
      request,
      metadata || {},
      methodDescriptor_FileService_FilterFiles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileObject,
 *   !proto.pb.FileObject>}
 */
const methodDescriptor_FileService_WatchFiles = new grpc.web.MethodDescriptor(
  '/pb.FileService/WatchFiles',
  grpc.web.MethodType.SERVER_STREAMING,
  file_pb.FileObject,
  file_pb.FileObject,
  /**
   * @param {!proto.pb.FileObject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileObject.deserializeBinary
);


/**
 * @param {!proto.pb.FileObject} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.watchFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.FileService/WatchFiles',
      request,
      metadata || {},
      methodDescriptor_FileService_WatchFiles);
};


/**
 * @param {!proto.pb.FileObject} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServicePromiseClient.prototype.watchFiles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.FileService/WatchFiles',
      request,
      metadata || {},
      methodDescriptor_FileService_WatchFiles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileObject,
 *   !proto.pb.FileObject>}
 */
const methodDescriptor_FileService_PreviewFile = new grpc.web.MethodDescriptor(
  '/pb.FileService/PreviewFile',
  grpc.web.MethodType.UNARY,
  file_pb.FileObject,
  file_pb.FileObject,
  /**
   * @param {!proto.pb.FileObject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileObject.deserializeBinary
);


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.previewFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/PreviewFile',
      request,
      metadata || {},
      methodDescriptor_FileService_PreviewFile,
      callback);
};


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileObject>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.previewFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/PreviewFile',
      request,
      metadata || {},
      methodDescriptor_FileService_PreviewFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileObject,
 *   !proto.pb.FileObject>}
 */
const methodDescriptor_FileService_ApproveFile = new grpc.web.MethodDescriptor(
  '/pb.FileService/ApproveFile',
  grpc.web.MethodType.UNARY,
  file_pb.FileObject,
  file_pb.FileObject,
  /**
   * @param {!proto.pb.FileObject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileObject.deserializeBinary
);


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.approveFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/ApproveFile',
      request,
      metadata || {},
      methodDescriptor_FileService_ApproveFile,
      callback);
};


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileObject>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.approveFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/ApproveFile',
      request,
      metadata || {},
      methodDescriptor_FileService_ApproveFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileObject,
 *   !proto.pb.FileObject>}
 */
const methodDescriptor_FileService_UpdateFile = new grpc.web.MethodDescriptor(
  '/pb.FileService/UpdateFile',
  grpc.web.MethodType.UNARY,
  file_pb.FileObject,
  file_pb.FileObject,
  /**
   * @param {!proto.pb.FileObject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileObject.deserializeBinary
);


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.updateFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/UpdateFile',
      request,
      metadata || {},
      methodDescriptor_FileService_UpdateFile,
      callback);
};


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileObject>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.updateFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/UpdateFile',
      request,
      metadata || {},
      methodDescriptor_FileService_UpdateFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileTransfer,
 *   !proto.pb.FileTransfer>}
 */
const methodDescriptor_FileService_SendFile = new grpc.web.MethodDescriptor(
  '/pb.FileService/SendFile',
  grpc.web.MethodType.UNARY,
  file_transfer_pb.FileTransfer,
  file_transfer_pb.FileTransfer,
  /**
   * @param {!proto.pb.FileTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_transfer_pb.FileTransfer.deserializeBinary
);


/**
 * @param {!proto.pb.FileTransfer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileTransfer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileTransfer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.sendFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/SendFile',
      request,
      metadata || {},
      methodDescriptor_FileService_SendFile,
      callback);
};


/**
 * @param {!proto.pb.FileTransfer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileTransfer>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.sendFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/SendFile',
      request,
      metadata || {},
      methodDescriptor_FileService_SendFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileObject,
 *   !proto.pb.FileObject>}
 */
const methodDescriptor_FileService_DeleteFile = new grpc.web.MethodDescriptor(
  '/pb.FileService/DeleteFile',
  grpc.web.MethodType.UNARY,
  file_pb.FileObject,
  file_pb.FileObject,
  /**
   * @param {!proto.pb.FileObject} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileObject.deserializeBinary
);


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileObject)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileObject>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.deleteFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/DeleteFile',
      request,
      metadata || {},
      methodDescriptor_FileService_DeleteFile,
      callback);
};


/**
 * @param {!proto.pb.FileObject} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileObject>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.deleteFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/DeleteFile',
      request,
      metadata || {},
      methodDescriptor_FileService_DeleteFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileAccess,
 *   !proto.pb.FileAccess>}
 */
const methodDescriptor_FileService_GetFileAccess = new grpc.web.MethodDescriptor(
  '/pb.FileService/GetFileAccess',
  grpc.web.MethodType.UNARY,
  file_pb.FileAccess,
  file_pb.FileAccess,
  /**
   * @param {!proto.pb.FileAccess} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileAccess.deserializeBinary
);


/**
 * @param {!proto.pb.FileAccess} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileAccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileAccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.getFileAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/GetFileAccess',
      request,
      metadata || {},
      methodDescriptor_FileService_GetFileAccess,
      callback);
};


/**
 * @param {!proto.pb.FileAccess} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileAccess>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.getFileAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/GetFileAccess',
      request,
      metadata || {},
      methodDescriptor_FileService_GetFileAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileAccess,
 *   !proto.pb.FileAccess>}
 */
const methodDescriptor_FileService_SetFileAccess = new grpc.web.MethodDescriptor(
  '/pb.FileService/SetFileAccess',
  grpc.web.MethodType.UNARY,
  file_pb.FileAccess,
  file_pb.FileAccess,
  /**
   * @param {!proto.pb.FileAccess} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_pb.FileAccess.deserializeBinary
);


/**
 * @param {!proto.pb.FileAccess} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileAccess)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileAccess>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.FileServiceClient.prototype.setFileAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.FileService/SetFileAccess',
      request,
      metadata || {},
      methodDescriptor_FileService_SetFileAccess,
      callback);
};


/**
 * @param {!proto.pb.FileAccess} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileAccess>}
 *     Promise that resolves to the response
 */
proto.pb.FileServicePromiseClient.prototype.setFileAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.FileService/SetFileAccess',
      request,
      metadata || {},
      methodDescriptor_FileService_SetFileAccess);
};


module.exports = proto.pb;

