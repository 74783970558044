// source: job.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.pb.Job', null, global);
goog.exportSymbol('proto.pb.JobRange', null, global);
goog.exportSymbol('proto.pb.JobStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.Job.displayName = 'proto.pb.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.JobRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.JobRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.pb.JobRange.displayName = 'proto.pb.JobRange';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskkey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestkey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    submitted: jspb.Message.getFieldWithDefault(msg, 7, 0),
    completed: jspb.Message.getFieldWithDefault(msg, 8, 0),
    received: jspb.Message.getFieldWithDefault(msg, 9, 0),
    exitstatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    combinedoutput: jspb.Message.getFieldWithDefault(msg, 11, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 12, ""),
    hostid: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Job}
 */
proto.pb.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.Job;
  return proto.pb.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Job}
 */
proto.pb.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskkey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestkey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 6:
      var value = /** @type {!proto.pb.JobStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubmitted(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompleted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReceived(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExitstatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCombinedoutput(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskkey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestkey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSubmitted();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCompleted();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getReceived();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getExitstatus();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCombinedoutput();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHostid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string JobID = 1;
 * @return {string}
 */
proto.pb.Job.prototype.getJobid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setJobid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TaskKey = 2;
 * @return {string}
 */
proto.pb.Job.prototype.getTaskkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setTaskkey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string RequestKey = 3;
 * @return {string}
 */
proto.pb.Job.prototype.getRequestkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setRequestkey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string AgentID = 4;
 * @return {string}
 */
proto.pb.Job.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ClientID = 5;
 * @return {string}
 */
proto.pb.Job.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional JobStatus Status = 6;
 * @return {!proto.pb.JobStatus}
 */
proto.pb.Job.prototype.getStatus = function() {
  return /** @type {!proto.pb.JobStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.pb.JobStatus} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int64 Submitted = 7;
 * @return {number}
 */
proto.pb.Job.prototype.getSubmitted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setSubmitted = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 Completed = 8;
 * @return {number}
 */
proto.pb.Job.prototype.getCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 Received = 9;
 * @return {number}
 */
proto.pb.Job.prototype.getReceived = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setReceived = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 ExitStatus = 10;
 * @return {number}
 */
proto.pb.Job.prototype.getExitstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setExitstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string CombinedOutput = 11;
 * @return {string}
 */
proto.pb.Job.prototype.getCombinedoutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setCombinedoutput = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Owner = 12;
 * @return {string}
 */
proto.pb.Job.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string HostID = 13;
 * @return {string}
 */
proto.pb.Job.prototype.getHostid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.Job} returns this
 */
proto.pb.Job.prototype.setHostid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.pb.JobRange.prototype.toObject = function(opt_includeInstance) {
  return proto.pb.JobRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.pb.JobRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.JobRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    job: (f = msg.getJob()) && proto.pb.Job.toObject(includeInstance, f),
    start: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    owner: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.JobRange}
 */
proto.pb.JobRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.pb.JobRange;
  return proto.pb.JobRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.JobRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.JobRange}
 */
proto.pb.JobRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.pb.Job;
      reader.readMessage(value,proto.pb.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.JobRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.pb.JobRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.JobRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.JobRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.pb.Job.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Job Job = 1;
 * @return {?proto.pb.Job}
 */
proto.pb.JobRange.prototype.getJob = function() {
  return /** @type{?proto.pb.Job} */ (
    jspb.Message.getWrapperField(this, proto.pb.Job, 1));
};


/**
 * @param {?proto.pb.Job|undefined} value
 * @return {!proto.pb.JobRange} returns this
*/
proto.pb.JobRange.prototype.setJob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.JobRange} returns this
 */
proto.pb.JobRange.prototype.clearJob = function() {
  return this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.JobRange.prototype.hasJob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 Start = 2;
 * @return {number}
 */
proto.pb.JobRange.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.JobRange} returns this
 */
proto.pb.JobRange.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 Limit = 3;
 * @return {number}
 */
proto.pb.JobRange.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.pb.JobRange} returns this
 */
proto.pb.JobRange.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string Owner = 4;
 * @return {string}
 */
proto.pb.JobRange.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.pb.JobRange} returns this
 */
proto.pb.JobRange.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.pb.JobStatus = {
  JOBRSV: 0,
  STARTING: 1,
  STARTED: 2,
  DONE: 3,
  FAILED: 4,
  TIMEOUT: 5,
  NOTFOUND: 6,
  NOREPLY: 7
};

goog.object.extend(exports, proto.pb);
