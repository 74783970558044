/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: task_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var request_pb = require('./request_pb.js')

var task_pb = require('./task_pb.js')

var job_pb = require('./job_pb.js')

var file_transfer_pb = require('./file_transfer_pb.js')
const proto = {};
proto.pb = require('./task_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.TaskServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.TaskServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Request,
 *   !proto.pb.Request>}
 */
const methodDescriptor_TaskService_NewRequest = new grpc.web.MethodDescriptor(
  '/pb.TaskService/NewRequest',
  grpc.web.MethodType.UNARY,
  request_pb.Request,
  request_pb.Request,
  /**
   * @param {!proto.pb.Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  request_pb.Request.deserializeBinary
);


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Request)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.newRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/NewRequest',
      request,
      metadata || {},
      methodDescriptor_TaskService_NewRequest,
      callback);
};


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Request>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.newRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/NewRequest',
      request,
      metadata || {},
      methodDescriptor_TaskService_NewRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Request,
 *   !proto.pb.Request>}
 */
const methodDescriptor_TaskService_NewFromRecipe = new grpc.web.MethodDescriptor(
  '/pb.TaskService/NewFromRecipe',
  grpc.web.MethodType.UNARY,
  request_pb.Request,
  request_pb.Request,
  /**
   * @param {!proto.pb.Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  request_pb.Request.deserializeBinary
);


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Request)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.newFromRecipe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/NewFromRecipe',
      request,
      metadata || {},
      methodDescriptor_TaskService_NewFromRecipe,
      callback);
};


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Request>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.newFromRecipe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/NewFromRecipe',
      request,
      metadata || {},
      methodDescriptor_TaskService_NewFromRecipe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Request,
 *   !proto.pb.Request>}
 */
const methodDescriptor_TaskService_GetRequest = new grpc.web.MethodDescriptor(
  '/pb.TaskService/GetRequest',
  grpc.web.MethodType.UNARY,
  request_pb.Request,
  request_pb.Request,
  /**
   * @param {!proto.pb.Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  request_pb.Request.deserializeBinary
);


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Request)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.getRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/GetRequest',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetRequest,
      callback);
};


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Request>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.getRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/GetRequest',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RequestRange,
 *   !proto.pb.Request>}
 */
const methodDescriptor_TaskService_FilterRequests = new grpc.web.MethodDescriptor(
  '/pb.TaskService/FilterRequests',
  grpc.web.MethodType.SERVER_STREAMING,
  request_pb.RequestRange,
  request_pb.Request,
  /**
   * @param {!proto.pb.RequestRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  request_pb.Request.deserializeBinary
);


/**
 * @param {!proto.pb.RequestRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.filterRequests =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterRequests',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterRequests);
};


/**
 * @param {!proto.pb.RequestRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.filterRequests =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterRequests',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterRequests);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Request,
 *   !proto.pb.Request>}
 */
const methodDescriptor_TaskService_WatchRequests = new grpc.web.MethodDescriptor(
  '/pb.TaskService/WatchRequests',
  grpc.web.MethodType.SERVER_STREAMING,
  request_pb.Request,
  request_pb.Request,
  /**
   * @param {!proto.pb.Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  request_pb.Request.deserializeBinary
);


/**
 * @param {!proto.pb.Request} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.watchRequests =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchRequests',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchRequests);
};


/**
 * @param {!proto.pb.Request} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.watchRequests =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchRequests',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchRequests);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Request,
 *   !proto.pb.Request>}
 */
const methodDescriptor_TaskService_CancelRequest = new grpc.web.MethodDescriptor(
  '/pb.TaskService/CancelRequest',
  grpc.web.MethodType.UNARY,
  request_pb.Request,
  request_pb.Request,
  /**
   * @param {!proto.pb.Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  request_pb.Request.deserializeBinary
);


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Request)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.cancelRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/CancelRequest',
      request,
      metadata || {},
      methodDescriptor_TaskService_CancelRequest,
      callback);
};


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Request>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.cancelRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/CancelRequest',
      request,
      metadata || {},
      methodDescriptor_TaskService_CancelRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Task,
 *   !proto.pb.Task>}
 */
const methodDescriptor_TaskService_GetTask = new grpc.web.MethodDescriptor(
  '/pb.TaskService/GetTask',
  grpc.web.MethodType.UNARY,
  task_pb.Task,
  task_pb.Task,
  /**
   * @param {!proto.pb.Task} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  task_pb.Task.deserializeBinary
);


/**
 * @param {!proto.pb.Task} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.getTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/GetTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetTask,
      callback);
};


/**
 * @param {!proto.pb.Task} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Task>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.getTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/GetTask',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.TaskRange,
 *   !proto.pb.Task>}
 */
const methodDescriptor_TaskService_FilterTasks = new grpc.web.MethodDescriptor(
  '/pb.TaskService/FilterTasks',
  grpc.web.MethodType.SERVER_STREAMING,
  task_pb.TaskRange,
  task_pb.Task,
  /**
   * @param {!proto.pb.TaskRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  task_pb.Task.deserializeBinary
);


/**
 * @param {!proto.pb.TaskRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Task>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.filterTasks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterTasks',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterTasks);
};


/**
 * @param {!proto.pb.TaskRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Task>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.filterTasks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterTasks',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterTasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Task,
 *   !proto.pb.Task>}
 */
const methodDescriptor_TaskService_WatchTasks = new grpc.web.MethodDescriptor(
  '/pb.TaskService/WatchTasks',
  grpc.web.MethodType.SERVER_STREAMING,
  task_pb.Task,
  task_pb.Task,
  /**
   * @param {!proto.pb.Task} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  task_pb.Task.deserializeBinary
);


/**
 * @param {!proto.pb.Task} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Task>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.watchTasks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchTasks',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchTasks);
};


/**
 * @param {!proto.pb.Task} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Task>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.watchTasks =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchTasks',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchTasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Job,
 *   !proto.pb.Job>}
 */
const methodDescriptor_TaskService_GetJob = new grpc.web.MethodDescriptor(
  '/pb.TaskService/GetJob',
  grpc.web.MethodType.UNARY,
  job_pb.Job,
  job_pb.Job,
  /**
   * @param {!proto.pb.Job} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  job_pb.Job.deserializeBinary
);


/**
 * @param {!proto.pb.Job} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Job)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Job>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.getJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/GetJob',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetJob,
      callback);
};


/**
 * @param {!proto.pb.Job} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Job>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.getJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/GetJob',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.JobRange,
 *   !proto.pb.Job>}
 */
const methodDescriptor_TaskService_FilterJobs = new grpc.web.MethodDescriptor(
  '/pb.TaskService/FilterJobs',
  grpc.web.MethodType.SERVER_STREAMING,
  job_pb.JobRange,
  job_pb.Job,
  /**
   * @param {!proto.pb.JobRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  job_pb.Job.deserializeBinary
);


/**
 * @param {!proto.pb.JobRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Job>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.filterJobs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterJobs',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterJobs);
};


/**
 * @param {!proto.pb.JobRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Job>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.filterJobs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterJobs',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Job,
 *   !proto.pb.Job>}
 */
const methodDescriptor_TaskService_WatchJobs = new grpc.web.MethodDescriptor(
  '/pb.TaskService/WatchJobs',
  grpc.web.MethodType.SERVER_STREAMING,
  job_pb.Job,
  job_pb.Job,
  /**
   * @param {!proto.pb.Job} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  job_pb.Job.deserializeBinary
);


/**
 * @param {!proto.pb.Job} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Job>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.watchJobs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchJobs',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchJobs);
};


/**
 * @param {!proto.pb.Job} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Job>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.watchJobs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchJobs',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileTransfer,
 *   !proto.pb.FileTransfer>}
 */
const methodDescriptor_TaskService_GetFileTransfer = new grpc.web.MethodDescriptor(
  '/pb.TaskService/GetFileTransfer',
  grpc.web.MethodType.UNARY,
  file_transfer_pb.FileTransfer,
  file_transfer_pb.FileTransfer,
  /**
   * @param {!proto.pb.FileTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_transfer_pb.FileTransfer.deserializeBinary
);


/**
 * @param {!proto.pb.FileTransfer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FileTransfer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileTransfer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.getFileTransfer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/GetFileTransfer',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetFileTransfer,
      callback);
};


/**
 * @param {!proto.pb.FileTransfer} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FileTransfer>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.getFileTransfer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/GetFileTransfer',
      request,
      metadata || {},
      methodDescriptor_TaskService_GetFileTransfer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileTransferRange,
 *   !proto.pb.FileTransfer>}
 */
const methodDescriptor_TaskService_FilterFileTransfers = new grpc.web.MethodDescriptor(
  '/pb.TaskService/FilterFileTransfers',
  grpc.web.MethodType.SERVER_STREAMING,
  file_transfer_pb.FileTransferRange,
  file_transfer_pb.FileTransfer,
  /**
   * @param {!proto.pb.FileTransferRange} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_transfer_pb.FileTransfer.deserializeBinary
);


/**
 * @param {!proto.pb.FileTransferRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileTransfer>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.filterFileTransfers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterFileTransfers',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterFileTransfers);
};


/**
 * @param {!proto.pb.FileTransferRange} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileTransfer>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.filterFileTransfers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/FilterFileTransfers',
      request,
      metadata || {},
      methodDescriptor_TaskService_FilterFileTransfers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileTransfer,
 *   !proto.pb.FileTransfer>}
 */
const methodDescriptor_TaskService_WatchFileTransfers = new grpc.web.MethodDescriptor(
  '/pb.TaskService/WatchFileTransfers',
  grpc.web.MethodType.SERVER_STREAMING,
  file_transfer_pb.FileTransfer,
  file_transfer_pb.FileTransfer,
  /**
   * @param {!proto.pb.FileTransfer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  file_transfer_pb.FileTransfer.deserializeBinary
);


/**
 * @param {!proto.pb.FileTransfer} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileTransfer>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.watchFileTransfers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchFileTransfers',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchFileTransfers);
};


/**
 * @param {!proto.pb.FileTransfer} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileTransfer>}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServicePromiseClient.prototype.watchFileTransfers =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.TaskService/WatchFileTransfers',
      request,
      metadata || {},
      methodDescriptor_TaskService_WatchFileTransfers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Request,
 *   !proto.pb.Request>}
 */
const methodDescriptor_TaskService_UpdateRemoteAddress = new grpc.web.MethodDescriptor(
  '/pb.TaskService/UpdateRemoteAddress',
  grpc.web.MethodType.UNARY,
  request_pb.Request,
  request_pb.Request,
  /**
   * @param {!proto.pb.Request} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  request_pb.Request.deserializeBinary
);


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.Request)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.Request>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TaskServiceClient.prototype.updateRemoteAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.TaskService/UpdateRemoteAddress',
      request,
      metadata || {},
      methodDescriptor_TaskService_UpdateRemoteAddress,
      callback);
};


/**
 * @param {!proto.pb.Request} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.Request>}
 *     Promise that resolves to the response
 */
proto.pb.TaskServicePromiseClient.prototype.updateRemoteAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.TaskService/UpdateRemoteAddress',
      request,
      metadata || {},
      methodDescriptor_TaskService_UpdateRemoteAddress);
};


module.exports = proto.pb;

